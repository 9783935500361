import React from "react"
import { historicalstatementslist } from "../../actions/historicalStatementsAction"
import { connect } from "react-redux"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import autoBind from "react-autobind"
import SchedulerAcc from "../schedulers/scheduler_acc"
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"


class HistoricalStatements extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    historicalstatementsDetails: false,
    isRunbuttonClicked: false,
    isstatementselected: false,
    selectedhistoricalstatement: null,
  }

  runButtonClicked() {
    if (this.state.isstatementselected) {
      this.setState({ isRunbuttonClicked: true })
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  getstatus(e) {
    this.setState({ isRunbuttonClicked: false })
    this.setState({ isstatementselected: false })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.historicalstatementslist()
    this.setState({ loading: false })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isstatementselected: true })
          this.state.selectedhistoricalreport = row
        }
      },
    }

    return this.state.isRunbuttonClicked ? (
      <SchedulerAcc
        getViewStatusfromHisoricalSatememts={this.getstatus}
        fromHistoricalStatements='fromhistoricalStatements'
        historicalStatementname={this.state.selectedhistoricalreport}
      />
    ) : (
      <div className="content-wrapper">
        <section className="content-header pb-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item active">
                    Historical Statements
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 dashboard-main-area">

                <div className="card-header card-onboarding">
                  <div className="col-md-12 action-group report-actions">
                    <button
                      className="btn btn-default"
                      onClick={this.runButtonClicked}
                    >
                      Run
                      </button>
                  </div>
                </div>

                <div className="card bg-black">
                  <div className="card-body p-0">
                    {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                      <div
                        className="table-responsive p-0 report-table"
                        style={{ height: 410 }}
                      >
                        <BootstrapTable
                          data={this.props.historicalstatementList}
                          selectRow={selectRowProp}
                          striped
                          hover
                        >
                          <TableHeaderColumn isKey dataField="ID" hidden>
                            ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"105px"}
                            dataField="REPORTID"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Statement ID",
                            }}
                          >
                            Statement ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"300px"}
                            dataField="REPORTNAME"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Statement Name",
                            }}
                          >
                            Statement Name
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"80px"}
                            dataField="REPORTSYSTEM"
                            filter={{ type: "TextFilter", placeholder: "System" }}
                          >
                            System
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTBATCHID"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Batch ID",
                            }}
                          >
                            Batch ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTBATCHTYPE"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Batch Type",
                            }}
                          >
                            Batch Type
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTLOADTYPE"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Load Type",
                            }}
                          >
                            Load Type
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"70px"}
                            dataField="REPORTFORMAT"
                            filter={{ type: "TextFilter", placeholder: "Format" }}
                          >
                            Format
                        </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    historicalstatementList: state.csr.historicalstatementsReport,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    historicalstatementslist: () => {
      return dispatch(historicalstatementslist())
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalStatements)
