import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getListofBatchStatus, getMaxCOBDate, getRegionList, getmoduleList, getEndTimeList, getFullTableData } from '../../actions/batchStatus'
import _ from "lodash"
import autoBind from "react-autobind"
import "react-datetime/css/react-datetime.css"
import jwtDecode from "jwt-decode"


class BatchStatus extends Component {
  state = {
    datePickerValue: null,
    RegionListArray: [],
    tblHeaderList: [],
    tblRowsList: [],
    EndTimeValue: [],
    tblMileNameList: ['Bust Makeup Leave',
      'Daily Interest', 'Daily Statement', 'Detail Activity', 'Interest Report', 'Margin Statement',
      'Margin Workstation', 'Margining Report', 'MoneyLine Report', 'Monthly Interest', 'Monthly Statement', 'MTD Interest Report',
      'PnS Report', 'Position Report', 'Summary Activity'],
    regionName: ['Europe', 'Fareast', 'North America', 'South Africa', 'TOKYO', 'SINGAPORE', 'AUSTRALIA', 'LONDON', 'N.A. MEMO'],
    form: {
      maxCOBDate: null
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  async componentDidMount() {

    let MaxCOBDate = await this.props.getMaxCOBDate()

    let form = {}
    form["maxCOBDate"] = MaxCOBDate.data.body
    this.setState(_.extend(this.state.form, form))

    this.setState({ datePickerValue: MaxCOBDate.data.body.substring(0, 10) })

    this.getRegionListforBody()
  }

  async getinputDate(e) {

    this.setState({ datePickerValue: e.target.value })

    let form = {}
    form["maxCOBDate"] = e.target.value + 'T00:00:00.000Z'
    this.setState(_.extend(this.state.form, form))

    this.getRegionListforBody()
  }

  async getRegionListforHeader() {
    let res = []

    this.state.RegionListArray = []

    let response = await this.props.getRegionList(this.state.form)



    for (var i = 0; i < response.data.body.length; i++) {
      this.state.RegionListArray.push(response.data.body[i].REGIONNAME.trim())
      res.push(response.data.body[i].REGIONNAME.trim() + '-' + response.data.body[i].BATCHID)
    }

    this.setState({
      tblHeaderList: res.map((data) => (
        <th key={data}>{data.toUpperCase()}</th>
      ))
    })
  }

  async getRegionListforBody() {
      this.state.tblRowsList = [];

      let FullTableData = await this.props.getFullTableData(this.state.form);
      let fullDataBody = FullTableData.data.body;

      // Create a map to store endTime values for each moduleName and regionName
      const endTimeValues = new Map();

      // Populate the endTimeValues map from FullTableData
      fullDataBody.forEach(entry => {
          const moduleName = entry.MILENAME.trim();
          const regionName = entry.REGIONNAME.trim();
          const endTime = entry.ENDTIME.replace('T', ' ').replace('.000Z', '');

          if (!endTimeValues.has(moduleName)) {
              endTimeValues.set(moduleName, {});
          }

          endTimeValues.get(moduleName)[regionName] = endTime;
      });

      // Create the tblRowsList based on tblMileNameList and regionName
      this.setState({
          tblRowsList: this.state.tblMileNameList.map((moduleName, index) => (
              <tr key={index}>
                  <td>{moduleName}</td>
                  {this.state.regionName.map((regionName, idx) => (
                      <td key={idx} style={{ width: 165 }}>
                          {endTimeValues.get(moduleName)?.[regionName] || ''}
                      </td>
                  ))}
              </tr>
          ))
      });
  }


  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header pb-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-5">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item active">Batch Status</li>
                </ol>
              </div>
              <div className="col-sm-3">
                <div className="status-date">
                  <p>Business Date</p>
                  <input type="date" value={this.state.datePickerValue} onChange={this.getinputDate} name="BusinessDate" id="businessDate" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 dashboard-main-area">
                <div className="card bg-black">
                  <div className="card-body p-0">
                    <div className="col-md-12">
                      <div className="col-md-12 pr-3 float-left ">
                        <div className="row">
                          <div className="card-body table-responsive p-0 Batch-Status">
                            <table className="table table-head-fixed text-nowrap" border="0">
                              <thead>
                                <tr>
                                  <th style={{ width: 165 }}>MODULE</th>
                                  <th style={{ width: 165 }}>EUROPE-EU</th>
                                  <th style={{ width: 165 }}>FAREAST-FE</th>
                                  <th style={{ width: 165 }}>NORTH AMERICA-N1</th>
                                  <th style={{ width: 165 }}>SOUTH AFRICA-SA</th>
                                  <th style={{ width: 165 }}>TOKYO-TK</th>
                                  <th style={{ width: 165 }}>SINGAPORE-SG</th>
                                  <th style={{ width: 165 }}>AUSTRALIA-AU</th>
                                  <th style={{ width: 165 }}>LONDON-LN</th>
                                  <th style={{ width: 165 }}>N.A. MEMO-NM</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.tblRowsList}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    statusList: state.csr.getstatuslist,
    maxCOBDate: state.csr.getmaxcobDate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getListofBatchStatus: () => {
      return dispatch(getListofBatchStatus())
    },
    getMaxCOBDate: () => {
      return dispatch(getMaxCOBDate())
    },
    getRegionList: (formData) => {
      return dispatch(getRegionList(formData))
    },
    getmoduleList: (formData) => {
      return dispatch(getmoduleList(formData))
    },
    getEndTimeList: (formData) => {
      return dispatch(getEndTimeList(formData))
    },
    getFullTableData: (formData) => {
      return dispatch(getFullTableData(formData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchStatus)