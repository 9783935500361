import React from "react"
import { connect } from "react-redux"
import { userList, userById } from "../../actions/csrUserAction"
import autoBind from "react-autobind"
import Scheduler from "./scheduler"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import S3Objects from "../s3Bucket/ListofObjects"
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";


class ScheduleUser extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    isuserselected: false,
    isschedulebuttonClicked: false,
    isschedulebuttonClicked1: false,
    selectedReportDetails: null,
    selectedUserDetails: null,
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  cancelButtonClicked() {
    if (this.props.fromStatements) {
      this.props.compviewstatusofStatements(true)
    } else {
      this.props.compviewstatus(true)
    }
  }

  schedulebuttonclicked() {
    if (this.state.isuserselected) {
      this.setState({ isschedulebuttonClicked: true })
    }
  }

  getstatus(status) {
    this.setState({ isschedulebuttonClicked: false })
  }

  async componentDidMount() {
    this.setState({ loading: false })
    await this.props.userList()
    if (this.props.fromStatements) {
      this.setState({ selectedReportDetails: this.props.selectedstatement })
    } else {
      this.setState({ selectedReportDetails: this.props.selectedreport })
    }
    this.setState({ loading: false })
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isuserselected: true })
          let response = await this.props.userById(row.id)
          if (response && response.status === 200) {
            this.setState({ selectedUserDetails: response.data.body })
          }
        }
      },
    }

    return !this.state.isMyconsoleClicked ? (
      this.state.isschedulebuttonClicked ? (
        this.state.loading ? (<Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner>) :
          <Scheduler
            compviewstatus={this.getstatus}
            reloadView={this.props.compviewstatus}
            fromStatements={this.props.fromStatements}
            selectedreportDetail={
              this.props.fromStatements
                ? this.props.selectedstatement
                : this.props.selectedreport
            }
            selectedUserDetail={this.state.selectedUserDetails}
            statementCompView={this.props.compviewstatusofStatements}
          />
      ) : (
        <div className="content-wrapper">
          <section className="content-header pb-0">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      {this.props.fromStatements ?
                        <a href="#">Statements</a> :
                        <a href="#">Reports</a>
                      }
                    </li>
                    <li className="breadcrumb-item active">Schedule</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 dashboard-main-area">
                  <div className="card bg-black">
                    <div className="card-header card-onboarding">
                      <div className="col-md-12 action-group report-actions">
                        <button className="btn btn-default" onClick={this.schedulebuttonclicked}>Continue</button>
                        <button className="btn btn-default" onClick={this.cancelButtonClicked}>Cancel</button>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div
                        className="table-responsive p-0 report-table schedule-users"
                        style={{ height: 425 }}
                      >
                        <BootstrapTable
                          data={this.props.users}
                          selectRow={selectRowProp}
                          striped
                          hover
                        >
                          <TableHeaderColumn hidden isKey dataField="id">
                            ID
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"110px"}
                            dataField="userid"
                            filter={{
                              type: "TextFilter",
                              placeholder: "User Id",
                            }}
                          >
                            User Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"180px"}
                            dataField="userfirstname"
                            filter={{
                              type: "TextFilter",
                              placeholder: "User First Name",
                            }}
                          >
                            User First Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"180px"}
                            dataField="userlastname"
                            filter={{
                              type: "TextFilter",
                              placeholder: "User Last Name",
                            }}
                          >
                            User Last Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"240px"}
                            dataField="useremailid"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Email ID",
                            }}
                          >
                            Email ID
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="usertp"
                            filter={{
                              type: "TextFilter",
                              placeholder: "User Type",
                            }}
                          >
                            User Type
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"240px"}
                            dataField="companyname"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Company",
                            }}
                          >
                            Company
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"220px"}
                            dataField="usercountry"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Country",
                            }}
                          >
                            Country
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    ) : (
      <S3Objects />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.csr.usersList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userList: () => {
      return dispatch(userList())
    },
    userById: (id) => {
      return dispatch(userById(id))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleUser)
