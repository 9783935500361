import axios from 'axios'
import config from './config'

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const priormoneyline = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'PriorDaymoney-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIOR_MONEY_LINE',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const companyaccountList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'getcompanyaccount-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_ACCOUNT_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const accountgroupselection = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'UserSymbol', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_SELECT',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const priormoneyselaccountList = (formData) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'selectedacctPriorDaymoneylist', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIORMONEYLINE_SELACCOUNT_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}