import React from "react"
import { connect } from "react-redux"
import {
  companyaccountList,
  accountbyaccountgroup,
  getAccountsList,
  accountgroupselection
} from "../../actions/IntraTradePositionAction.js"
import _ from "lodash"
import autoBind from "react-autobind"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import Intraday from './IntradayTradeandPositions'
import Moneyline from './PriorDayMoneyline'
import Priorday from './PriordayTradePositions'
import jwtDecode from "jwt-decode"

class SelectionComponent extends React.Component {
  state = {
    readyTorender: false,
    selected: [],
    selectedsymbol: [],
    renderStatus: null,
    IntradayClassName: 'dash-sidebar-item active',
    PriorDayClassName: 'dash-sidebar-item ',
    MoneylineClassName: 'dash-sidebar-item',
    ShowIntraday: true,
    ShowPriorDay: false,
    ShowMoneyline: false,
    selectedaccounts: [],
    isLoadbuttonclicked: false,
    form: {
      companyname: null,
      actgroupid: null,
      userType: localStorage.getItem('userType'),
      userID: localStorage.getItem('useridforuser'),
      companynameforActGrp: localStorage.getItem('companyname')
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)


    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  onOpenModal() {
    this.formReset()
    this.setState({ showAddModal: true })
  }

  async componentDidMount() {

    let response = await this.props.getAccountsList(this.state.form)

    let array = []

    let array1 = []

    Promise.all(response.data.body && response.data.body.map(async (data, index) => {
      array.push(data.id)
      array1.push(data.firm + data.office + data.account)
    }))

    this.setState({ selected: array }, () => {
      this.setState({ readyTorender: true, selectedaccounts: array1 })
    })
  }

  Intradayclick() {
    this.setState({ IntradayClassName: 'dash-sidebar-item active', PriorDayClassName: 'dash-sidebar-item', MoneylineClassName: 'dash-sidebar-item', ShowIntraday: true, ShowPriorDay: false, ShowMoneyline: false })
  }

  PriorDayClick() {
    this.setState({ IntradayClassName: 'dash-sidebar-item', PriorDayClassName: 'dash-sidebar-item active', MoneylineClassName: 'dash-sidebar-item', ShowIntraday: false, ShowPriorDay: true, ShowMoneyline: false })
  }

  MoneylineClick() {
    this.setState({ IntradayClassName: 'dash-sidebar-item', PriorDayClassName: 'dash-sidebar-item', MoneylineClassName: 'dash-sidebar-item active', ShowIntraday: false, ShowPriorDay: false, ShowMoneyline: true })
  }

  async getStatus() {
    let response = await this.props.getAccountsList(this.state.form)
    this.state.selected = []
    this.state.selectedaccounts = []

    let array = []
    let array1 = []

    Promise.all(response.data.body && response.data.body.map(async (data, index) => {
      array.push(data.id)
      array1.push(data.firm + data.office + data.account)
    }))

    this.setState({ selected: array, selectedaccounts: array1 })
  }

  async symbolbuttonClicked() {
    this.state.selected = []
    this.state.selectedaccounts = []
    await this.props.accountgroupselection(this.state.form)
  }

  render() {
    const selectRowProp = {
      mode: "checkbox",
      bgColor: 'rgba(255,255,255,0.3)',
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect === true) {
          this.state.selected.push(row.id)
          this.state.selectedaccounts.push(row.firm + row.office + row.account)
          this.setState({ selectedsymbol: [] })
        } else {
          var toRemove = row.account
          var index = this.state.selectedaccounts.indexOf(toRemove)
          if (index === -1) {
            this.state.selectedaccounts.splice(index, 1)
            this.state.selected.splice(index, 1)
          }
        }
      },

      onSelectAll: (isSelect, rows, e) => {
        if (isSelect === true) {
          for (let i = 0; i < rows.length; i++) {
            this.state.selectedaccounts.push(rows[i].firm + rows[i].office + rows[i].account)
            this.state.selected.push(rows[i].id)
            this.setState({ selectedsymbol: [] })
          }
        } else {
          if (this.state.ShowIntraday === true) {
            this.state.renderStatus = '1'
          } else if (this.state.ShowMoneyline === true) {
            this.state.renderStatus = '2'
          } else if (this.state.ShowPriorDay === true) {
            this.state.renderStatus = '3'
          }
          this.setState({ ShowIntraday: false, ShowMoneyline: false, ShowPriorDay: false })

          this.state.selected = []
          this.state.selectedaccounts = []

          if (this.state.renderStatus === 1) {
            this.setState({ ShowIntraday: true })
          } else if (this.state.renderStatus === 2) {
            this.setState({ ShowMoneyline: true })
          } else if (this.state.renderStatus === 3) {
            this.setState({ ShowPriorDay: true })
          }
        }
      },
    }

    const selectRowPropsymbol = {
      mode: "checkbox",
      bgColor: 'rgba(255,255,255,0.3)',
      selected: this.state.selectedsymbol,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect === true) {
          this.state.selectedsymbol.push(row.id)
          let form = {}
          form["companyname"] = row.companyname
          form["actgroupid"] = row.actgroupid
          this.setState(_.extend(this.state.form, form))
          let response = await this.props.accountbyaccountgroup(this.state.form)
          response.data.body && response.data.body.map((data, index) => {
            this.state.selectedaccounts.push(data.firm + data.office + data.account)
          })

        } else {
          var toRemove = row.actgroupid
          var index = this.state.selectedsymbol.indexOf(toRemove)
          if (index === -1) {
            this.state.selectedsymbol.splice(index, 1)
            let form = {}
            form["companyname"] = row.companyname
            form["actgroupid"] = row.actgroupid
            this.setState(_.extend(this.state.form, form))
            let response = await this.props.accountbyaccountgroup(this.state.form)
            response.data.body && response.data.body.map((data, index) => {
              let remove = data.firm + data.office + data.account
              let selection = this.state.selectedaccounts.indexOf(remove)
              if (selection > -1) {
                this.state.selectedaccounts.splice(selection, 1)
              }
            })
          }
        }
      },

      onSelectAll: async (isSelect, rows, e) => {
        if (isSelect === true) {
          this.state.selectedaccounts = []
          for (let i = 0; i < rows.length; i++) {
            this.state.selectedsymbol.push(rows[i].id)
            let form = {}
            form["companyname"] = rows[i].companyname
            form["actgroupid"] = rows[i].actgroupid
            this.setState(_.extend(this.state.form, form))
            let response = await this.props.accountbyaccountgroup(this.state.form)
            response.data.body && response.data.body.map((data, index) => {
              this.state.selectedaccounts.push(data.firm + data.office + data.account)
            })
            this.setState({ selected: [] })
          }
        } else {
          this.state.selectedaccounts = []
          this.state.selectedsymbol = []
        }
      },
    }

    return (
      <div className="content-wrapper">
        <section className="content-header pb-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-4">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="#">Dashboards</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {(this.state.ShowIntraday) ? "Intraday Trades and Positions" : "" }
                    {(this.state.ShowPriorDay) ? "Prior Day Trades | Positions" : "" }
                    {(this.state.ShowMoneyline) ? "Prior Day Moneyline" : "" }
                  </li>
                </ol>
              </div>
              <div className="col-sm-5">
                <div className="card-head p-0">
                  <ul className="dash-sidebar-menu float-sm-left">
                    <li className={this.state.IntradayClassName} name="Intraday" style={{ cursor: "pointer" }} onClick={this.Intradayclick}>
                      Intraday Trades | Positions
                    </li>
                    <li className={this.state.PriorDayClassName} name="PriorDay" style={{ cursor: "pointer" }} onClick={this.PriorDayClick}>
                      Prior Day Trades | Positions
                    </li>
                    <li className={this.state.MoneylineClassName} name="Moneyline" style={{ cursor: "pointer" }} onClick={this.MoneylineClick}>
                      Prior Day Moneyline{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <span
                className="tablehead-buttonlinks col-6 float-left"
                style={{ textAlign: "right" }}
              >
              </span>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 dashboard-sidebar">
                <div className="card bg-black">
                  <div className="card-body p-0">
                    <h6>Selection</h6>
                    <ul
                      className="nav nav-tabs"
                      id="custom-content-below-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-content-below-account-tab"
                          data-toggle="pill"
                          href="#custom-content-below-account"
                          role="tab"
                          aria-controls="custom-content-below-account"
                          aria-selected="true"
                        >
                          Account
                        </a>
                      </li>
                      <li className="nav-item" onClick={this.symbolbuttonClicked}>
                        <a
                          className="nav-link"
                          id="custom-content-below-symbol-tab"
                          data-toggle="pill"
                          href="#custom-content-below-symbol"
                          role="tab"
                          aria-controls="custom-content-below-symbol"
                          aria-selected="false"
                        >
                          Symbol
                        </a>
                      </li>
                    </ul>
                    <div
                      className="tab-content"
                      id="custom-content-below-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="custom-content-below-account"
                        role="tabpanel"
                        aria-labelledby="custom-content-below-account-tab"
                      >
                        <div
                          className="table-responsive tradesmallgridalignment p-0"
                          width={""}>
                          <BootstrapTable
                            style={{ "minWidth": "20px" }}
                            keyField="id"
                            data={this.props.accountGroups}
                            selectRow={selectRowProp}
                          >
                            <TableHeaderColumn
                              width="22px"
                              columnClassName={this.columnClassNameFormat}
                              columnTitle={this.customTitle}
                              dataAlign="left"
                              headerAlign="left"
                              dataField="firm"
                              csvHeader="Guest Name"
                              tdStyle={{
                                whiteSpace: "normal",
                                "minWidth": "20px",
                              }}
                              thStyle={{ "minWidth": "20px" }}
                              hidden={this.state.hideColumnHeaderDetails}
                              dataSort={true}
                              filter={{ type: "TextFilter", placeholder: "F" }}
                            >
                              F
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="36px"
                              columnClassName={this.columnClassNameFormat}
                              columnTitle={this.customTitle}
                              dataField="office"
                              csvHeader="Guest Name"
                              tdStyle={{
                                whiteSpace: "normal",
                                "minWidth": "25px",
                              }}
                              thStyle={{ "minWidth": "25px" }}
                              hidden={this.state.hideColumnHeaderDetails}
                              dataSort={true}
                              filter={{
                                type: "TextFilter",
                                placeholder: "Off",
                              }}
                            >
                              Off
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="55px"
                              columnClassName={this.columnClassNameFormat}
                              columnTitle={this.customTitle}
                              dataField="account"
                              csvHeader="Guest Name"
                              tdStyle={{
                                whiteSpace: "normal",
                                "minWidth": "25px",
                              }}
                              thStyle={{ "minWidth": "25px" }}
                              hidden={this.state.hideColumnHeaderDetails}
                              dataSort={true}
                              filter={{
                                type: "TextFilter",
                                placeholder: "Acct",
                              }}
                            >
                              Acct
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="150px"
                              columnClassName={this.columnClassNameFormat}
                              columnTitle={this.customTitle}
                              dataField="shortname"
                              csvHeader="Guest Name"
                              tdStyle={{
                                whiteSpace: "normal",
                                "minWidth": "115px",
                              }}
                              thStyle={{ width: "115px" }}
                              hidden={this.state.hideColumnHeaderDetails}
                              dataSort={true}
                              filter={{
                                type: "TextFilter",
                                placeholder: "Short Name",
                              }}
                            >
                              Short Name
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>

                      </div>

                      <div
                        className="tab-pane fade"
                        id="custom-content-below-symbol"
                        role="tabpanel"
                        aria-labelledby="custom-content-below-symbol-tab"
                      >
                        <div className="table-responsive p-0">
                          <BootstrapTable
                            keyField="id"
                            data={this.props.accountgroup}
                            selectRow={selectRowPropsymbol}
                          >
                            <TableHeaderColumn
                              dataField="actgroupid"
                              filter={{
                                type: "TextFilter",
                                placeholder: "Symbol",
                              }}
                            >
                              Symbol
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 dashboard-main-area">
                <div className="card bg-black">
                  <div className="card-body p-0">
                    {this.state.readyTorender ?
                      this.state.ShowIntraday ?
                        <Intraday loadData={this.state.selectedaccounts} getstatus={this.getStatus} /> :
                        this.state.ShowPriorDay ?
                          <Priorday loadData={this.state.selectedaccounts} getstatus={this.getStatus} /> :
                          this.state.ShowMoneyline ?
                            <Moneyline loadData={this.state.selectedaccounts} getstatus={this.getStatus} /> :
                            ' '
                      :
                      ' '
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyaccount: state.csr.companyaccountList,
    accountgroup: state.csr.accountgroupselection,
    accountGroups: state.csr.getAccountsList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountgroupselection: (formData) => {
      return dispatch(accountgroupselection(formData))
    },
    getAccountsList: (formData) => {
      return dispatch(getAccountsList(formData))
    },
    companyaccountList: () => {
      return dispatch(companyaccountList())
    },
    accountbyaccountgroup: (formData) => {
      return dispatch(accountbyaccountgroup(formData))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionComponent)