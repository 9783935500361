import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'

import auth from './reducers/auth.js'
import csr from './reducers/csr.js'
let middleware = null

if (1) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    middleware = composeEnhancers(applyMiddleware(thunk))
}
else {
    middleware = applyMiddleware(thunk)
}

let store = createStore(combineReducers({
    form: formReducer,
    auth,
    csr
}), middleware)

export function createReducer(initialState, handlers) {
    return (state = initialState, action) => {
        if (action && handlers[action.type]) { return handlers[action.type](state, action) }
        return state
    }
}

export default store
