import _ from 'lodash'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { getCurrentDate } from '../../utils/currentDate.js'
import { authAction } from '../../actions/authAction'
import { authMiddleware } from '../../utils/authMiddleware.js'
import Topmenu from './topmenu.jsx'
import { Link,BrowserRouter as Router } from 'react-router-dom'
import ContactUS from '../ContactUS'
import { GetColorDetailsByID, ToAddorUpdateNewColor } from '../../actions/BGcolorChangeAPIController'

class Header extends Component {

  state = {
    isbluethemeSelected: false,
    dropdownopen: "theme-color-picker",
    contactUsClicked: false,
    loginfailedError: null,
    login: false,
    form: {
      email: '',
      password: '',
      UserID: null,
      SelectedColor: null,
    },
    auth: false
  }

  constructor(props) {
    super(props)
    autoBind(this)

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  contactUsClicked() {
    this.setState({ contactUsClicked: true })
  }

  handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
    this.setState({ loginfailedError: '' })
  }

  async handleConfirm(e) {
    e.preventDefault()
    let response = await this.props.authAction(this.state.form.email, this.state.form.password)
    if (response === undefined) {
      this.setState({ loginfailedError: 'Please enter a valid username or password' })
    }
  }

  async componentDidMount() {
    try {
      let form = {};
      form["UserID"] = localStorage.getItem('useridforuser');
      let response = await this.props.GetColorDetailsByID(form);

      if (response !== undefined) {
        let body = document.querySelector('body');
        switch (response.data.body) {
          case 'Blue':
            body.classList.remove('theme-MidNight');
            body.classList.remove('theme-black');
            body.classList.add('theme-blue');
            break;

          case 'MidNight':
            body.classList.remove('theme-black');
            body.classList.remove('theme-blue');
            body.classList.add('theme-MidNight');
            break;

          case 'Black':
            body.classList.remove('theme-black');
            body.classList.remove('theme-blue');
            body.classList.add('theme-black');
            break;
            
          default:
            body.classList.add('');  
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  logOut() {
    localStorage.clear()
    this.setState({ login: false })
  }

  // blue color functions 

  blueThemeButtonClicked(event) {
    event.preventDefault()
    let form = {}
    form["UserID"] = localStorage.getItem('useridforuser')
    form["SelectedColor"] = 'Blue'
    this.setState(_.extend(this.state.form, form))
    this.setState({ isbluethemeSelected: true })
    this.props.ToAddorUpdateNewColor(this.state.form)
    const body = document.querySelector('body');
    body.classList.remove('theme-black');
    body.classList.remove('theme-MidNight');
    body.classList.add('theme-blue');
    if (this.state.dropdownopen === "theme-color-picker") {
      this.setState({ dropdownopen: "theme-color-picker showcolor" })
    }
    else {
      this.setState({ dropdownopen: "theme-color-picker" })
    }
  }

  // midNightTHeme color functions 
  midNightThemeButtonClicked() {
    let form = {}
    form["UserID"] = localStorage.getItem('useridforuser')
    form["SelectedColor"] = 'MidNight'
    this.setState(_.extend(this.state.form, form))
    this.setState({ isbluethemeSelected: true })
    this.props.ToAddorUpdateNewColor(this.state.form)
    const body = document.querySelector('body');
    body.classList.remove('theme-black')
    body.classList.remove('theme-blue')
    body.classList.add('theme-MidNight');
    if (this.state.dropdownopen === "theme-color-picker") {
      this.setState({ dropdownopen: "theme-color-picker showcolor" })
    }
    else {
      this.setState({ dropdownopen: "theme-color-picker" })
    }
  }

  // blackTheme color functions
  blackThemeButtonClicked(event) {
  event.preventDefault()
    let form = {}
    form["UserID"] = localStorage.getItem('useridforuser')
    form["SelectedColor"] = 'Black'
    this.setState(_.extend(this.state.form, form))
    this.setState({ isbluethemeSelected: true })
    this.props.ToAddorUpdateNewColor(this.state.form)
    const body = document.querySelector('body');
    body.classList.remove('theme-blue');
    body.classList.remove('theme-MidNight');
    body.classList.add('theme-black');
    if (this.state.dropdownopen === "theme-color-picker") {
      this.setState({ dropdownopen: "theme-color-picker showcolor" })
    }
    else {
      this.setState({ dropdownopen: "theme-color-picker" })
    }
  }

  dropdownopen() {
    if (this.state.dropdownopen === "theme-color-picker") {
      this.setState({ dropdownopen: "theme-color-picker showcolor" })
    }
    else {
      this.setState({ dropdownopen: "theme-color-picker" })
    }
  }


  render() {
    return (
    
      <div>
        <div>
          {authMiddleware() ?
            <div><nav className="main-header navbar navbar-expand navbar-dark navbar-dark" style={{ border: 'none' }}>
              <div className="user-panel d-flex title-area">
                <div className="logo-area-fixed">
                  <a href="index3.html" className="brand-link bgwhite">
                    <img src="/dist/img/logo-white.png"
                      alt="AdminLTE Logo"
                      className="brand-image" />
                  </a>
                </div>
                <div className="info">
                  <a href="#" className="d-block">F&O and OTC Clearing</a>
                </div>
              </div>

              <div className="user-panel d-flex User-area">
                <div className="info">
                  <p>{getCurrentDate()}</p>
                  <span>Welcome!</span>
                  <a href="#" className="d-block">{localStorage.getItem('userfirstname') + ' ' + localStorage.getItem('userlastname')}</a>
                </div>
                <div className="image">
                  <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                </div>

                <div className="color-image" onClick={this.dropdownopen}>
                  <img src="/dist/img/color-circle.png" className="img-circle elevation-2" alt="User Image" />
                </div>

                <Link to="/" style={{ color: "black" }} className="nav-link"><button className="btn btn-default btn-sm" style={{ marginLeft: 10 }} onClick={this.logOut}>Logoff</button></Link>
              </div>

            </nav><Topmenu />
              <div className={this.state.dropdownopen}>
                <ul>
                  <li className="theme-black" onClick={this.blackThemeButtonClicked}><span className="color-black"></span>Theme Black</li>
                  <li className="theme-midnight" onClick={this.midNightThemeButtonClicked}><span className="color-midnight"></span>Theme MidNight</li>
                  <li className="theme-blue" onClick={this.blueThemeButtonClicked}><span className="color-blue"></span>Theme Blue</li>
                </ul>
              </div>
            </div> :
            <nav className="main-header login-header navbar navbar-expand navbar-dark">
              <div className="user-panel d-flex title-area">
                <div className="logo-area-fixed">
                  <a href="index3.html" className="brand-link bgwhite">
                    <img src="/dist/img/logo-white.png" alt="AdminLTE Logo" className="brand-image" />
                  </a>
                </div>
                <div className="info">
                  <a href="#" className="d-block">F&O and OTC Clearing</a>
                </div>
              </div>
              <div className="user-panel d-flex User-area">
                <div className="login-form">
                  <form className="col-md-12">
                    <div className="form-group">
                      <input type="text" name="email" onChange={this.handleInputChange} placeholder="User name" />
                    </div>
                    <div className="form-group">
                      <input type="password" name="password" onChange={this.handleInputChange} placeholder="Password" />
                    </div>
                    <div className="form-group">
                      <input type="submit" name="submitBtn" onClick={this.handleConfirm} value="Login" />
                    </div>
                  </form>
                </div>


                <div className="contact-link">
                  <Router>
                     <Link to="/contact-us" className="nav-link" onClick={this.contactUsClicked}>Contact Us</Link>
                  </Router>
                </div>
              </div>
              <div className="user-panel d-flex User-bottom">
                <div style={{ color: 'red' }}>{this.state.loginfailedError}</div>
              </div>
            </nav>
          }
        </div>
        {this.state.contactUsClicked ?
          <ContactUS />
          :
          ''}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authAction: (email, password) => {
      return dispatch(authAction(email, password))
    },
    GetColorDetailsByID: (formData) => {
      return dispatch(GetColorDetailsByID(formData))
    },
    ToAddorUpdateNewColor: (formData) => {
      return dispatch(ToAddorUpdateNewColor(formData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
