import React from 'react'
import jwtDecode from "jwt-decode";
import autoBind from "react-autobind"



class ContactUs extends React.Component {

    constructor(props) {
        super(props)
        autoBind(this)

        let expirationTime
        let token = localStorage.getItem('accessToken')
        if (token !== null) {
            const { exp } = jwtDecode(token)
            expirationTime = (exp * 1000)
            if (Date.now() >= expirationTime) {
                localStorage.clear();
                window.location.reload('/')
            }
        }
    }


    render() {
        return (
            <div className="wrapper">
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-black">
                                            <div className="contact-section col-md-12  float-left">
                                                <h5>Please contact Regional CSRs</h5>
                                                <div className="col-lg-4 col-md-6 col-sm-6 contacts float-left contacts-1">
                                                    <h6>Americas</h6>
                                                    <span className="contact-phn">
                                                        <a href="tel:1 802 210 1973"><i className="fas fa-phone-alt"></i> 1 802 210 1973 (US only)</a>
                                                        <a href="tel:1 866 654 1976"><i className="fas fa-phone-alt"></i> 1 866 654 1976</a>
                                                    </span>
                                                    <span className="contact-mail">
                                                        <a href="mailto:myhelpdesk@company-us.com"><i className="fas fa-envelope"></i> myhelpdesk@company-us.com</a>
                                                    </span>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 contacts float-left">
                                                    <h6>Europe, Middle East & Africa</h6>
                                                    <span className="contact-phn">
                                                        <a href="tel:+44 20 7995 7995"><i className="fas fa-phone-alt"></i> +44 20 7995 7995</a>
                                                        <a href="tel:0 800 021 1973"><i className="fas fa-phone-alt"></i> 0 800 021 1973 (UK)</a>
                                                    </span>
                                                    <span className="contact-mail">
                                                        <a href="mailto:myhelpdesk@company-us.com"><i className="fas fa-envelope"></i> myhelpdesk@company-eu.com</a>
                                                    </span>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 contacts float-left contacts-3">
                                                    <h6>Asia Pacific</h6>
                                                    <span className="contact-phn">
                                                        <a href="tel:+81 3 8116 1776"><i className="fas fa-phone-alt"></i> +81 3 8116 1776 (Japan)</a>
                                                        <a href="tel:800 910 9777"><i className="fas fa-phone-alt"></i> 800 910 9777 (Singapore)</a>
                                                        <a href="tel:800 80 0003"><i className="fas fa-phone-alt"></i> 800 80 0003 (Hong Kong)</a>
                                                        <a href="tel:800 80 0004"><i className="fas fa-phone-alt"></i> 800 80 0004 (Singapore)</a>
                                                    </span>
                                                    <span className="contact-mail">
                                                        <a href="mailto:myhelpdesk@company-us.com"><i className="fas fa-envelope"></i> myhelpdesk@company-asia.com</a>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default ContactUs;