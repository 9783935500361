import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import {
  accountGroupList,
  availableaccountList,
  notselectedaccountGroupList,
  addaccountgroup,
  selectedaccountGroupList
} from "../../../../actions/csrAccountGroupAction.js"
import autoBind from "react-autobind"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { companiesList } from "../../../../actions/csrCompaniesAction"
import AlertPopup from '../../../AlertPopup/popupDesign'
import jwtDecode from "jwt-decode";


class addAccountGroup extends React.Component {

  state = {
    actgrpidError: null,
    companyError: null,
    SelectedAccountsError: null,
    rowIds: [],
    successAlert: false,
    successMessage: null,
    id: [],
    indexarray: [],
    columnids: [],
    childData: [],
    emptyarray: [],
    availableaccountvalue: [],
    idsfromchildcomp: [],
    value: "0",
    accountGroupsList: [],
    selectedRightSide: [],
    selectedLeftSide: [],
    selectedaccountgrouplist: [],
    removedaccountgrouplist: [],
    accountAfterAddButtonClicked: [],
    accountafterremoveButtonClicked: [],
    actGrpRightafterClkRmvBtn: [],
    bindrightSideafterClkRmvBtn: [],
    rightcheckboxids: [],
    leftcheckboxids: [],
    isaddbuttonClicked: false,
    isremovebuttonclicked: false,
    isrowselected: false,
    isrowselected1: false,
    form: {
      actgroupid: null,
      companyname: null,
      active: "NO",
      items: []
    },
  }

  constructor(props) {
    super(props)
    autoBind(this)
    this.getData = this.getData.bind(this)
    this.state.columnids = this.props.availableAccount
    this.setState({ availableaccountvalue: this.props.availableAccount })
    this.onBootstrapTableRef = this.onBootstrapTableRef.bind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  clearSelectedRow() {
    this.bootstrapTableRef.cleanSelected()
  }

  onBootstrapTableRef(instance) {
    this.bootstrapTableRef = instance
  }


  async getData(data) {
    let form = _.cloneDeep(this.state)
    let sortOrder = this.state.id
    let dif = _.differenceWith(sortOrder, data, _.isEqual)
    form['id'] = dif
    form['isremovebuttonclicked'] = false
    await this.setState(_.extend(this.state, form))
    this.props.notselectedaccountGroupList(dif)
  }

  async componentDidMount() {
    await this.props.availableaccountList()
    await this.props.companiesList()
    await this.props.selectedaccountGroupList(this.state.id)

    this.tablerender()
  }

  async tablerender() {
    let array = []
    this.props.availableAccount && this.props.availableAccount.map((accountgrp, index) => {
      array.push(accountgrp)
    })
    this.setState({ accountGroupsList: array })
  }

  async addButtonClicked() {
    if (this.state.isrowselected) {
      this.setState({ SelectedAccountsError: "" })
      this.state.accountafterremoveButtonClicked = []
      this.state.selectedaccountgrouplist && this.state.selectedaccountgrouplist.map((selected, index) => {
        this.state.accountAfterAddButtonClicked.push(selected)
        this.state.accountafterremoveButtonClicked.push(selected)
      })

      this.setState({ isaddbuttonClicked: true })
      this.state.leftcheckboxids && this.state.leftcheckboxids.map((ids, index) => {
        this.state.accountGroupsList = this.state.accountGroupsList.filter((accountselected) => {
          return accountselected.id !== ids
        })
      })
    }

    this.state.selectedaccountgrouplist = []
    this.setState({ isremovebuttonclicked: false })
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
    this.clearSelectedRow()
  }

  async removebuttonclicked() {

    if (this.state.isrowselected1) {
      let form = _.cloneDeep(this.state)
      let containsids = this.state.leftcheckboxids
      let removedid = this.state.rightcheckboxids
      let dif = _.differenceWith(containsids, removedid, _.isEqual)
      form['leftcheckboxids'] = dif
      await this.setState(_.extend(this.state, form))

      this.setState({ isremovebuttonclicked: true, isaddbuttonClicked: true })
      this.state.removedaccountgrouplist && this.state.removedaccountgrouplist.map((accountselected, index) => {
        this.state.accountGroupsList.push(accountselected)
        this.state.accountAfterAddButtonClicked = this.state.accountAfterAddButtonClicked.filter((actgrpid) => {
          return accountselected.id !== actgrpid.id
        })
      })
      this.setState({ isrowselected1: false })
      this.setState({ isrowselected: false })
    }
    this.state.removedaccountgrouplist = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
    this.clearSelectedRow()
  }


  handleInputChange(e) {
    if (e.target.name === "actgroupid" && e.target.value.slice(-1) === " ") {
      return false
    } else if (e.target.name === "actgroupid") {
      let actGpVal = e.target.value.toUpperCase()
      let form = {}
      form[e.target.name] = actGpVal
      this.setState(_.extend(this.state.form, form))
    } else {
      let form = {}
      form[e.target.name] = e.target.value
      this.setState(_.extend(this.state.form, form))
    }
    if (this.state.form.actgroupid !== null) {
      this.setState({ actgrpidError: "" })
      return false
    }
    if (this.state.form.companyname !== null) {
      this.setState({ companyError: "" })
      return false
    }
  }

  Validation() {
    let checkActGrpAvailability = false
    if (this.state.form.actgroupid === null) {
      this.setState({ actgrpidError: "Enter Account Group Name" })
      return false
    } else {
      this.props.AvailabilityofAccountGroup && this.props.AvailabilityofAccountGroup.map((data, index) => {
        if (data === this.state.form.actgroupid) {
          checkActGrpAvailability = true
        }
      })
      if (checkActGrpAvailability) {
        this.setState({ actgrpidError: "Account Group Name Already Exist" })
        return false
      }
      else {
        this.setState({ actgrpidError: "" })
      }
    }
    if (this.state.form.companyname === null) {
      this.setState({ companyError: "Select any Company" })
      return false
    } else {
      this.setState({ companyError: "" })
    }
  }

  async SavebuttonClicked() {
    let validation = this.Validation()
    if (validation !== false) {
      this.state.form.items = []
      this.state.accountAfterAddButtonClicked && this.state.accountAfterAddButtonClicked.map((account, index) => {
        this.state.form.items.push(account)
      })

      if (this.state.form.items.length === 0) {
        this.setState({ SelectedAccountsError: "Please add the Accounts" })
        return false
      }
      else {

        let response = await this.props.addaccountgroup(this.state.form)

        if (response && response.status === 200) {
          this.setState({ successMessage: "Added Successfully" })
          this.setState({ successAlert: true })
        }
      }
    }
  }

  cancelAlert() {
    this.setState({ successAlert: false })
    this.props.getSuccessStatus()
  }

  CancelButtonClicked() {
    this.props.getSuccessStatus()
  }

  ongetstatus() {
    this.setState({ successAlert: false })
    this.props.getSuccessStatus()
  }

  render() {

    let CompaniesList
    if (this.props.Companies) {
      CompaniesList = this.props.Companies.map((company, index) => (
        <option key={company.id}> {company.companyname}</option>
      ))
    }

    const selectRowProp = {
      mode: "checkbox",
      selected: this.state.leftcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected: true })

          //Add selected account to rightsidechekcboxList
          this.state.leftcheckboxids.push(row.id)

          //Get checked Id Details
          let selected = this.state.accountGroupsList.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.selectedaccountgrouplist.push(selected)
        }
        else {
          //Remove Unchecked ID from List
          let ids = this.state.leftcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.leftcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.leftcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.selectedaccountgrouplist = this.state.selectedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      }
    }

    const selectRowPropforselectedaccounts = {
      mode: "checkbox",
      selected: this.state.rightcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected1: true })

          //Add selected account from rightsidechekcboxList
          this.state.rightcheckboxids.push(row.id)

          //Add removed Id details       removedaccountgrouplist
          let selected = this.state.accountAfterAddButtonClicked.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.removedaccountgrouplist.push(selected)

          this.state.accountafterremoveButtonClicked = this.state.accountafterremoveButtonClicked.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
        else {
          //Remove id from rightsidechekcboxList 
          let ids = this.state.rightcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.rightcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.rightcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.removedaccountgrouplist = this.state.removedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      }
    }

    return (
      this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={'Account Group Added Successfully'} />) :
        <div className="hold-transition sidebar-mini layout-navbar-fixed">

          <div className="hold-transition sidebar-mini layout-navbar-fixed">
            <div className="wrapper">
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <ol className="breadcrumb float-sm-left">
                          <li className="breadcrumb-item">
                            <a href="#">CSR</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Client Onboarding</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Account Groups</a>
                          </li>
                          <li className="breadcrumb-item active">Add account Groups</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="card bg-black csr-onboarding">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card-header card-onboarding col-md-5 float-left">
                                    <div
                                      className="card-tools col-md-12 float-left"
                                      style={{ marginBottom: 3 }}
                                    >
                                      <div className="input-group search-fields input-group-sm">
                                        <label>Company:</label>
                                        <select
                                          name="companyname"
                                          onChange={this.handleInputChange}
                                          value={this.state.form.companyname}
                                        >
                                          <option value="0">Select</option>
                                          {CompaniesList}
                                        </select>
                                        <label className="ml-3">
                                          Save Account Group as:
                                        </label>
                                        <input
                                          type="text"
                                          name="actgroupid"
                                          className="form-control float-left input-small"
                                          onChange={this.handleInputChange}
                                          placeholder="Account Group Name"
                                          value={this.state.form.actgroupid}
                                          style={{ textTransform: "uppercase" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ color: "red" }}>{this.state.companyError}</div>
                                  <div style={{ color: "red" }}>{this.state.actgrpidError}</div>
                                  <div
                                    className="card-tools col-md-2 float-left"
                                    style={{ marginBottom: 3 }}
                                  ></div>
                                </div>
                                <div className="card-tools col-md-12">
                                  <div className="top-button-group ">
                                    <button
                                      className="btn btn-default"
                                      onClick={this.SavebuttonClicked}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn btn-default"
                                      onClick={this.CancelButtonClicked}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="col-md-5 pr-3 float-left ">
                              <div className="row">
                                <div className="col-md-12 table-header">
                                  <h6>Available Accounts</h6>
                                </div>
                              </div>
                              <div className="col-md-12 pl-0 pr-0">
                                <div className="row rowaddUserGrid">
                                  <div
                                    className="card-body table-responsive addUserGrid p-0"
                                    style={{ maxHeight: 360 }}
                                  >

                                    <BootstrapTable data={this.state.accountGroupsList} selectRow={selectRowProp} striped hover ref={this.onBootstrapTableRef}>
                                      <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                      <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                      <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                      <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                      <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                    </BootstrapTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 pl-2 pr-2 float-left add-group-form">
                              <div className="row">
                                <div
                                  className="card bg-black col-md-12 block-right add-user-form"
                                  style={{ maxHeight: 440 }}
                                >
                                  <div
                                    className="col-md-12 table-header"
                                    style={{
                                      height: 36,
                                      borderBottom: 1,
                                      borderColor: "#ffffff",
                                    }}
                                  >
                                    <h6 style={{ textAlign: "center" }}>
                                      Add or Remove
                                    </h6>
                                  </div>
                                  <div className="card-body">
                                    <form>
                                      <div className="row">
                                        <div className="col-md-12 forward-buttons">
                                          <span
                                            className="arrow-btn"
                                            onClick={this.addButtonClicked}
                                          >
                                            <i className="fas fa-angle-right"></i>
                                          </span>
                                        </div>
                                        <div className="col-md-12 forward-buttons">
                                          <span
                                            className="arrow-btn"
                                            onClick={this.removebuttonclicked}
                                          >
                                            <i className="fas fa-angle-left"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 pl-3 float-left add-user-form pr-0">
                              <div className="hold-transition sidebar-mini layout-navbar-fixed">
                                <div className="wrapper">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-12 table-header">
                                        <h6>Selected Accounts</h6>
                                      </div>
                                    </div>
                                    <div className="row rowaddUserGrid" >
                                      <div className="card-body table-responsive addUserGrid p-0" style={{ maxHeight: 360 }}>
                                        {this.state.isaddbuttonClicked ?
                                          this.state.isremovebuttonclicked ?
                                            <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropforselectedaccounts} striped hover ref={this.onBootstrapTableRef}>
                                              <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                              <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm1</TableHeaderColumn>
                                              <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                              <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                              <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                            </BootstrapTable>
                                            :
                                            <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropforselectedaccounts} striped hover ref={this.onBootstrapTableRef}>
                                              <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                              <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm2</TableHeaderColumn>
                                              <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                              <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                              <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                            </BootstrapTable>
                                          :
                                          <BootstrapTable data={this.state.emptyarray} selectRow={selectRowPropforselectedaccounts} striped hover ref={this.onBootstrapTableRef}>
                                            <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                            <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm3</TableHeaderColumn>
                                            <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                            <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                            <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                          </BootstrapTable>
                                        }
                                      </div>
                                      <div style={{ color: "red" }}>{this.state.SelectedAccountsError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    availableAccount: state.csr.availableaccountgrplist,
    accountgroups: state.csr.accountgrouplist,
    notselectedaccounts: state.csr.notselectedaccountgrouplist,
    Companies: state.csr.companiesList,
    selectedaccountgroups: state.csr.selectedaccountgrouplist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    availableaccountList: () => {
      return dispatch(availableaccountList())
    },
    notselectedaccountGroupList: (id) => {
      return dispatch(notselectedaccountGroupList(id))
    },
    addaccountgroup: (formData) => {
      return dispatch(addaccountgroup(formData))
    },
    selectedaccountGroupList: (id) => {
      return dispatch(selectedaccountGroupList(id))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(addAccountGroup)
