import axios from 'axios'
import config from './config'

export const historicalstatementslist = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'historical-statements', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'HISTORICAL_STATEMENTS_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}
