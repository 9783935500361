import axios from 'axios'
import config from './config'

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const priordaytradelist = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'PriorDaytrade-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIORDAY_TRADE_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const priordaypositionlist = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'PriorDayPosition-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIORDAY_POSITION_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const companyaccountList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'getcompanyaccount-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_ACCOUNT_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const accountgroupselection = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'UserSymbol', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_SELECT',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const priordaytradeaccountList = (formData) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'priorselectedaccttradelist', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIOR_TRADE_Account_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const priordayPositionaccountList = (formData) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'priorselectedacctPositionlist', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRIOR_POSITION_Account_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}
