import React from "react";
import { accountGroupList, AccGrpById, getselectedaccbygroup, ActGrpCertifiedUserMailId, ActGrpUpdatedUserMailId, deleteAccountGroup, selectedaccountGroupList, ActGrpCreatedUserMailId } from '../../../../actions/csrAccountGroupAction.js';
import { connect } from "react-redux";
import AddAccountsGroup from './addAccountGroup';
import AccountGroupDetails from "./accountGroupDetails.jsx";
import autoBind from "react-autobind";
import EditAccountGroup from './editAccountGroup'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from 'react-router-dom'
import _ from "lodash";
import WarningPopup from '../../../AlertPopup/alertpopupDesign'
import AlertPopup from '../../../AlertPopup/popupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";



const initialstate = {
  AvailabilityofAccountGroup: [],
  loading: null,
  rowIds: [],
  warningAlert: false,
  successAlert: false,
  successMessage: null,
  isEditButtonClicked: false,
  isAddButtonClicked: false,
  noofcheckboxselected: 0,
  accounts: [],
  ids: [],
  ischeckboxClicked: false,
  form: {
    Created_User_Name: null,
    Updated_User_Name: null,
    Certified_User_Name: null,
    Created_User_Mail_ID: null,
    selectedAccountsforID: [],
    id: null,
    OnboardedDate: null,
    UpdatedDate: null,
    ApprovedDate: null,
    actgroupid: null,
    companyname: null,
    warningAlertforeditbutton: false,
  }
}

class accountGroup extends React.Component {
  state = initialstate;

  async getdata(Listofids) {
    this.state.ids = Listofids;
    await this.props.selectedaccountGroupList(Listofids);
    this.props.selectedaccountgroups && this.props.selectedaccountgroups.map((Actgrp, index) => {
    })
  }

  async getSuccessStatus() {
    this.state.AvailabilityofAccountGroup = []
    let response = await this.props.accountGroupList();
    response.data.body && response.data.body.map((data, index) => {
      this.state.AvailabilityofAccountGroup.push(data.actgroupid)
    })
    this.setState({ isAddButtonClicked: false });
  }

  getdata1(listofselectedaccounts) {
    this.state.accounts = listofselectedaccounts;
  }

  getstatus(e) {
    this.setState(initialstate);
  }

  viewstatus(e) {
    this.setState({ isEditButtonClicked: false });
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  removeData() {
    this.state.rowIds.length
      ? this.setState({ warningAlert: true })
      : this.setState({ warningAlert: false });
  }

  async deleteRecord() {
    this.setState({ warningAlert: false });
    let response = await this.props.deleteAccountGroup(this.state.form);
    if (response && response.status !== undefined && response.status === 200) {
      this.setState({ successMessage: "Account Group Details Deleted Successfully" });
      this.setState({ successAlert: true });
      this.state.AvailabilityofAccountGroup = []
      let accoutGrpList = await this.props.accountGroupList();

      accoutGrpList.data.body && accoutGrpList.data.body.map((data, index) => {
        this.state.AvailabilityofAccountGroup.push(data.actgroupid)
      })
      this.setState({ rowIds: [], noofcheckboxselected: 0 });
    }
  }

  async componentDidMount() {
    let response = await this.props.accountGroupList();

    response.data.body && response.data.body.map((data, index) => {
      this.state.AvailabilityofAccountGroup.push(data.actgroupid)
    })
  }

  onOpenEditModal() {
    this.state.form.selectedAccountsforID = [];
    this.props.selectedaccountgroups && this.props.selectedaccountgroups.map((Actgrp, index) => {
      this.state.form.selectedAccountsforID.push(Actgrp);
    })

    if (this.state.noofcheckboxselected !== 0) {
      this.setState({ isEditButtonClicked: true });
    }
    else {
      this.setState({ warningAlertforeditbutton: true });
    }
  }

  onOpenAddModal() {
    this.setState({ isAddButtonClicked: true });
  }


  cancelAlert() {
    this.setState({ warningAlert: false });
    this.setState({ successAlert: false });
    this.setState({ showAddModal: false });
    this.setState({ warningAlertforeditbutton: false });
  }

  onGetWarningStatus() {
    this.setState({ warningAlert: false, ischeckboxClicked: false })
    this.deleteRecord()
  }

  cancelclicked() {
    this.setState({ warningAlert: false, ischeckboxClicked: false, showAddModal: false, isEditButtonClicked: false })
    this.state.rowIds = []
  }

  ongetstatus() {
    this.setState({ successAlert: false, warningAlertforeditbutton: false })
  }


  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        this.setState({ ischeckboxClicked: false, loading: true })
        if (isSelect === true) {
          this.state.form.Created_User_Mail_ID = []
          this.state.form.Created_User_Name = []
          this.state.form.Updated_User_Name = []
          this.state.form.Certified_User_Name = []
          this.state.form.OnboardedDate = []
          this.state.form.UpdatedDate = []
          this.state.form.ApprovedDate = []
          this.state.rowIds = []

          this.setState({ noofcheckboxselected: this.state.noofcheckboxselected + 1 })

          this.state.rowIds.push(row.id);
          let response = await this.props.AccGrpById(row.id);

          console.log(response)


          if (response && response.status === 200) {
            let form = [];
            form["companyname"] = response.data.body.companyname;
            form["actgroupid"] = response.data.body.actgroupid;
            form["id"] = response.data.body.id;
            form["OnboardedDate"] = response.data.body.onboardeddate;
            form["UpdatedDate"] = response.data.body.updatedate;
            form["ApprovedDate"] = response.data.body.certifieddate;
            form["active"] = response.data.body.active;
            this.setState(_.extend(this.state.form, form));
          }

          let getCreatedUserMailID = await this.props.ActGrpCreatedUserMailId(response.data.body.onboardedby);
          let getUpdatedUserName = await this.props.ActGrpUpdatedUserMailId(response.data.body.updatedby);
          let getCertifiedUserName = await this.props.ActGrpCertifiedUserMailId(response.data.body.certifiedby);

          let form = [];
          if (getCreatedUserMailID !== undefined && getCreatedUserMailID.data.body !== null) {
            form['Created_User_Mail_ID'] = getCreatedUserMailID.data.body.useremailid;
            form['Created_User_Name'] = getCreatedUserMailID.data.body.userfirstname + " " + getCreatedUserMailID.data.body.userlastname;
          }
          if (getUpdatedUserName !== undefined && getUpdatedUserName.data.body !== null) {
            form['Updated_User_Name'] = getUpdatedUserName.data.body.userfirstname + " " + getUpdatedUserName.data.body.userlastname;
          }
          if (getCertifiedUserName !== undefined && getCertifiedUserName.data.body !== null) {
            form['Certified_User_Name'] = getCertifiedUserName.data.body.userfirstname + " " + getCertifiedUserName.data.body.userlastname;
          }
          this.setState(_.extend(this.state.form, form));

          this.setState({ ischeckboxClicked: true, loading: false })
        } else {
          this.setState({ noofcheckboxselected: this.state.noofcheckboxselected - 1 })

          this.setState({ ischeckboxClicked: false });
        }
      }
    }

    return (
      this.state.warningAlert ? (<WarningPopup alertType={'warningAlert'} getStatus={this.onGetWarningStatus} Message={'Are You sure want to delete this Account Group details'} cancelStatus={this.cancelclicked} />) :
        this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={this.state.successMessage} />) :
          this.state.warningAlertforeditbutton ? (<AlertPopup getStatus={this.ongetstatus} Message='Please Select any AccountGroup' />) :

            !this.state.isEditButtonClicked ?

              !this.state.isAddButtonClicked ?

                <div className="content-wrapper">

                  <div className="hold-transition sidebar-mini layout-navbar-fixed">
                    <div className="wrapper">
                      <section className="content-header">
                        <div className="container-fluid">
                          <div className="row mb-2">
                            <div className="col-sm-4">
                              <ol className="breadcrumb float-sm-left">
                                <li className="breadcrumb-item"><a href="#">CSR</a></li>
                                <li className="breadcrumb-item"><a href="#">Client Onboarding</a></li>
                                <li className="breadcrumb-item active">Account Groups</li>
                              </ol>
                            </div>
                            <div className="col-sm-5">
                              <div className="card-head p-0">
                                <ul className="dash-sidebar-menu float-sm-left">
                                  <li className="dash-sidebar-item">
                                    <Link to="/dashboard/csr-CsrCompanies" className="nav-link">Companies</Link>
                                  </li>
                                  <li className="dash-sidebar-item active">
                                    <Link to="/dashboard/csr-AccountGroup" className="nav-link">Account Groups</Link>
                                  </li>
                                  <li className="dash-sidebar-item">
                                    <Link to="/dashboard/csr-Users" className="nav-link">Users</Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="content">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12">
                              <div className="card bg-black csr-onboarding">
                                <div className="card-header card-onboarding">
                                  <div className="card-tools col-md-12 pl-0">
                                    <div className="top-button-group row">
                                      <button className="btn btn-default" onClick={this.onOpenAddModal}>Add</button>

                                      <button className="btn btn-default" onClick={this.onOpenEditModal}>Edit</button>

                                      <button className="btn btn-default" onClick={this.removeData}>Delete</button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 pl-0 pr-0">
                                  <div className="col-md-6 pr-3 float-left">
                                    <div className="row">
                                      <div className="col-md-12 table-header">
                                        <h6>List of Account Groups</h6>
                                      </div>
                                    </div>
                                    <div className="row rowAccountGroupGrid">
                                      <div className="card-body table-responsive p-0 account-group" style={{ maxHeight: 440 }}>

                                        <BootstrapTable data={this.props.accountgroups} selectRow={selectRowProp} striped hover>
                                          <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"360px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                          <TableHeaderColumn width={"350px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                        </BootstrapTable>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.ischeckboxClicked ?
                                    this.state.loading ? (<div className="d-inline-block clientonboarding"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                                      <AccountGroupDetails formDetails={this.state.form}
                                        senddata={this.getdata} senddata1={this.getdata1}
                                      />
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                :
                <AddAccountsGroup getSuccessStatus={this.getSuccessStatus} AvailabilityofAccountGroup={this.state.AvailabilityofAccountGroup} />
              :
              <EditAccountGroup
                showaccountGroupStatus={this.getstatus}
                selectedidsDetails={this.state.ids}
                formDetails={this.state.form}
                getviewstatus={this.viewstatus}
                selectedaccounts={this.state.accounts}
              />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountgroups: state.csr.accountgrouplist,
    getselectedaccounts: state.csr.getselectedaccountgroup,
    selectedaccountgroups: state.csr.selectedaccountgrouplist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountGroupList: () => {
      return dispatch(accountGroupList());
    },
    AccGrpById: (id) => {
      return dispatch(AccGrpById(id));
    },
    getselectedaccbygroup: (formdata) => {
      return dispatch(getselectedaccbygroup(formdata));
    },
    deleteAccountGroup: (formdata) => {
      return dispatch(deleteAccountGroup(formdata));
    },
    selectedaccountGroupList: (id) => {
      return dispatch(selectedaccountGroupList(id));
    },
    ActGrpCreatedUserMailId: (id) => {
      return dispatch(ActGrpCreatedUserMailId(id));
    },
    ActGrpUpdatedUserMailId: (id) => {
      return dispatch(ActGrpUpdatedUserMailId(id));
    },
    ActGrpCertifiedUserMailId: (id) => {
      return dispatch(ActGrpCertifiedUserMailId(id));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(accountGroup);