import autoBind from 'react-autobind'
import React, { Component } from 'react'
import Header from './layouts/header.jsx'
import Sidebar from './layouts/sidebar.jsx'
import Footer from './layouts/footer.jsx'
import { authMiddleware } from './../utils/authMiddleware.js'
import { Redirect } from "react-router"

class Login extends Component {

  state = {
    authStatus: false
  }

  constructor(props) {
    super(props)
    autoBind(this)
  }

  loginStatus(status) {
    this.setState({ authStatus: status })
  }

  render() {
    if (this.state.authStatus || authMiddleware()) {
      return <Redirect to={'/dashboard'} />
    }
    return (
      <div className="hold-transition sidebar-mini layout-navbar-fixed">
        <div className="wrapper">
          <Header authStatus={this.loginStatus} />
          <div className="content-wrapper">
          </div>
          <Sidebar />
          <Footer />
        </div>
      </div>
    )
  }
}

export default Login
