import Routes from './Routes'
import React, { Component } from 'react'

class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Routes />
    )
  }

}

export default App