import React from "react"
import { Link } from "react-router-dom"
import _ from "lodash"
import {
  accountGroupList,
  availableaccountList,
  notselectedaccountGroupList,
  addaccountgroup,
  selectedaccountGroupList,
  AccountGroupEdit,
  UpdateActiveStatusforAG
} from "../../../../actions/csrAccountGroupAction.js"
import { companiesList } from "../../../../actions/csrCompaniesAction"
import { connect } from "react-redux"
import autoBind from "react-autobind"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import AlertPopup from '../../../AlertPopup/popupDesign'
import jwtDecode from "jwt-decode";


class editAccountGroup extends React.Component {
  state = {
    actgrpidError: null,
    companyError: null,
    notselectedaccounts: [],
    selectedaccountgroups: [],
    SelectedAccountsError: null,
    accountAfterAddButtonClicked: [],
    accountafterremoveButtonClicked: [],
    ids: [],
    selectedids: [],
    selectedaccountsids: [],
    iscancelbuttonclicked: false,
    rowids: [],
    isrowselected: false,
    leftcheckboxids: [],
    rightcheckboxids: [],
    selectedaccountgrouplist: [],
    removedaccountgrouplist: [],
    form: {
      id: this.props.formDetails.id,
      active: "NO",
      companyname: null,
      actgroupid: null,
      items: [],
      selectedAccountGroupids: []
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  Active_Status_handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
    this.onActiveStatusUpdate()
  }

  async onActiveStatusUpdate() {
    let response = await this.props.UpdateActiveStatusforAG(this.state.form)
    if (response && response.status === 200) {
      window.location.reload(false)
    }
  }


  validation() {
    let checkActGrpAvailability = false
    if (this.state.form.actgroupid === null || this.state.form.actgroupid === "") {
      this.setState({ actgrpidError: "Enter Account Group Name" })
      return false
    } else {
      this.props.AvailabilityofAccountGroup && this.props.AvailabilityofAccountGroup.map((data, index) => {
        if (data === this.state.form.actgroupid) {
          checkActGrpAvailability = true
        }
      })
      if (checkActGrpAvailability) {
        this.setState({ actgrpidError: "Account Group Name Already Exist" })
        return false
      }
      else {
        this.setState({ actgrpidError: "" })
      }
    }
    if (this.state.form.companyname === null) {
      this.setState({ companyError: "Select any Company" })
      return false
    } else {
      this.setState({ companyError: "" })
    }
  }

  async onupdateButttonClick() {
    let validation = this.validation()
    if (this.state.form.items.length !== 0) {
      if (validation !== false) {
        let response = await this.props.AccountGroupEdit(this.state.form)
        if (response && response.status === 200) {
          this.setState({ successMessage: "Updated Successfully" })
          this.setState({ successAlert: true })
        }
      }
    }
    else {
      this.setState({ SelectedAccountsError: "Please select atleast one account" })
    }
  }

  handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
  }



  async componentDidMount() {

    if (this.props.formDetails.ApprovedDate !== null) {
      let aprdte = this.props.formDetails.ApprovedDate.replace('T', ' ')
      this.state.ApprovedDate = aprdte.replace('.000Z', '')
    }
    if (this.props.formDetails.UpdatedDate !== null) {
      let updte = this.props.formDetails.UpdatedDate.replace('T', ' ')
      this.state.UpdatedDate = updte.replace('.000Z', '')
    }
    if (this.props.formDetails.OnboardedDate !== null) {
      let onboarddte = this.props.formDetails.OnboardedDate.replace('T', ' ')
      this.state.OnboardedDate = onboarddte.replace('.000Z', '')
    }

    this.props.notselectedaccounts && this.props.notselectedaccounts.map((data, index) => {
      this.state.notselectedaccounts.push(data)
    })

    this.props.selectedaccountgroups && this.props.selectedaccountgroups.map((data, index) => {
      this.state.selectedaccountgroups.push(data)
      this.state.accountAfterAddButtonClicked.push(data)
    })

    console.log(this.state.selectedaccountgroups)

    this.props.formDetails.selectedAccountsforID && this.props.formDetails.selectedAccountsforID.length && this.props.formDetails.selectedAccountsforID.map((accounts, index) => {
      this.state.form.items.push(accounts)
      this.state.ids.push(accounts.id)
    })

    let form = {}
    form["companyname"] = this.props.formDetails.companyname
    form["actgroupid"] = this.props.formDetails.actgroupid
    this.setState(_.extend(this.state.form, form))
  }


  async addButtonClicked() {
    if (this.state.isrowselected) {
      this.state.accountafterremoveButtonClicked = []
      this.state.selectedaccountgrouplist && this.state.selectedaccountgrouplist.map((selected, index) => {
        this.state.accountAfterAddButtonClicked.push(selected)
        this.state.selectedaccountgroups.push(selected)
      })

      this.setState({ isaddbuttonClicked: true })
      this.state.leftcheckboxids && this.state.leftcheckboxids.map((ids, index) => {
        this.state.notselectedaccounts = this.state.notselectedaccounts.filter((accountselected) => {
          return accountselected.id !== ids
        })
      })
    }
    this.state.selectedaccountgrouplist = []
    this.setState({ isremovebuttonclicked: false })
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
    console.log(this.state.notselectedaccounts, this.state.selectedaccountgroups)
  }

  async removebuttonclicked() {
    let flag = false
    if (this.state.isrowselected1) {
      let form = _.cloneDeep(this.state)
      let containsids = this.state.leftcheckboxids
      let removedid = this.state.rightcheckboxids
      let dif = _.differenceWith(containsids, removedid, _.isEqual)
      form['leftcheckboxids'] = dif
      await this.setState(_.extend(this.state, form))

      this.setState({ isremovebuttonclicked: true })

      this.state.removedaccountgrouplist && this.state.removedaccountgrouplist.map((accountselected, index) => {
        this.state.notselectedaccounts.push(accountselected)
        this.state.selectedaccountgroups = this.state.selectedaccountgroups.filter((actgrpid) => {
          console.log('accountselected.id', accountselected.id, 'actgrpid.id', actgrpid.id)
          return accountselected.id !== actgrpid.id
        })
      })
      this.setState({ isrowselected1: false })
      this.setState({ isrowselected: false })
    }
    this.state.removedaccountgrouplist = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
    console.log(this.state.notselectedaccounts, this.state.selectedaccountgroups)
  }


  cancelbuttonclick() {
    this.props.getviewstatus(false)
    this.props.showaccountGroupStatus(true)
  }

  CancelButtonClicked() {
    this.state.ids = []
    this.state.selectedids = []
    this.state.selectedaccountsids = []
    this.state.rowids = []
    this.props.getSuccessStatus()
  }

  ongetstatus() {
    this.setState({ successAlert: false })
    this.props.getviewstatus(false)
  }

  render() {
    const selectRowProp = {
      mode: "checkbox",
      selected: this.state.leftcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected: true })

          this.state.leftcheckboxids.push(row.id)

          //Get checked Id Details
          let selected = this.state.notselectedaccounts.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.selectedaccountgrouplist.push(selected)
        }
        else {
          //Remove Unchecked ID from List
          let ids = this.state.leftcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.leftcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.leftcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.selectedaccountgrouplist = this.state.selectedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      }
    }


    const selectRowPropforselectedacc = {
      mode: "checkbox",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected1: true })

          //Add selected account from rightsidechekcboxList
          this.state.rightcheckboxids.push(row.id)

          //Add removed Id details
          let selected = this.state.accountAfterAddButtonClicked.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.removedaccountgrouplist.push(selected)

          this.state.accountafterremoveButtonClicked = this.state.accountafterremoveButtonClicked.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
        else {
          //Remove id from rightsidechekcboxList 
          let ids = this.state.rightcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.rightcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.rightcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.removedaccountgrouplist = this.state.removedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      }
    }

    let AccountGroupList
    if (this.props.Companies) {
      AccountGroupList = this.props.Companies.map((company) => (
        <option key={company.id}> {company.companyname}</option>
      ))
    }
    return (
      this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={'Account Group Edited Successfully'} />) :
        <div classNameName="hold-transition sidebar-mini layout-navbar-fixed">
          <div className="hold-transition sidebar-mini layout-navbar-fixed">
            <div className="wrapper">
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <ol className="breadcrumb float-sm-left">
                          <li className="breadcrumb-item">
                            <a href="#">CSR</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Client Onboarding</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Account Groups</a>
                          </li>
                          <li className="breadcrumb-item active">Edit account Groups</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="card bg-black csr-onboarding">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card-header card-onboarding col-md-6 float-left">
                                    <div className="card-tools col-md-12 float-left" style={{ marginBottom: 3 }}>
                                      <div className="input-group search-fields input-group-sm">
                                        <label>Company:</label>
                                        <select name="companyname" onChange={this.handleInputChange} value={this.state.form.companyname}>
                                          <option value="0">Select</option>
                                          {AccountGroupList}
                                        </select>
                                        <label className="ml-3">Account Group:</label>
                                        <input type="text" name="actgroupid" onChange={this.handleInputChange} value={this.state.form.actgroupid} />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ color: "red" }}>{this.state.companyError}</div>
                                  <div style={{ color: "red" }}>{this.state.actgrpidError}</div>
                                  <div className="card-tools col-md-2 float-left" style={{ marginBottom: 3 }}>
                                  </div>
                                </div>

                                <div className="card-tools col-md-12">
                                  <div className="top-button-group ">

                                    <button className="btn btn-default" onClick={this.onupdateButttonClick}>Update</button>
                                    <Link to="/dashboard/csr-AccountGroup" className="btn btn-default add-user-btn" onClick={this.cancelbuttonclick}>Cancel</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="col-md-5 pr-3 float-left ">
                              <div className="row">
                                <div className="col-md-12 table-header">
                                  <h6>Available Accounts</h6>
                                </div>
                              </div>
                              <div className="row rowaddUserGrid">
                                <div className="card-body table-responsive addUserGrid p-0" style={{ maxHeight: 360 }}>

                                  <BootstrapTable data={this.state.notselectedaccounts} selectRow={selectRowProp} striped hover>
                                    <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                    <TableHeaderColumn width={"70"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                    <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                    <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                    <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 pl-2 pr-2 float-left add-group-form">
                              <div className="row">
                                <div className="card bg-black col-md-12 block-right add-user-form" style={{ maxHeight: 440 }}>
                                  <div className="col-md-12 table-header" style={{ height: 36, borderBottom: 1, borderColor: '#ffffff' }}>
                                    <h6 style={{ textAlign: "center" }}>Add or Remove</h6>
                                  </div>
                                  <div className="card-body">
                                    <form>
                                      <div className="row">
                                        <div className="col-md-12 forward-buttons">
                                          <span
                                            className="arrow-btn"
                                            onClick={this.addButtonClicked}
                                          >
                                            <i className="fas fa-angle-right"></i>
                                          </span>
                                        </div>
                                        <div className="col-md-12 forward-buttons">
                                          <span
                                            className="arrow-btn"
                                            onClick={this.removebuttonclicked}
                                          >
                                            <i className="fas fa-angle-left"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-5 pl-3 float-left add-user-form pr-0">
                              <div className="row">
                                <div className="col-md-12 table-header">
                                  <h6>Selected Accounts</h6>
                                </div>
                              </div>
                              <div className="row rowaddUserGrid">
                                <div className="card-body table-responsive addUserGrid p-0" style={{ maxHeight: 180 }}>

                                  <BootstrapTable data={this.state.selectedaccountgroups} selectRow={selectRowPropforselectedacc} striped hover>
                                    <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                    <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                    <TableHeaderColumn width={"70px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                    <TableHeaderColumn width={"100px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                    <TableHeaderColumn width={"240px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                                <div style={{ color: "red" }}>{this.state.SelectedAccountsError}</div>
                              </div>
                              <div className="row">
                                <div className="card bg-black col-md-12 block-right" style={{ maxHeight: 160, marginTop: 15 }}>

                                  <div className="card-body">

                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-3 form-title">Active</div>
                                        {(this.props.formDetails.Created_User_Mail_ID === localStorage.getItem('useremailid')) ?
                                          <div className="col-md-9 form-value">
                                            {this.props.formDetails.active}
                                          </div>
                                          :
                                          <div className="col-md-9 form-value">
                                            <select name="active" value={this.props.formDetails.active} onChange={this.Active_Status_handleInputChange}>
                                              <option>YES</option>
                                              <option>NO</option>
                                            </select>
                                          </div>}
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Onboarded By:</div>
                                        <div className="col-md-9 form-value">{this.props.formDetails.Created_User_Name}</div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Onboarded Date:</div>
                                        <div className="col-md-9 form-value">{this.state.OnboardedDate}</div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Approved By:</div>
                                        <div className="col-md-9 form-value">{this.props.formDetails?.Certified_User_Name !== 'undefined undefined' ? this.props.formDetails.Updated_User_Name : ''}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Approved Date:</div>
                                        <div className="col-md-9 form-value">{this.state.ApprovedDate}</div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Updated By:</div>
                                        <div className="col-md-9 form-value">{this.props.formDetails?.Updated_User_Name !== 'undefined undefined' ? this.props.formDetails.Updated_User_Name : ''}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3 form-title">Updated Date:</div>
                                        <div className="col-md-9 form-value">{this.props.formDetails.UpdatedDate}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    availableAccount: state.csr.availableaccountgrplist,
    accountgroups: state.csr.accountgrouplist,
    notselectedaccounts: state.csr.notselectedaccountgrouplist,
    Companies: state.csr.companiesList,
    selectedaccountgroups: state.csr.selectedaccountgrouplist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    availableaccountList: () => {
      return dispatch(availableaccountList())
    },
    notselectedaccountGroupList: (id) => {
      return dispatch(notselectedaccountGroupList(id))
    },
    addaccountgroup: (formData) => {
      return dispatch(addaccountgroup(formData))
    },
    selectedaccountGroupList: (id) => {
      return dispatch(selectedaccountGroupList(id))
    },
    AccountGroupEdit: (formData) => {
      return dispatch(AccountGroupEdit(formData))
    },
    UpdateActiveStatusforAG: (formData) => {
      return dispatch(UpdateActiveStatusforAG(formData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(editAccountGroup)