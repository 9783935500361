import { Provider } from 'react-redux'
import Login from './components/login.jsx';
import Assets from './components/csr/generalConfiguration/assets.jsx';
import Entity from './components/csr/generalConfiguration/entity.jsx';
import Region from './components/csr/generalConfiguration/region.jsx';
import Product from './components/csr/generalConfiguration/product.jsx';
import Companies from './components/csr/clientOnBoarding/companies/companies.jsx';
import AccountGroup from './components/csr/clientOnBoarding/accountGroups/accountGroup.jsx';
import Relationship from './components/csr/generalConfiguration/relationship.jsx';
import Tier from './components/csr/generalConfiguration/tier.jsx';
import AddAccountGroup from './components/csr/clientOnBoarding/accountGroups/addAccountGroup';
import AddUser from './components/csr/clientOnBoarding/users/addUser.jsx';
import EditAccountGroup from './components/csr/clientOnBoarding/accountGroups/editAccountGroup';
import Users from './components/csr/clientOnBoarding/users/users';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store';
import { authMiddleware } from './utils/authMiddleware.js';
import Header from './components/layouts/header.jsx';
import Footer from './components/layouts/footer.jsx';
import PrivateRoute from './PrivateRoute.jsx';
import IntradayTradeandPosition from './components/IntraPriorDay/IntradayTradeandPositions';
import PriorTradePosition from './components/IntraPriorDay/PriordayTradePositions';
import PriorDayMoneyline from './components/IntraPriorDay/PriorDayMoneyline';
import Reports from './components/reports/Reports';
import Statements from './components/statements/statements';
import HistoricalReports from './components/histrical-Reports/historicalreports';
import HistoricalStatements from './components/historical-Statements/historicalStatements';
import ScheduleUser from './components/schedulers/schedule_User';
import Scheduler from './components/schedulers/scheduler';
import SchedulerAcc from './components/schedulers/scheduler_acc';
import generatePdf1 from './components/statements/Sample';
import ListofS3objects from './components/s3Bucket/ListofObjects';
import Batchstatus from './components/BatchStatus/batchStatus';
import SelectionComponent from './components/IntraPriorDay/SelectionComponent.jsx';
import ContactUs from './components/ContactUS.jsx';
import ListOfSchedulers from './components/schedulers/ListOfSchedulers.jsx';


export default () => {
        const requireLogin = (to, from, next) => {
                if (authMiddleware()) {
                        next();
                }
                else {
                        next.redirect('/');
                }
        };

        return (
                <Router >
                        <Provider store={store}>
                                <Switch>
                                        <Route path="/" component={Login} exact={true} />
                                        <div className="sidebar-mini layout-navbar-fixed">
                                                <div className="wrapper">
                                                        <Header/>
                                                        <PrivateRoute path="/dashboard" component={SelectionComponent} exact={true} />
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-asset-class" component={Assets} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-entity-type" component={Entity} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-region" component={Region} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-product" component={Product} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-relationship" component={Relationship} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-tier" component={Tier} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-CsrCompanies" component={Companies} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-AccountGroup" component={AccountGroup} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-Users" component={Users} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-AddAccountGroup" component={AddAccountGroup} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-AddUser" component={AddUser} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                  <PrivateRoute path="/dashboard/csr-ListOfSchedulers" component={ListOfSchedulers} exact={true} /> : ""}
                                                        {localStorage.getItem('userType') === "INTERNAL" ?
                                                                <PrivateRoute path="/dashboard/csr-EditAccountGroup" component={EditAccountGroup} exact={true} /> : ""}
                                                        <PrivateRoute path="/Select-IntradayTradeandPositions" component={IntradayTradeandPosition} exact={true} />
                                                        <PrivateRoute path="/Select-PriordayTradePositions" component={PriorTradePosition} exact={true} />
                                                        <PrivateRoute path="/Select-PriordayMoneyline" component={PriorDayMoneyline} exact={true} />
                                                        <PrivateRoute path="/dashboard/reports" component={Reports} exact={true} />
                                                        <PrivateRoute path="/dashboard/statements" component={Statements} exact={true} />
                                                        <PrivateRoute path="/dashboard/historicalreports" component={HistoricalReports} exact={true} />
                                                        <PrivateRoute path="/dashboard/historicalstatements" component={HistoricalStatements} exact={true} />
                                                        <PrivateRoute path="/dashboard/ScheduleUser" component={ScheduleUser} exact={true} />
                                                        <PrivateRoute path="/dashboard/Scheduler" component={Scheduler} exact={true} />
                                                        <PrivateRoute path="/dashboard/SchedulerAcc" component={SchedulerAcc} exact={true} />
                                                        <PrivateRoute path="/pdfViewer" component={generatePdf1} exact={true} />
                                                        <PrivateRoute path="/ListofS3objects" component={ListofS3objects} exact={true} />
                                                        <PrivateRoute path="/Batchstatus" component={Batchstatus} exact={true} />
                                                        <PrivateRoute path='/contact-us' component={ContactUs} exact={true} />
                                                        <Footer />
                                                </div>
                                        </div>
                                </Switch>
                        </Provider>
                </Router>
        );
}
