import autoBind from 'react-autobind'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'


class Topmenu extends Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    return (
      <nav className="main-header navbar navbar-expand navbar-dark" style={{ top: 57 }}>
        <ul className="navbar-nav">
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard" className="nav-link" onClick={this.CheckAuth}>Dashboards</Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard/reports" className="nav-link" onClick={this.CheckAuth}>Reports</Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard/historicalreports" className="nav-link" onClick={this.CheckAuth}>Historical Reports</Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard/statements" className="nav-link" onClick={this.CheckAuth}>Statements</Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard/historicalstatements" className="nav-link" onClick={this.CheckAuth}>Historical Statements</Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/ListofS3objects" className="nav-link" onClick={this.CheckAuth}>My Console</Link>
          </li>

          {localStorage.getItem('userType') === "INTERNAL" ?
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/Batchstatus" className="nav-link" onClick={this.CheckAuth}>Batch Status</Link>
            </li>
            : ""}

          {localStorage.getItem('userType') === "INTERNAL" ?
            <li className="nav-item dropdown dropdown-hover">
              <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">CSR</a>
              <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                <li><Link to="/dashboard/csr-CsrCompanies" className="nav-link" onClick={this.CheckAuth}>Client Onboarding</Link></li>
                <li><Link to="/dashboard/csr-ListOfSchedulers" className="nav-link" onClick={this.CheckAuth}>Scheduled List</Link></li>
                <li><Link to="/dashboard/csr-asset-class" className="nav-link" onClick={this.CheckAuth}>General Configuration</Link></li>
              </ul>
            </li>
            : ""}

          <li className="nav-item d-none d-sm-inline-block li-contactus">
            <Link to="/contact-us" className="nav-link" onClick={this.CheckAuth}>Contact Us</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Topmenu
