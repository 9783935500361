import axios from 'axios'
import config from './config'

export const productList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'product-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRODUCT_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const productAdd = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-product', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRODUCT_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const productEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-product', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRODUCT_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const productById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-product/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRODUCT_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const productDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-product', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'PRODUCT_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}