import axios from 'axios'
import config from './config'

export const authAction = (email, password) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'login', { useremailid: email, password: password })
            .then(response => {
                localStorage.setItem('refreshToken', response.data.body.refreshToken)
                localStorage.setItem('accessToken', response.data.body.token)
                localStorage.setItem('refreshToken', response.data.body.refreshToken)
                localStorage.setItem('userfirstname', response.data.body.userDetails.userfirstname)
                localStorage.setItem('userlastname', response.data.body.userDetails.userlastname)
                localStorage.setItem('userId', response.data.body.userDetails.id)
                localStorage.setItem('userType', response.data.body.userDetails.usertp)
                localStorage.setItem('useremailid', response.data.body.userDetails.useremailid)
                localStorage.setItem('useridforuser', response.data.body.userDetails.userid)
                localStorage.setItem('companyname', response.data.body.userDetails.companyname)
                dispatch({
                    type: 'USER_LOGIN',
                    value: true
                })
                window.location.href = "/dashboard"
            })
            .catch(error => {
                console.log(error)
            })
    }
}

