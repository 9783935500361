import axios from 'axios'
import config from './config'

export const GetObjectsList = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'ListofOjectsinS3', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'OBJECT_LISTED_SUCESS',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const GetDownloadURl = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'DownloadObjfromS3', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'DOWNLOAD_URL_SUCESS',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}
