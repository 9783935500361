import axios from 'axios';
import config from './config';

export const getListofBatchStatus = ()  => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + '/BatchStatusList', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'BATCH_STATUS_LIST',
                    value: response.data.body
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const getMaxCOBDate = () => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + '/MaxCOBDate', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_MAX_COB_DATE',
                    value: response.data.body
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};


export const getRegionList = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + '/RegionList', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_REGION_LIST',
                    value: response.data.message
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const getmoduleList = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + '/ModuleList', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_MODULE_LIST',
                    value: response.data.message
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const getEndTimeList = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + '/getEndTime', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_END_TIME_LIST',
                    value: response.data.message
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const getFullTableData = (formData) => {
    return async function(dispatch) {
        return await axios.post(config.apibaseUrl + '/getwholeTableData', formData, axiosconfig)
        .then(response => {
            dispatch({
                type: 'GET_FULL_TABLE_DATA',
                value: response.data.body
            });
            return response;
        })
        .catch(error => {
            console.log(error)
        })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
};