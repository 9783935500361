import React from 'react'
import autoBind from "react-autobind"

class Popup extends React.Component {
    state = {
        AlertMessage: this.props.Message
    }

    constructor(props) {
        super(props)
        autoBind(this)
      }

    confirmButtonClicked() {
        this.props.getStatus(true)
    }

    render() {
        return (
            <div className="modal-wrapper">

                <div id="myModal" className="popupMessage">
                    <div className="modal-head">
                        <span className="close" onClick={this.confirmButtonClicked}>&times;</span>
                        <h4>Message</h4>
                    </div>
                    <div className="modal-content">
                        <p>{this.state.AlertMessage}</p>
                        <button className="modal-button" onClick={this.confirmButtonClicked}>Ok</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Popup;