import React from 'react'
import { accountGroupList, accountGroupListforExternalUser, accountGroupListforInternalUser } from '../../actions/csrAccountGroupAction'
import { connect } from "react-redux"
import { GetReportsDetails,AddToS3 } from '../../actions/ReportsAction'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import autoBind from "react-autobind"
import _ from "lodash"
import { ExportToCsv } from 'export-to-csv'
import jsPDF from 'jspdf'
import S3Objects from '../s3Bucket/ListofObjects'
import WarningPopup from '../AlertPopup/popupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"


const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
}

class SchedulerAcc extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    fromdateError: null,
    toDateError: null,
    noDataError: false,
    isrowselected: false,
    emptyarray: [],
    fileformat: [],
    rightcheckboxids: [],
    leftcheckboxids: [],
    removedaccountgrouplist: [],
    selectedaccountgrouplist: [],
    accountGroupsList: [],
    accountAfterAddButtonClicked: [],
    accountafterremoveButtonClicked: [],
    bindrightSideafterClkRmvBtn: [],
    isremovebuttonclicked: false,
    isaddbuttonClicked: false,
    form: {
      fromdate: null,
      todate: null,
      selectedReportViewName: null,
      UserID: localStorage.getItem('useridforuser'),
      ReportId: null,
      UserType: localStorage.getItem('userType'),
      REPORTSYSTEM: null,
      REPORTBATCHID: null,
      REPORTBATCHTYPE: null,
      REPORTLOADTYPE: null,
      name: null,
      firmofficeaccounts: []
    },
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  formreset() {
    this.state.emptyarray = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
    this.state.removedaccountgrouplist = []
    this.state.selectedaccountgrouplist = []
    this.state.accountGroupsList = []
    this.state.accountAfterAddButtonClicked = []
    this.state.accountafterremoveButtonClicked = []
    this.state.bindrightSideafterClkRmvBtn = []
  }

  handleInputChange(e) {
    if (this.props.fromHistoricalStatements === 'fromhistoricalStatements') {
      if (this.props.historicalStatementname.REPORTCATAGORY === 'H' && this.props.historicalStatementname.REPORTFREQUENCY === 'M') {
        let form = {}
        form['fromdate'] = e.target.value + "-1"
        form['todate'] = e.target.value + "-31"
        this.setState(_.extend(this.state.form, form))
      } else {
        if (e.target.name === "fromdate" || e.target.name === "todate") {
          this.setState({ value: e.target.value })
          let form = {}
          form[e.target.name] = e.target.value + " 00:00:00"
          this.setState(_.extend(this.state.form, form))
        }
        else {
          this.setState({ value: e.target.value })
          let form = {}
          form[e.target.name] = e.target.value
          this.setState(_.extend(this.state.form, form))
        }
      }
    } else {
      if (this.props.historcalReport.REPORTCATAGORY === 'H' && this.props.historcalReport.REPORTFREQUENCY === 'M') {
        let form = {}
        form['fromdate'] = e.target.value + "-1"
        form['todate'] = e.target.value + "-31"
        this.setState(_.extend(this.state.form, form))
      } else {
        if (e.target.name === "fromdate" || e.target.name === "todate") {
          this.setState({ value: e.target.value })
          let form = {}
          form[e.target.name] = e.target.value + " 00:00:00"
          this.setState(_.extend(this.state.form, form))
        }
        else {
          this.setState({ value: e.target.value })
          let form = {}
          form[e.target.name] = e.target.value
          this.setState(_.extend(this.state.form, form))
        }
      }
    }
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  addButtonClicked() {
    if (this.state.isrowselected) {
      this.state.accountafterremoveButtonClicked = []
      this.state.selectedaccountgrouplist && this.state.selectedaccountgrouplist.map((selected, index) => {
        this.state.accountAfterAddButtonClicked.push(selected)
        this.state.accountafterremoveButtonClicked.push(selected)
      })
      this.setState({ isaddbuttonClicked: true })
      this.state.leftcheckboxids && this.state.leftcheckboxids.map((ids, index) => {
        this.state.accountGroupsList = this.state.accountGroupsList.filter((accountselected) => {
          return accountselected.id !== ids
        })
      })
    }
    this.state.selectedaccountgrouplist = []
    this.setState({ isremovebuttonclicked: false })
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
  }

  runButtonClicked() {
    this.state.form.firmofficeaccounts = []
    this.state.accountAfterAddButtonClicked && this.state.accountAfterAddButtonClicked.map((account, index) => {
      this.state.form.firmofficeaccounts.push(account.firmofficeacct)
      this.addtos3();
    })
  }

  validation() {
    if (this.state.form.fromdate === null) {
      this.setState({ fromdate: "Choose From Date" })
      return false
    } else {
      this.setState({ fromdateError: "" })
    }
    if (this.state.form.todate === null) {
      this.setState({ toDateError: "Choose To Date" })
      return false
    } else {
      this.setState({ toDateError: "" })
    }
  }

  async exportdata() {

    this.state.form.firmofficeaccounts = []
    this.state.accountAfterAddButtonClicked && this.state.accountAfterAddButtonClicked.map((account, index) => {
      this.state.form.firmofficeaccounts.push(account.firmofficeacct)
    })

    let value = this.validation()

    const csvExporter = new ExportToCsv(options)

    let response = await this.props.GetReportsDetails(this.state.form)

    this.state.data = []

    if (response && response.status === 200) {
      response.data.body && response.data.body.map((reports, index) => {
        this.state.data.push(reports)
      })
    }

    if (this.state.data.length !== 0) {
      csvExporter.generateCsv(this.state.data)
      this.addtos3();
    }
    else {
      this.setState({ noDataError: true })
    }
  }

  async printDocument() {

    this.state.form.firmofficeaccounts = []
    this.state.accountAfterAddButtonClicked && this.state.accountAfterAddButtonClicked.map((account, index) => {
      this.state.form.firmofficeaccounts.push(account.firmofficeacct)
    })
    this.validation()
    this.state.data = []
    let response = await this.props.GetReportsDetails(this.state.form)
    response.data.body && response.data.body.map((statement, index) => {
      this.state.data.push(statement)
    })

    var doc = new jsPDF('p', 'mm', 'a4')

    const usersRows = this.state.data && this.state.data.map(u => {
      const row = [JSON.stringify(u)]
      return row
    })


    doc.autoTable("", usersRows, {

      margin: { top: 10, bottom: 7 },
      styles: { fontSize: 7, cellPadding: 0.69 },
      columnStyles: {
        0: { cellwidth: '200' }
      }
    })

    doc.save('pdfgenerated.pdf')
  }

  async removebuttonclicked() {
    let flag = false
    if (this.state.isrowselected1) {

      let form = _.cloneDeep(this.state)
      let containsids = this.state.leftcheckboxids
      let removedid = this.state.rightcheckboxids
      let dif = _.differenceWith(containsids, removedid, _.isEqual)
      form['leftcheckboxids'] = dif
      await this.setState(_.extend(this.state, form))

      this.setState({ isremovebuttonclicked: true })

      this.state.removedaccountgrouplist && this.state.removedaccountgrouplist.map((accountselected, index) => {
        this.state.accountGroupsList.push(accountselected)
        this.state.accountAfterAddButtonClicked = this.state.accountAfterAddButtonClicked.filter((actgrpid) => {
          return accountselected.id !== actgrpid.id
        })
      })
      this.setState({ isrowselected1: false })
      this.setState({ isrowselected: false })

    }
    this.state.removedaccountgrouplist = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
  }
  addtos3() {
    if (this.state.isrowselected) {
        const data = this.state.data;
        const reportDetails = this.state.reportDetails;
        const form = this.state.form;
        const filepath = localStorage.getItem('companyname').toLowerCase().replace(/\s/g, '')+"/"+localStorage.getItem('useridforuser');
        this.props.AddToS3({ reportDetails, form ,filepath,data});
    }
  }

  async componentDidMount() {

    this.setState({ loading: true })

    this.formreset()

    this.tablerender()

    if (this.props.fromHistoricalStatements !== 'fromhistoricalStatements') {
      let form = {}
      form["name"] = this.props.historicalReportName.REPORTNAME.trim() + "-" + localStorage.getItem('useridforuser')
      form["firmofficeacct"] = this.props.firmofficeacct
      form["selectedReportViewName"] = this.props.historicalReportName.REPORTSRC
      form["ReportId"] = this.props.historicalReportName.REPORTID
      form["REPORTSYSTEM"] = this.props.historicalReportName.REPORTSYSTEM
      form["REPORTLOADTYPE"] = this.props.historicalReportName.REPORTLOADTYPE
      form["REPORTBATCHTYPE"] = this.props.historicalReportName.REPORTBATCHTYPE
      form["REPORTBATCHID"] = this.props.historicalReportName.REPORTBATCHID
      this.setState(_.extend(this.state.form, form))
      this.setState({ fileformat: this.props.historicalReportName.REPORTFORMAT })
    }
    else {
      let form = {}
      form["name"] = this.props.historicalStatementname.REPORTNAME.trim() + "-" + localStorage.getItem('useridforuser')
      form["firmofficeacct"] = this.props.firmofficeacct
      form["selectedReportViewName"] = this.props.historicalStatementname.REPORTSRC
      form["ReportId"] = this.props.historicalStatementname.REPORTID
      form["REPORTSYSTEM"] = this.props.historicalStatementname.REPORTSYSTEM
      form["REPORTLOADTYPE"] = this.props.historicalStatementname.REPORTLOADTYPE
      form["REPORTBATCHTYPE"] = this.props.historicalStatementname.REPORTBATCHTYPE
      form["REPORTBATCHID"] = this.props.historicalStatementname.REPORTBATCHID
      form["CompanyName"] =localStorage.getItem('companyname')
      this.setState(_.extend(this.state.form, form))
      this.setState({ fileformat: this.props.historicalStatementname.REPORTFORMAT })

    }

    this.setState({ loading: false })

  }

  async tablerender() {
    let array = []
    if (localStorage.getItem('userType') == "INTERNAL") {
      let response = await this.props.accountGroupListforInternalUser()
      this.props.getactgrpforInternalUser && this.props.getactgrpforInternalUser.map((accounts, index) => {
        array.push(accounts)
      })

      this.setState({ accountGroupsList: array })
    } else {
      let response = await this.props.accountGroupListforExternalUser(this.state.form)
      if (response.status === 200) {
        if (response.data.body !== "") {
          this.setState({ accountGroupsList: response.data.body })
        }
      }
    }
  }

  cancelButtonClicked() {
    if (this.props.fromHistoricalStatements === 'fromhistoricalStatements') {
      this.props.getViewStatusfromHisoricalSatememts(true)
    }
    else {
      this.props.viewstatus(true)
    }
  }

  cancelAlert() {
    this.setState({ noDataError: false })
  }

  onGetEditWarningStatus() {
    this.setState({ noDataError: false })
  }

  cancelclicked() {
    this.setState({ noDataError: false })
  }

  render() {
    const selectRowProp = {
      mode: "checkbox",
      selected: this.state.leftcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {

        if (isSelect) {

          this.setState({ isrowselected: true })

          //Add selected account to LeftsidechekcboxList
          this.state.leftcheckboxids.push(row.id)

          //Get checked Id Details
          let selected = this.state.accountGroupsList.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.selectedaccountgrouplist.push(selected)

        }
        else {
          //Remove Unchecked ID from List
          let ids = this.state.leftcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.leftcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.leftcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.selectedaccountgrouplist = this.state.selectedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      },
            onSelectAll: (isSelect, rows, e) => {
              if (isSelect) {
                    const allIds = rows.map(row => row.id);
                    this.setState({
                      isrowselected: true,
                      leftcheckboxids: allIds,
                      selectedaccountgrouplist: rows // Assuming accountGroupsList contains all rows
                    });
                  } else {
                    this.setState({
                      isrowselected: false,
                      leftcheckboxids: [],
                      selectedaccountgrouplist: []
                    });
                  }
            },
    }

    const selectRowPropfromleft = {
      mode: "checkbox",
      selected: this.state.rightcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected1: true })

          this.state.rightcheckboxids.push(row.id)

          let selected = this.state.accountAfterAddButtonClicked.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.removedaccountgrouplist.push(selected)

          this.state.accountafterremoveButtonClicked = this.state.accountafterremoveButtonClicked.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
        else {
          let ids = this.state.rightcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.rightcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.rightcheckboxids.push(id)
          })

          this.state.removedaccountgrouplist = this.state.removedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      },
      onSelectAll: (isSelect, rows, e) => {
if (isSelect) {
      const allIds = rows.map(row => row.id);
      this.setState({
        isrowselected1: true,

        rightcheckboxids: allIds,
        removedaccountgrouplist: rows
      });
    } else {
      this.setState({
        isrowselected1: false,
        rightcheckboxids: [],
        removedaccountgrouplist: []
      });
    }
              },
    }


    return (
      this.state.loading ? (<Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner>) :
        this.state.noDataError ? <WarningPopup getStatus={this.onGetEditWarningStatus} Message={'Selected Details has no Matching Records'} cancelStatus={this.cancelclicked} /> :
          !this.state.isMyconsoleClicked ?
            <div className="hold-transition sidebar-mini layout-navbar-fixed">

              <div className="content-wrapper">
                <section className="content-header pb-0">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-left">
                          {this.props.fromHistoricalStatements !== 'fromhistoricalStatements' ?
                            <li className="breadcrumb-item active">Historical Reports</li>
                            :
                            <li className="breadcrumb-item active">Historical Statements</li>
                          }
                          <li className="breadcrumb-item"><a href="#">Run</a></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 dashboard-main-area">
                        <div className="card bg-black">
                          <div className="card-header card-onboarding">
                            <div className="col-md-12 action-group report-actions">
                              <button className="btn btn-default" onClick={this.cancelButtonClicked}>Cancel</button>
                              {this.state.fileformat === "CSV" ?
                                (<button className="btn btn-default" onClick={this.exportdata}>Run</button>)
                                :
                                (<button className="btn btn-default" onClick={this.printDocument}>Run</button>)}
                            </div>
                          </div>
                          <div className="card-body p-0">
                            <div className="col-md-12 scheduler-block ml-2" style={{ width: 'calc(100% - 10px)' }}>
                              <div className="row">
                                <div className="col-12">
                                  <h2>Run</h2>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="col-md-12">
                                    <p>Statement / Report Name</p>
                                  </div>
                                  <div className="col-md-12">
                                    <input type="text" id="SRname" value={this.state.form.name} readOnly placeholder="Historical Position Report - Final - UserID" style={{ width: 400, paddingLeft: 15 }} />
                                  </div>
                                </div>
                                {this.props.fromHistoricalStatements === 'fromhistoricalStatements' ?
                                  ((this.props.historicalStatementname.REPORTCATAGORY === 'H' && this.props.historicalStatementname.REPORTFREQUENCY === 'M') ?
                                    <div className="col-6 businessDateSec">
                                      <p className="date-field">Business Date</p> <input type="month" style={{ width: 174 }} name="fromdate" id="businessDate" onChange={this.handleInputChange} />
                                    </div>
                                    :
                                    <div className="col-6 businessDateSec">
                                      <p className="col-md-12">Business Date</p>
                                      <div className="col-md-6">
                                        <p className="date-field">from:</p> <input type="date" name="fromdate" id="businessDate" onChange={this.handleInputChange} />
                                        <div style={{ color: "red" }}>{this.state.fromdateError}</div>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="date-field">to:</p> <input type="date" name="todate" id="businessDate" onChange={this.handleInputChange} />
                                      </div>
                                    </div>
                                  )
                                  :
                                  <div className="col-6 businessDateSec">
                                    <p className="col-md-12">Business Date</p>
                                    <div className="col-md-6">
                                      <p className="date-field">from:</p> <input type="date" name="fromdate" id="businessDate" onChange={this.handleInputChange} />
                                      <div style={{ color: "red" }}>{this.state.fromdateError}</div>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="date-field">to:</p> <input type="date" name="todate" id="businessDate" onChange={this.handleInputChange} />
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="col-md-5 pr-3 float-left ">
                                <div className="row">
                                  <div className="col-md-12 table-header">
                                    <h6>Available Accounts</h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="card-body table-responsive tradesmallgridalignment p-0" style={{ maxHeight: 312 }}>
                                    {!this.state.isaddbuttonClicked ?
                                      (
                                        <BootstrapTable data={this.state.accountGroupsList} selectRow={selectRowProp} striped hover>
                                          <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                          <TableHeaderColumn width={"80px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                          <TableHeaderColumn width={"90px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                        </BootstrapTable>
                                      )
                                      :
                                      (
                                        <BootstrapTable data={this.state.accountGroupsList} selectRow={selectRowProp} striped hover>
                                          <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                          <TableHeaderColumn width={"80px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                          <TableHeaderColumn width={"90px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                        </BootstrapTable>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 pl-2 pr-2 float-left add-group-form">
                                <div className="row">
                                  <div className="card bg-black col-md-12 block-right add-user-form" style={{ maxHeight: 350 }}>
                                    <div className="col-md-12 table-header" style={{ height: 36, borderBottom: 'solid 1px rgba(255,255,255,0.25)' }}>
                                      <h6 style={{ textAlign: 'center' }}>Add or Remove</h6>
                                    </div>
                                    <div className="card-body">
                                      <form>
                                        <div className="row">
                                          <div className="col-md-12 forward-buttons">
                                            <span
                                              className="arrow-btn"
                                              onClick={this.addButtonClicked}
                                            >
                                              <i className="fas fa-angle-right"></i>
                                            </span>
                                          </div>
                                          <div className="col-md-12 forward-buttons">
                                            <span
                                              className="arrow-btn"
                                              onClick={this.removebuttonclicked}
                                            >
                                              <i className="fas fa-angle-left"></i>
                                            </span>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 pl-3 float-left add-user-form pr-0">
                                <div className="row">
                                  <div className="col-md-12 table-header">
                                    <h6>Selected Accounts</h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="card-body table-responsive p-0 tradesmallgridalignment" style={{ maxHeight: 312 }}>
                                    {this.state.isaddbuttonClicked ?
                                      this.state.isremovebuttonclicked ?
                                        <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropfromleft} striped hover>
                                          <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                          <TableHeaderColumn width={"80px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                          <TableHeaderColumn width={"90px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                        </BootstrapTable>
                                        :
                                        <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropfromleft} striped hover>
                                          <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                          <TableHeaderColumn width={"80px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                          <TableHeaderColumn width={"90px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                        </BootstrapTable>
                                      :
                                      <BootstrapTable data={this.state.emptyarray} striped hover>
                                        <TableHeaderColumn hidden isKey dataField='id' >ID</TableHeaderColumn>
                                        <TableHeaderColumn width={"70px"} dataField='firm' filter={{ type: 'TextFilter', placeholder: 'Firm' }}>Firm</TableHeaderColumn>
                                        <TableHeaderColumn width={"80px"} dataField='office' filter={{ type: 'TextFilter', placeholder: 'Office' }}>Office</TableHeaderColumn>
                                        <TableHeaderColumn width={"90px"} dataField='account' filter={{ type: 'TextFilter', placeholder: 'Account no' }}>Account no</TableHeaderColumn>
                                        <TableHeaderColumn width={"200px"} dataField='shortname' filter={{ type: 'TextFilter', placeholder: 'Account short name' }}>Account short name</TableHeaderColumn>
                                      </BootstrapTable>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            :
            <S3Objects />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    accountGrpList: state.csr.accountgrouplist,
    getactgrpforInternalUser: state.csr.getactgrpforInternalUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    GetReportsDetails: (formData) => {
      return dispatch(GetReportsDetails(formData))
    },
    accountGroupListforExternalUser: (formData) => {
      return dispatch(accountGroupListforExternalUser(formData))
    },
    accountGroupListforInternalUser: () => {
      return dispatch(accountGroupListforInternalUser())
    },
    AddToS3: (formData) => {
      return dispatch(AddToS3(formData));
   },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerAcc)