import React from "react";
import { connect } from "react-redux";
import {
  priordaytradeaccountList,
  priordayPositionaccountList,
} from "../../actions/PriorTradePositionAction.js";
import autoBind from "react-autobind";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"


const selectRowProp = {
  mode: "checkbox",
};
class PriordayTradePositions extends React.Component {
  state = {
    loading: false,
    priorDayData: [],
    priordaypositionData: [],
    filename: "Prior Day  Trades - " + Date().toLocaleString() + ".csv",
    selectedaccounts: [],
    isLoadbuttonclicked: false,
  };

  constructor(props) {
    super(props)
    autoBind(this)

    
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }

    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
    };
  }

  onOpenModal() {
    this.formReset();
    this.setState({ showAddModal: true });
  }

  async componentDidMount() {

    this.setState({ loading: true, selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    })

  }

  sizePerPageListChange(sizePerPage) {
    alert(`sizePerPage: ${sizePerPage}`);
  }

  onPageChange(page, sizePerPage) {
    alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
  }

  async getloaddata() {

    this.setState({ loading: true })

    this.setState({ selectedaccounts: this.props.loadData }, async () => {

      let array = []

      this.state.isLoadbuttonclicked = true;

      let traderesponse = await this.props.priordaytradeaccountList(this.state.selectedaccounts);

      traderesponse.data.body && traderesponse.data.body.map((data, index) => { array.push(data) })

      this.setState({ priorDayData: array })

      let array1 = []

      let positionresponse = await this.props.priordayPositionaccountList(this.state.selectedaccounts);

      positionresponse.data.body && positionresponse.data.body.map((data, index) => { array1.push(data) })

      this.setState({ priordaypositionData: array1 })


      this.setState({ loading: false })

    });

  }

  async getloadfulldata() {

    await this.props.getstatus()

    this.setState({ loading: true, selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    });
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <>
        <span
          className="tablehead-buttonlinks col-12 float-left"
          style={{ alignItems: "right" }}
        >
          <div className="actionbuttons">
            <button
              className="btn btn-dark btn-load"
              onClick={this.getloaddata}
            >
              Load
            </button>
            <button
              className="btn btn-dark btn-refresh"
              onClick={this.getloadfulldata}
            >
              Refresh
            </button>
            <button className="btn btn-dark" onClick={onClick}>
              Export
            </button>
          </div>
        </span>
      </>
    );
  };

  IntradayTradesClick() {
    this.setState({
      filename: "Prior Day Trades - " + Date().toLocaleString() + ".csv",
    });
  }

  async IntradayPositionsClick() {
    this.setState({
      filename: "Prior Day Positions - " + Date().toLocaleString() + ".csv",
    });
  }

  render() {
    const selectRowProp = {
      mode: "checkbox",
      bgColor: "rgba(255,255,255,0.3)",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect === true) {
          this.state.selectedaccounts.push(row.account);
        } else {
          var toRemove = row.account;
          var index = this.state.selectedaccounts.indexOf(toRemove);
          if (index > -1) {
            this.state.selectedaccounts.splice(index, 1);
          }
        }
      },

      onSelectAll: (isSelect, rows, e) => {
        if (isSelect === true) {
          for (let i = 0; i < rows.length; i++) {
            this.state.selectedaccounts.push(rows[i].account);
          }
        } else {
          this.setState({ selectedaccounts: [] });
        }
      },
    };

    const selectRowPropsymbol = {
      mode: "checkbox",
      bgColor: "rgba(255,255,255,0.3)",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect === true) {
          this.state.selectedaccounts.push(row.actgroupid);
        } else {
          var toRemove = row.actgroupid;
          var index = this.state.selectedaccounts.indexOf(toRemove);
          if (index > -1) {
            this.state.selectedaccounts.splice(index, 1);
          }
        }
      },

      onSelectAll: (isSelect, rows, e) => {
        if (isSelect === true) {
          for (let i = 0; i < rows.length; i++) {
            this.state.selectedaccounts.push(rows[i].actgroupid);
          }
        } else {
          this.setState({ selectedaccounts: [] });
        }
      },
    };

    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
      expandBy: "column",
      sizePerPage: 15,
      sizePerPageList: [{
        text: '15', value: 15
      }]
    };

    function colorstyle(fieldValue, row, rowIdx) {
      return { color: fieldValue < 0 ? "red" : "white" };
    }

    return (
      this.state.loading ? (<div className="d-inline-block dashboard"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
        <div className="col-12 dashboard-main-area">
          <div className="card bg-black">
            <div className="card-body p-0">
              <ul
                className="nav nav-tabs float-left"
                id="custom-content-below-tab"
                role="tablist"
                style={{ width: "100%" }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-content-below-trade-tab"
                    data-toggle="pill"
                    href="#custom-content-below-trade"
                    role="tab"
                    aria-controls="custom-content-below-trade"
                    aria-selected="true"
                    onClick={this.IntradayTradesClick}
                  >
                    Trades
                </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-content-below-position-tab"
                    data-toggle="pill"
                    href="#custom-content-below-position"
                    role="tab"
                    aria-controls="custom-content-below-position"
                    aria-selected="false"
                    onClick={this.IntradayPositionsClick}
                  >
                    Positions
                </a>
                </li>
              </ul>
              <div className="tab-content" id="custom-content-below-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-content-below-trade"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-trade-tab"
                >
                  {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                    <div
                      className="card-body priorgridalignment table-responsive p-0"
                      style={{ MaxHeight: 400 }}
                    >
                      {!this.state.isLoadbuttonclicked ? (
                        <BootstrapTable
                          data={this.state.priorDayData}
                          options={options}
                          exportCSV
                          csvFileName={this.state.filename}
                          pagination
                        >
                          <TableHeaderColumn isKey dataField="id" hidden>
                            ID
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            className="priorgriddatebind"
                            dataField="gmicobdate"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Business Date",
                            }}
                          >
                            Business Date
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"45px"}
                            Style={{ "minWidth": "45px" }}
                            className="priorgridfirmidbind"
                            dataField="firmid"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Firm",
                            }}
                          >
                            Firm
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"50px"}
                            Style={{ "minWidth": "50px" }}
                            className="priorgridofficenobind"
                            dataField="officeno"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Office",
                            }}
                          >
                            Office
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            className="priorgridaccount"
                            dataField="accountno"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Account",
                            }}
                          >
                            Account
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"158px"}
                            Style={{ "minWidth": "158px" }}
                            className="priorgridsrtname"
                            dataField="shortname"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Short name",
                            }}
                          >
                            Short name
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"72px"}
                            Style={{ "minWidth": "72px" }}
                            className="priorgridcntr"
                            dataField="contractmm"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Contract",
                            }}
                          >
                            Contract
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"40px"}
                            Style={{ "minWidth": "40px" }}
                            dataField="buysell"
                            className="priorgridbs"
                            filter={{
                              type: "TextFilter",
                              placeholder: "B/S",
                            }}
                          >
                            B/S
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"85px"}
                            Style={{ "minWidth": "85px" }}
                            tdStyle={colorstyle}
                            dataField="quantity"
                            filter={{
                              type: "TextFilter",
                              placeholder: "QTY",
                            }}
                          >
                            QTY
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"75px"}
                            Style={{ "minWidth": "75px" }}
                            dataField="exchangecode"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Exchange",
                            }}
                          >
                            Exchange
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            dataField="contractmm"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Month",
                            }}
                          >
                            Month
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            dataField="contractccyy"
                            className="priorgridyy"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Year",
                            }}
                          >
                            Year
                      </TableHeaderColumn>

                          <TableHeaderColumn
                            width={"65px"}
                            Style={{ "minWidth": "65px" }}
                            tdStyle={colorstyle}
                            dataField="subcusip"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Prompt",
                            }}
                          >
                            Prompt
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"50px"}
                            Style={{ "minWidth": "50px" }}
                            dataField="subtype"
                            filter={{
                              type: "TextFilter",
                              placeholder: "P/C",
                            }}
                          >
                            P/C
                      </TableHeaderColumn>

                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            tdStyle={colorstyle}
                            dataField="strikeprice"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Strike Price",
                            }}
                          >
                            Strike Price
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            tdStyle={colorstyle}
                            dataField="tradeprice"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Trade Price",
                            }}
                          >
                            Trade Price
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            dataField="contracttypecode"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Type",
                            }}
                          >
                            Type
                      </TableHeaderColumn>
                        </BootstrapTable>
                      ) : (
                        <BootstrapTable
                          data={this.state.priorDayData}
                          options={options}
                          exportCSV
                          csvFileName={this.state.filename}
                          pagination
                        >
                          <TableHeaderColumn isKey dataField="id" hidden>
                            ID
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            className="priorgriddatebind"
                            dataField="gmicobdate"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Business Date",
                            }}
                          >
                            Business Date
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"45px"}
                            Style={{ "minWidth": "45px" }}
                            className="priorgridfirmidbind"
                            dataField="firmid"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Firm",
                            }}
                          >
                            Firm
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"50px"}
                            Style={{ "minWidth": "50px" }}
                            className="priorgridofficenobind"
                            dataField="officeno"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Office",
                            }}
                          >
                            Office
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            className="priorgridaccount"
                            dataField="accountno"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Account",
                            }}
                          >
                            Account
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"158px"}
                            Style={{ "minWidth": "158px" }}
                            className="priorgridsrtname"
                            dataField="shortname"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Short name",
                            }}
                          >
                            Short name
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"72px"}
                            Style={{ "minWidth": "72px" }}
                            className="priorgridcntr"
                            dataField="contractmm"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Contract",
                            }}
                          >
                            Contract
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"40px"}
                            Style={{ "minWidth": "40px" }}
                            dataField="buysell"
                            className="priorgridbs"
                            filter={{
                              type: "TextFilter",
                              placeholder: "B/S",
                            }}
                          >
                            B/S
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"85px"}
                            Style={{ "minWidth": "85px" }}
                            tdStyle={colorstyle}
                            dataField="quantity"
                            filter={{
                              type: "TextFilter",
                              placeholder: "QTY",
                            }}
                          >
                            QTY
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"75px"}
                            Style={{ "minWidth": "75px" }}
                            dataField="exchangecode"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Exchange",
                            }}
                          >
                            Exchange
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            dataField="contractmm"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Month",
                            }}
                          >
                            Month
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"60px"}
                            Style={{ "minWidth": "60px" }}
                            dataField="contractccyy"
                            className="priorgridyy"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Year",
                            }}
                          >
                            Year
                      </TableHeaderColumn>

                          <TableHeaderColumn
                            width={"65px"}
                            Style={{ "minWidth": "65px" }}
                            tdStyle={colorstyle}
                            dataField="subcusip"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Prompt",
                            }}
                          >
                            Prompt
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"50px"}
                            Style={{ "minWidth": "50px" }}
                            dataField="subtype"
                            filter={{
                              type: "TextFilter",
                              placeholder: "P/C",
                            }}
                          >
                            P/C
                      </TableHeaderColumn>

                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            tdStyle={colorstyle}
                            dataField="strikeprice"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Strike Price",
                            }}
                          >
                            Strike Price
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            tdStyle={colorstyle}
                            dataField="tradeprice"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Trade Price",
                            }}
                          >
                            Trade Price
                      </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            Style={{ "minWidth": "90px" }}
                            dataField="contracttypecode"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Type",
                            }}
                          >
                            Type
                      </TableHeaderColumn>
                        </BootstrapTable>
                      )}
                    </div>
                  }
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-content-below-position"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-position-tab"
                >
                  <div
                    className="card-body priorgridalignment table-responsive p-0"
                    style={{ MaxHeight: 400 }}
                  >
                    {!this.state.isLoadbuttonclicked ? (
                      <BootstrapTable
                        data={this.state.priordaypositionData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          className="priorgriddatebind"
                          dataField="gmicobdate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                        >
                          Business Date
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          className="priorgridfirmidbind"
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                        >
                          Firm
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          className="priorgridofficenobind"
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          className="priorgridaccount"
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          className="priorgridsrtname"
                          dataField="shortname"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          className="priorgridcntr"
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          className="priorgridbs"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          tdStyle={colorstyle}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                        >
                          QTY
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          className="priorgridyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                      </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          tdStyle={colorstyle}
                          dataField="subcusip"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                      </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          tdStyle={colorstyle}
                          dataField="strikeprice"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          tdStyle={colorstyle}
                          dataField="tradeprice"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="contracttypecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Type",
                          }}
                        >
                          Type
                      </TableHeaderColumn>
                      </BootstrapTable>
                    ) : (
                      <BootstrapTable
                        data={this.state.priordaypositionData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          className="priorgriddatebind"
                          dataField="gmicobdate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                        >
                          Business Date
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          className="priorgridfirmidbind"
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                        >
                          Firm
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          className="priorgridofficenobind"
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          className="priorgridaccount"
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"158px"}
                          Style={{ "minWidth": "158px" }}
                          className="priorgridsrtname"
                          dataField="shortname"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          className="priorgridcntr"
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          className="priorgridbs"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          tdStyle={colorstyle}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                        >
                          QTY
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          className="priorgridyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                      </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          tdStyle={colorstyle}
                          dataField="subcusip"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                      </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          tdStyle={colorstyle}
                          dataField="strikeprice"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          tdStyle={colorstyle}
                          dataField="tradeprice"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                      </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="contracttypecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Type",
                          }}
                        >
                          Type
                      </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedintradaytrade: state.csr.priordaytradeaccountList,
    selectedintradayposition: state.csr.priordayPositionaccountList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priordaytradeaccountList: (formData) => {
      return dispatch(priordaytradeaccountList(formData));
    },
    priordayPositionaccountList: (formData) => {
      return dispatch(priordayPositionaccountList(formData));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriordayTradePositions);
