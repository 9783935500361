import axios from 'axios'
import config from './config'

export const assetList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'asset-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const assetAdd = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-asset', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const assetEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-asset', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const assetById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-asset/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const assetDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-asset', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}