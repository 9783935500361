import axios from 'axios'
import config from './config'

export const statementList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'statements-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'STATEMENTS_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}



export const statementlistById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'getStatementsValueByID/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_STATEMENT_BY_ID_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}