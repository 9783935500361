import React from "react"
import { connect } from "react-redux"
import autoBind from "react-autobind"
import _ from "lodash"
import { companiesList } from '../../../../actions/csrCompaniesAction.js'
import { accountGroupList, getActGrpByCompany } from '../../../../actions/csrAccountGroupAction.js'
import { userEdit, userAccById, userTypeList } from '../../../../actions/csrUserAction.js'
import { regionList } from "../../../../actions/csrRegionAction.js"
import Select from 'react-select';
import jwtDecode from "jwt-decode";

class EditUser extends React.Component {
  state = {
    selectdaccountgrplist: [],
    selectedValue: [],
    form: {
      id: null,
      userfirstname: null,
      userlastname: null,
      useremailid: null,
      password: null,
      userphonenumer: null,
      companyname: null,
      usercountry: null,
      userregion: null,
      items: [],
      selectditems: [],
      note: null,
      active: 'NO',
      userid: null,
      usertp: null
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  handlechangedropdown(e) {
    let form = {}
    form['selectditems'] = e
    this.setState(_.extend(this.state.form, form))
  }

  async handleInputChange(e) {

    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))

    if (e.target.name === 'companyname') {
      let response = await this.props.getActGrpByCompany(e.target.value)
      this.state.selectdaccountgrplist = []

      response.data.body && response.data.body.map((accountgrp, index) => {
        this.state.selectdaccountgrplist.push({ label: accountgrp.actgroupid, value: accountgrp.actgroupid })
      })
      this.state.selectedValue = []
    }
  }

  async componentDidMount() {
    this.props.userTypeList()
    this.props.regionList()
    let response = await this.props.getActGrpByCompany(this.props.formDetails.companyname)
    if (response.status === 200) {
      response.data.body && response.data.body.map((accountgrp, index) => {
        this.state.selectdaccountgrplist.push({ label: accountgrp.actgroupid, value: accountgrp.actgroupid })
        this.setState({ selectedValue: this.state.selectdaccountgrplist })
      })
    }
    let form = {}
    form["userfirstname"] = this.props.formDetails.userfirstname
    form["userlastname"] = this.props.formDetails.userlastname
    form["useremailid"] = this.props.formDetails.useremailid
    form["password"] = this.props.formDetails.password
    form["userphonenumer"] = this.props.formDetails.userphonenumer
    form["usercountry"] = this.props.formDetails.usercountry
    form["userregion"] = this.props.formDetails.userregion
    form["accountgroup"] = this.props.formDetails.accountgroup
    form["note"] = this.props.formDetails.note
    form["active"] = this.props.formDetails.active
    form["id"] = this.props.formDetails.id
    form["userid"] = this.props.formDetails.userid
    form["companyname"] = this.props.formDetails.companyname
    form["usertp"] = this.props.formDetails.usertp
    this.setState(_.extend(this.state.form, form))
  }

  async onupdateButttonClick() {
    this.state.form.items = []
    this.state.form.selectditems && this.state.form.selectditems.map((data, index) => {
      this.state.form.items.push(data.value)
    })
    if (
      this.state.form.userfirstname === null ||
      this.state.form.userlastname === null ||
      this.state.form.useremailid === null ||
      this.state.form.companyname === null ||
      this.state.form.Telephone === null ||
      this.state.form.usercountry === null ||
      this.state.form.userregion === null ||
      this.state.form.accountgroup === null
    ) {
      return false
     }  else if (this.state.form.password !== undefined  && this.state.form.password.length < 8) {
       this.setState({ passworderror: "Password must be a minimum of 8 characters in length." })
       return false
    }
    else {
      let response = await this.props.userEdit(this.state.form)
      if (response.status === 200) {
        this.setState({ successMessage: "Updated Successfully" })
        this.props.updatelist(true)
      }
    }
  }

  onCancelButttonClick() {
    this.props.showcompstatus(true)
  }

  cancelAlert() {
    this.setState({ successAlert: false })

  }
  render() {

    let CompanyList
    if (this.props.Companies) {
      CompanyList = this.props.Companies.map((company, index) =>
        <option key={company.id} > {company.companyname}
        </option>
      )
    }

    let CompaniesList
    if (this.props.Companies) {
      CompaniesList = this.props.Companies.map((company, index) =>
        <option key={company.id} > {company.companyname}
        </option>
      )
    }

    let userregionlist
    if (this.props.regions) {
      userregionlist = this.props.regions.map((region, index) => (
        <option key={region.id}> {region.region}</option>
      ))
    }

    let usertype
    if (this.props.usertype) {
      usertype = this.props.usertype.map((type, index) => (
        <option key={type.id}> {type.usertype}</option>
      ))
    }

    return (
      <div className="col-md-5 pl-3 float-left">
        <div className="row">
          <div
            className="card bg-black col-md-12 block-right add-user-form"
            style={{ maxHeight: 440 }}
          >
            <div className="card-header">
              <h4>Edit User</h4>
            </div>
            <div className="card-body">
              <form>
                <input
                  type="hidden"
                  id="Id"
                  onChange={this.handleInputChange}
                  name="Id"
                  value={this.state.form.id}
                />
                <div className="row">
                  <div className="col-md-3 form-title">First Name:</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="text"
                      name="userfirstname"
                      placeholder="Enter first name"
                      onChange={this.handleInputChange}
                      value={this.state.form.userfirstname}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Last Name:</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="text"
                      name="userlastname"
                      placeholder="Enter last name"
                      onChange={this.handleInputChange}
                      value={this.state.form.userlastname}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">
                    User Type
                  </div>
                  <div className="col-md-9 form-value">
                    <select
                      name="usertp"
                      onChange={this.handleInputChange}
                      value={this.state.form.usertp}
                    >
                      <option value="0">Select</option>
                      {usertype}
                    </select>
                    <div style={{ color: "red" }}>{this.state.usertypeerror}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Email:</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="Email"
                      name="useremailid"
                      placeholder="Enter e-mail"
                      onChange={this.handleInputChange}
                      value={this.state.form.useremailid}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Password</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={this.handleInputChange}
                      value={this.state.form.password}
                     />
                     <div style={{ color: "red" }}>{this.state.passworderror}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Telephone:</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="text"
                      name="userphonenumer"
                      placeholder="Enter phone number"
                      onChange={this.handleInputChange}
                      value={this.state.form.userphonenumer}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">User Country:</div>
                  <div className="col-md-9 form-value">
                    <input
                      type="text"
                      name="usercountry"
                      placeholder="Enter your country"
                      onChange={this.handleInputChange}
                      value={this.state.form.usercountry}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">User Region:</div>
                  <div className="col-md-9 form-value">
                    <select
                      name="userregion"
                      onChange={this.handleInputChange}
                      value={this.state.form.userregion}
                    >
                      <option value="0">Select</option>
                      {userregionlist}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">
                    Company:
                  </div>
                  <div className="col-md-9 form-value">
                    <select
                      name="companyname"
                      onChange={this.handleInputChange}
                      value={this.state.form.companyname}
                    >
                      <option value="0">Select</option>
                      {CompaniesList}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Account Group:</div>
                  <div className="AccountGroup_Dropdown">
                    <Select
                      isMulti
                      isSearchable
                      onChange={this.handlechangedropdown}
                      options={this.state.selectdaccountgrplist}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-title">Notes:</div>
                  <div className="col-md-9 form-value">
                    <textarea
                      name="note"
                      placeholder="Notes"
                      style={{ marginBottom: 15 }}
                      onChange={this.handleInputChange}
                      value={this.state.form.note}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-12 action-group">
            <button className="btn btn-dark" onClick={this.onCancelButttonClick}>Cancel</button>
            <button className="btn btn-default" onClick={this.onupdateButttonClick}>Update</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountgroups: state.csr.accountgrouplist,
    Companies: state.csr.companiesList,
    regions: state.csr.regionList,
    usertype: state.csr.usertypelist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    userEdit: (formData) => {
      return dispatch(userEdit(formData))
    },
    userAccById: (id) => {
      return dispatch(userAccById(id))
    },
    regionList: () => {
      return dispatch(regionList())
    },
    getActGrpByCompany: (company) => {
      return dispatch(getActGrpByCompany(company))
    },
    userTypeList: () => {
      return dispatch(userTypeList())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)
