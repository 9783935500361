import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { accountGroupList, getActGrpByCompany } from "../../../../actions/csrAccountGroupAction.js"
import { userAdd, userTypeList } from "../../../../actions/csrUserAction.js"
import { companiesList } from "../../../../actions/csrCompaniesAction.js"
import autoBind from "react-autobind"
import { regionList } from "../../../../actions/csrRegionAction.js"
import Select from 'react-select';
import jwtDecode from "jwt-decode";



class addUser extends React.Component {

  state = {
    selectedOption: null,
    accountGrpforCompany: [],
    AccountGroupListArray: [],
    value: "0",
    firstnameerror: null,
    lastnameerror: null,
    usertypeerror: null,
    emailerror: null,
    passworderror: null,
    Telephoneerror: null,
    UserCountryerror: null,
    companyerror: null,
    userregionerror: null,
    accountgrouperror: null,
    noteserror: null,
    form: {
      userfirstname: null,
      userlastname: null,
      useremailid: null,
      password: null,
      userphonenumer: null,
      usercountry: null,
      userregion: null,
      companyname: null,
      accountgroup: 1,
      note: ' ',
      userscreen: "",
      selectedItem: [],
      items: [],
      usertp: null,
      active: "NO"
    },
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  async handleInputChange(e) {
    if (e.target.name === 'companyname') {
      let response = await this.props.getActGrpByCompany(e.target.value)
      if (response.status && response.status === 200) {
        this.state.accountGrpforCompany = []
        response.data.body && response.data.body.map((actgrp, index) => {
          this.state.accountGrpforCompany.push({ label: actgrp.actgroupid, value: actgrp.actgroupid })
        })
      }
    }

    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
  }

  async componentDidMount() {
    await this.props.companiesList()
    await this.props.accountGroupList()
    await this.props.userTypeList()
    await this.props.regionList()

    this.setState({ AccountGroupListArray: this.props.accountgroups1 })
  }

  handlechangedropdown(e) {
    let form = {}
    form['selectedItem'] = e
    this.setState(_.extend(this.state.form, form))
  }

  validation() {
    let isDuplicateData = false
    if (!this.state.form.userfirstname) {
       this.setState({ firstnameerror: "userfirstname Cannot be blank" })
      return false
    } else {
      this.setState({ firstnameerror: "" })
    }
    if (!this.state.form.userlastname) {
      this.setState({ lastnameerror: "userlastname Cannot be blank" })
      return false
    } else {
      this.setState({ lastnameerror: "" })
    }
    if (!this.state.form.usertp) {
      this.setState({ usertypeerror: "usertype Cannot be blank" })
      return false
    } else {
      this.setState({ usertypeerror: "" })
    }
    if (!this.state.form.useremailid) {
      this.setState({ emailerror: "emailid Cannot be blank" })
      return false
    } else {
      this.props.UserAvailability && this.props.UserAvailability.map((data, index) => {
        if (data === this.state.useremailid) {
          isDuplicateData = true
        }
      })
      if (isDuplicateData) {
        this.setState({ emailerror: "User Mail ID Already Exist" })
        return false
      }
      else {
        this.setState({ emailerror: "" })
      }
    }
     if (!this.state.form.password) {
       this.setState({ passworderror: "password Cannot be blank." })
       return false
     } else if (this.state.form.password.length < 8) {
       this.setState({ passworderror: "Password must be a minimum of 8 characters in length." })
       return false
     }
    else {
      this.setState({ passworderror: "" })
    }
    if (!this.state.form.userphonenumer) {
       this.setState({ Telephoneerror: "phonenumber Cannot be blank" })
      return false
    } else {
      this.setState({ Telephoneerror: "" })
    }
    if (!this.state.form.usercountry) {
      this.setState({ UserCountryerror: "usercountry Cannot be blank" })
      return false
    } else {
      this.setState({ UserCountryerror: "" })
    }
    if (!this.state.form.companyname) {
      this.setState({ companyerror: "CompanyName Cannot be blank" })
      return false
    } else {
      this.setState({ companyerror: "" })
    }
    if (!this.state.form.userregion) {
      this.setState({ userregionerror: "UserRegion Cannot be blank" })
      return false
    } else {
      this.setState({ userregionerror: "" })
    }
    if (!this.state.form.note) {
      this.setState({ noteserror: "Note Cannot be blank" })
    } else {
      this.setState({ noteserror: "" })
    }
    return true
  }

  async onSaveButttonClick() {
    this.state.form.items = []
    this.state.form.selectedItem && this.state.form.selectedItem.map((data, index) => {
      this.state.form.items.push(data.value)
    })
    const isValid = this.validation()
    if (isValid) {
      let response = await this.props.userAdd(this.state.form)
      if (response && response.status === 200) {
        this.setState({ successMessage: "Added Successfully" })
        this.props.updatelist(true)
      }
    }
  }

  onSelect(selectedList, selectedItem) {
  }

  onRemove(selectedList, removedItem) {
  }

  onCancelButttonClick() {
    this.props.showcompstatus(true)
  }

  cancelAlert() {
    this.setState({ successAlert: false })
  }

  render() {

    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: "#000",
      })
    }

    let CompanyList
    if (this.props.Companies) {
      CompanyList = this.props.Companies.map((company, index) => (
        <option key={company.id}> {company.companyname}</option>
      ))
    }

    let userregionlist
    if (this.props.regions) {
      userregionlist = this.props.regions.map((region, index) => (
        <option key={region.id}> {region.region}</option>
      ))
    }

    let usertype
    if (this.props.usertype) {
      usertype = this.props.usertype.map((type, index) => (
        <option key={type.id}> {type.usertype}</option>
      ))
    }

    return (

      <div className="hold-transition sidebar-mini layout-navbar-fixed add-user-spc">
        <div className="wrapper">
          <div className="col-md-5 pl-3 float-left">
            <div className="row">
              <div
                className="card bg-black col-md-12 block-right add-user-form"
                style={{ maxHeight: 440 }}
              >
                <div className="card-header">
                  <h4>Add User</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-3 form-title">First Name:</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="text"
                          name="userfirstname"
                          placeholder="Enter first name"
                          onChange={this.handleInputChange}
                          value={this.state.form.userfirstname}
                        />
                        <div style={{ color: "red" }}>{this.state.firstnameerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Last Name:</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="text"
                          name="userlastname"
                          placeholder="Enter last name"
                          onChange={this.handleInputChange}
                          value={this.state.form.userlastname}
                        />
                        <div style={{ color: "red" }}>{this.state.lastnameerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">
                        User Type
                      </div>
                      <div className="col-md-9 form-value">
                        <select
                          name="usertp"
                          onChange={this.handleInputChange}
                        >
                          <option value="0">Select</option>
                          {usertype}
                        </select>
                        <div style={{ color: "red" }}>{this.state.usertypeerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Email:</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="email"
                          name="useremailid"
                          placeholder="Enter e-mail"
                          onChange={this.handleInputChange}
                          value={this.state.form.useremailid}
                        />
                        <div style={{ color: "red" }}>{this.state.emailerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Password</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          onChange={this.handleInputChange}
                          value={this.state.form.password}
                        />
                        <div style={{ color: "red" }}>{this.state.passworderror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Telephone:</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="text"
                          name="userphonenumer"
                          placeholder="Enter phone number"
                          onChange={this.handleInputChange}
                          value={this.state.form.userphonenumer}
                        />
                        <div style={{ color: "red" }}>{this.state.Telephoneerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">User Country:</div>
                      <div className="col-md-9 form-value">
                        <input
                          type="text"
                          name="usercountry"
                          placeholder="Enter your country"
                          onChange={this.handleInputChange}
                          value={this.state.form.usercountry}
                        />
                        <div style={{ color: "red" }}>{this.state.UserCountryerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">
                        Company:
                      </div>
                      <div className="col-md-9 form-value">
                        <select
                          name="companyname"
                          onChange={this.handleInputChange}
                        >
                          <option value="0">Select</option>
                          {CompanyList}
                        </select>
                        <div style={{ color: "red" }}>{this.state.companyerror}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">User Region:</div>
                      <div className="col-md-9 form-value">
                        <select
                          name="userregion"
                          onChange={this.handleInputChange}
                          value={this.state.form.userregion}
                        >
                          <option value="0">Select</option>
                          {userregionlist}
                        </select>
                        <div style={{ color: "red" }}>{this.state.userregionerror}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 form-title">Account Group:</div>
                      <div className="AccountGroup_Dropdown">
                        <Select
                          styles={customStyles}
                          isMulti
                          isSearchable
                          onChange={this.handlechangedropdown}
                          options={this.state.accountGrpforCompany}
                        />
                        <div style={{ color: "red" }}>{this.state.accountgrouperror}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 form-title">Notes:</div>
                      <div className="col-md-9 form-value">
                        <textarea
                          name="note"
                          placeholder="Notes"
                          style={{ marginBottom: 15 }}
                          onChange={this.handleInputChange}
                          value={this.state.form.note}
                        ></textarea>
                        <div style={{ color: "red" }}>{this.state.noteserror}</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 action-group">
                <button className="btn btn-dark" onClick={this.onCancelButttonClick}>Cancel</button>
                <button
                  className="btn btn-default"
                  onClick={this.onSaveButttonClick}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountgroups: state.csr.accountgrouplist,
    Companies: state.csr.companiesList,
    regions: state.csr.regionList,
    usertype: state.csr.usertypelist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    userAdd: (formData) => {
      return dispatch(userAdd(formData))
    },
    regionList: () => {
      return dispatch(regionList())
    },
    userTypeList: () => {
      return dispatch(userTypeList())
    },
    getActGrpByCompany: (company) => {
      return dispatch(getActGrpByCompany(company))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(addUser)
