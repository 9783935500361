import { createReducer } from '../store.js';

export default createReducer({
	auth: false
}, {
	'USER_LOGIN': (state, action) => {
		return Object.assign({}, state, {
			auth: action.value
		});
	}
}
);