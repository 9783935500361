import React from "react"
import { userList, userDelete, userById, CreatedUserMailId, UpdatedUserMailId, CertifiedUserMailId, ActGrpListforUser } from "../../../../actions/csrUserAction.js"
import autoBind from "react-autobind"
import { connect } from "react-redux"
import UserDetails from "./userDetails.jsx"
import EditUser from "./editUser"
import AddUser from "./addUser"
import { accountGroupList } from "../../../../actions/csrAccountGroupAction.js"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from 'react-router-dom'
import _ from "lodash"
import WarningPopup from '../../../AlertPopup/alertpopupDesign'
import AlertPopup from '../../../AlertPopup/popupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";



class users extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
    this.state = {
      loading: false,
      UserAvailability: [],
      noofcheckboxselected: 0,
      editclickedwhilenocheked: false,
      editclickedwhilemorethenone: false,
      ListofUser: [],
      isCheckBoxClicked: false,
      showcomponent: false,
      showeditUsers: false,
      showuserDetails: false,
      warningAlert: false,
      warningMessage: null,
      successAlert: false,
      successMessage: null,
      rowIds: [],
      actGrpID: [],
      form: {
        Created_User_Name: null,
        Updated_User_Name: null,
        Certified_User_Name: null,
        Created_User_Mail_ID: null,
        id: null,
        userfirstname: null,
        userlastname: null,
        useremailid: null,
        password: null,
        userphonenumer: null,
        accountgroup: 1,
        usercountry: null,
        userregion: null,
        note: null,
        active: null,
        onboardedBy_firstName: null,
        onboardedBy_secondName: null,
        onboardeddate: null,
        certifiedby_firstName: null,
        certifiedby_secondName: null,
        certifieddate: null,
        updatedBy_firstName: null,
        updatedBy_secondName: null,
        updatedate: null,
        usertp: null
      }
    }
  }

  getstatus(_status) {
    this.setState({ showcomponent: false })
    this.setState({ showeditUsers: false })
  }

  async componentDidMount() {
    let response = await this.props.userList()
    response.data.body && response.data.body.map((data, index) => {
      this.state.UserAvailability.push(data.useremailid)
    })
    await this.props.accountGroupList()
  }

  removeData() {
    this.state.rowIds.length
      ? this.setState({ warningAlert: true })
      : this.setState({ warningAlert: false })
  }

  async updateuserList(getstatus) {
    this.setState({ successMessage: 'User Details Added Successfully', successAlert: true })
    this.setState({ showcomponent: false })
    this.setState({ showeditUsers: false, isCheckBoxClicked: false })
    let response = await this.props.userList()
    this.state.UserAvailability = []
    response.data.body && response.data.body.map((data, index) => {
      this.state.UserAvailability.push(data.useremailid)
    })
  }

  async updateuserListEdit(getstatus) {
    this.setState({ successMessage: 'User Details Updated Successfully', successAlert: true })
    this.setState({ showcomponent: false })
    this.setState({ showeditUsers: false })
    let response = await this.props.userList()
    this.state.UserAvailability = []
    response.data.body && response.data.body.map((data, index) => {
      this.state.UserAvailability.push(data.useremailid)
    })
  }

  addButtonClick() {
    this.setState({ showcomponent: true })
    this.setState({ showeditUsers: false })
    this.setState({ showuserDetails: false })
    this.props.accountgroups.map((company, index) => (
      this.state.actGrpID.push(company.actgroupid)
    ))
  }

  editButtonClick() {
    this.setState({ showcomponent: false })
    this.setState({ showuserDetails: false })
    this.props.accountgroups.map((company, index) => (
      this.state.actGrpID.push(company.actgroupid)
    ))
    if (this.state.noofcheckboxselected === 0) {
      this.setState({ editclickedwhilenocheked: true })
    }
    else {
      this.setState({ showeditUsers: true })
    }
  }

  async deleteRecord() {
    this.setState({ warningAlert: false })
    let response = await this.props.userDelete(this.state.rowIds)
    if (response.status === 200) {
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false)
      let response = await this.props.userList()
      this.state.UserAvailability = []
      response.data.body && response.data.body.map((data, index) => {
        this.state.UserAvailability.push(data.useremailid)
      })
      this.setState({ successMessage: "User Details Deleted Successfully", successAlert: true, noofcheckboxselected: 0 })
      this.state.rowIds = []
    }
  }

  cancelAlert() {
    this.setState({ warningAlert: false })
    this.setState({ successAlert: false })
    this.setState({ editclickedwhilenocheked: false })
    this.setState({ editclickedwhilemorethenone: false })
  }

  onGetWarningStatus() {
    this.setState({ warningAlert: false, isCheckBoxClicked: false })
    this.deleteRecord()
  }


  cancelclicked() {
    this.setState({ warningAlert: false })
    this.state.rowIds = []
  }

  ongetstatus() {
    this.setState({ successAlert: false, editclickedwhilenocheked: false })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        this.setState({ isCheckBoxClicked: false, loading: true })
        if (isSelect === true) {

          this.state.form.Created_User_Mail_ID = []
          this.state.form.Created_User_Name = []
          this.state.form.Updated_User_Name = []
          this.state.form.Certified_User_Name = []
          this.state.form.onboardeddate = []
          this.state.form.certifieddate = []
          this.state.form.updatedate = []

          this.setState({ noofcheckboxselected: this.state.noofcheckboxselected + 1 })
          this.state.rowIds = []
          this.state.rowIds.push(row.id)
          let response = await this.props.userById(row.id)




          if (response.status === 200) {
            this.state.form.userfirstname = response.data.body.userfirstname
            this.state.form.userlastname = response.data.body.userlastname
            this.state.form.useremailid = response.data.body.useremailid
            this.state.form.password = response.data.body.password
            this.state.form.userphonenumer = response.data.body.userphonenumer
            this.state.form.userregion = response.data.body.userregion
            this.state.form.note = response.data.body.note
            this.state.form.id = response.data.body.id
            this.state.form.userid = response.data.body.userid
            this.state.form.usercountry = response.data.body.usercountry
            this.state.form.onboardeddate = response.data.body.onboardeddate
            this.state.form.certifieddate = response.data.body.certifieddate
            this.state.form.updatedate = response.data.body.updatedate
            this.state.form.active = response.data.body.active
            this.state.form.companyname = response.data.body.companyname
            this.state.form.usertp = response.data.body.usertp

            let actgrplist = await this.props.ActGrpListforUser(this.state.form)
            this.state.form.accountgroup = []
            actgrplist.data.body && actgrplist.data.body.map((data, index) => {
              this.state.form.accountgroup.push(data.actgroupid + ', ')
            })


            let getCreatedUserMailID = await this.props.CreatedUserMailId(response.data.body.onboardedBy)
            let getUpdatedUserName = await this.props.UpdatedUserMailId(response.data.body.updatedBy)
            let getCertifiedUserName = await this.props.CertifiedUserMailId(response.data.body.certifiedby)

            if (getCreatedUserMailID !== undefined && getCreatedUserMailID.data.body !== null) {
              let form = []
              form['Created_User_Mail_ID'] = getCreatedUserMailID.data.body.useremailid
              form['Created_User_Name'] = getCreatedUserMailID.data.body.userfirstname + " " + getCreatedUserMailID.data.body.userlastname
              this.setState(_.extend(this.state.form, form))
            }
            if (getUpdatedUserName !== undefined && getUpdatedUserName.data.body !== null) {
              let form = []
              form['Updated_User_Name'] = getUpdatedUserName.data.body.userfirstname + " " + getUpdatedUserName.data.body.userlastname
              this.setState(_.extend(this.state.form, form))
            }
            if (getCertifiedUserName !== undefined && getCertifiedUserName.data.body !== null) {
              let form = []
              form['Certified_User_Name'] = getCertifiedUserName.data.body.userfirstname + " " + getCertifiedUserName.data.body.userlastname
              this.setState(_.extend(this.state.form, form))
            }
            this.setState({ isCheckBoxClicked: true, showuserDetails: true, showcomponent: false, showeditUsers: false, loading: false })
          }
        }
        else {
          this.setState({ noofcheckboxselected: this.state.noofcheckboxselected - 1 })
          this.state.isCheckBoxClicked = false
          let ids = this.state.rowIds.filter(function (id) {
            return id !== row.id
          })
          this.setState({ rowIds: ids })
        }
      }
    }

    return (

      this.state.warningAlert ? (<WarningPopup alertType={'warningAlert'} getStatus={this.onGetWarningStatus} Message={'Are You sure want to delete this User details'} cancelStatus={this.cancelclicked} />) :
        this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={this.state.successMessage} />) :

          <div className="hold-transition sidebar-mini layout-navbar-fixed">

            {this.state.editclickedwhilenocheked ? (<AlertPopup getStatus={this.ongetstatus} Message="Please select any User" />) : ("")}

            <div className="wrapper">
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                        <ol className="breadcrumb float-sm-left">
                          <li className="breadcrumb-item">
                            <a href="#">CSR</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Client Onboarding</a>
                          </li>
                          <li className="breadcrumb-item active">Users</li>
                        </ol>
                      </div>
                      <div className="col-sm-5">
                        <div className="card-head p-0">
                          <ul className="dash-sidebar-menu float-sm-left">
                            <li className="dash-sidebar-item">
                              <Link to="/dashboard/csr-CsrCompanies" className="nav-link">Companies</Link>
                            </li>
                            <li className="dash-sidebar-item">
                              <Link to="/dashboard/csr-AccountGroup" className="nav-link">Account Groups</Link>
                            </li>
                            <li className="dash-sidebar-item active">
                              <Link to="/dashboard/csr-Users" className="nav-link">Users</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="card bg-black csr-onboarding">
                          <div className="card-header card-onboarding">
                            <div className="card-tools col-md-12 pl-0">
                              <div className="top-button-group row">
                                <a
                                  onClick={this.addButtonClick}
                                  className="btn btn-default add-user-btn"
                                >
                                  Add
                                </a>
                                <button
                                  onClick={this.editButtonClick}
                                  className="btn btn-default"
                                >
                                  Edit
                                </button>
                                <button className="btn btn-default"
                                  onClick={this.removeData}>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 pl-0 pr-0">
                            <div className="col-md-7 pr-3 float-left">
                              <div className="row">
                                <div className="col-md-12 table-header userListHead">
                                  <h6>List of Users</h6>
                                </div>
                              </div>
                              <div className="row rowcsrUserList">
                                <div
                                  className="card-body table-responsive csrUserList p-0"
                                  style={{ MaxHeight: 360 }}
                                >
                                  <BootstrapTable data={this.props.users} selectRow={selectRowProp} striped hover>
                                    <TableHeaderColumn hidden isKey dataField='id'>ID</TableHeaderColumn>
                                    <TableHeaderColumn width={"141px"} dataField='userfirstname' filter={{ type: 'TextFilter', placeholder: 'First Name' }}>First Name</TableHeaderColumn>
                                    <TableHeaderColumn width={"150px"} dataField='userlastname' filter={{ type: 'TextFilter', placeholder: 'Last Name' }}>Last Name</TableHeaderColumn>
                                    <TableHeaderColumn width={"100px"} dataField='userid' filter={{ type: 'TextFilter', placeholder: 'User Id' }}>User Id</TableHeaderColumn>
                                    <TableHeaderColumn width={"200px"} dataField='useremailid' filter={{ type: 'TextFilter', placeholder: 'Email' }}>Email</TableHeaderColumn>
                                    <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            {this.state.showeditUsers ?
                              (
                                <EditUser formDetails={this.state.form} accountgroups1={this.state.actGrpID} updatelist={this.updateuserListEdit} showcompstatus={this.getstatus} />
                              )
                              :
                              this.state.showcomponent ? (
                                <AddUser accountgroups1={this.state.actGrpID} updatelist={this.updateuserList} showcompstatus={this.getstatus} UserAvailability={this.state.UserAvailability} />
                              )
                                :
                                (
                                  this.state.isCheckBoxClicked ?
                                    this.state.loading ? (<div className="d-inline-block clientonboarding"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                                      <UserDetails formDetails={this.state.form} />
                                    : ""
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountgroups: state.csr.accountgrouplist,
    users: state.csr.usersList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userList: () => {
      return dispatch(userList())
    },
    userDelete: (rowIds) => {
      return dispatch(userDelete(rowIds))
    },
    userById: (id) => {
      return dispatch(userById(id))
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    CreatedUserMailId: (id) => {
      return dispatch(CreatedUserMailId(id))
    },
    UpdatedUserMailId: (id) => {
      return dispatch(UpdatedUserMailId(id))
    },
    CertifiedUserMailId: (id) => {
      return dispatch(CertifiedUserMailId(id))
    },
    ActGrpListforUser: (formData) => {
      return dispatch(ActGrpListforUser(formData))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(users)
