import React from 'react'

class Sample extends React.Component {


  getAssetList() {

    return (
      this.props.content &&
      this.props.content.map((asset, index) => {
        return (
          <tr key={'item_' + index}>
            <td style={{ whiteSpace: "pre", float: "left", width: "100%" }}>{asset}</td>
          </tr>
        )
      })
    )
  }

  getAssetList1() {

    return (
      this.props.content &&
      this.props.content.map((asset, index) => {

        try {
          var newstring = asset.replace(/ /g, '\u00a0')// replace(/\u00A0/, " ")
        } catch (e) {
          console.log(e)
        }

        return (
          <p>{newstring}</p>
        )
      })
    )
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper">
          <section className="content-header pb-0">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-9">
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    <font face="Consolas">
                      <p style={{ color: "White" }}>{this.getAssetList1()}</p>
                    </font>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}


export default Sample