import axios from 'axios'
import config from './config'

export const accountGroupList = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'accountgroup-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const availableaccountList = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'getaccount-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'AVAILABLEACCOUNT_GROUP_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getAllAccList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'GetAllaccount-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ALL_ACC_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const selectedaccountGroupList = (id) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'getselectedaccount-list', { id }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'SELECTED_ACCOUNT_GROUP_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const notselectedaccountGroupList = (id) => {
    return async (dispatch) => {
        return await axios.post(config.apibaseUrl + 'getnotselectedaccount-list', { id }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'NOT_SELECTED_ACCOUNT_GROUP_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const addaccountgroup = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-accountgroup', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ADD-ACCOUNT-GROUP',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const AccGrpById = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-accountgroup/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_ACCOUNT_GROUP_BY_ID',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const AccountGroupEdit = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'updateselectedaccount-list', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

//

export const getselectedaccbygroup = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'get-Selectedaccountgroup', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_SELECTED_ACCOUNT_BY_GROUP',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

//-

export const UpdateActiveStatusforAG = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'active-accountupdate', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_ACTIVE_STATUS_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const deleteAccountGroup = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'deleteaccountGroup-list', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getActGrpByCompany = (companyname) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'getAccountGroupByCompany/' + companyname)
            .then(response => {
                dispatch({
                    type: 'GET_SELECTED_ACCOUNT_BY_COMPANY',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const ActGrpCreatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'CreatedUser/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_ACCOUNT_GROUP_CREATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const ActGrpUpdatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'AgpUpdatedUser/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_ACCOUNT_GROUP_UPDATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const ActGrpCertifiedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'AgpCertifiedUser/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_ACCOUNT_GROUP_CERTIFIED_USER',
                    value: response.data.message
                })
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const accountGroupListforInternalUser = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'getAccountGrouplistforInternalUser', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_LIST_INTERNAL_USER',
                    value: response.data.body
                })
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const accountGroupListforExternalUser = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'getAccountGrouplistforExternalUser', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_LIST_EXTERNAL_USER',
                    value: response.data.message
                })
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

