import axios from 'axios'
import config from './config'
//


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const AddScheduler = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-scheduler', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'SCHEDULER_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const schedulerList = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'list-scheduler', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'SCHEDULER_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}



export const AccountGroupListfromScheduler = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'getSchedulerDetails', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACTGRP_SCHECULER_LIST',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const DeleteScheduler = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'DeleteSchedulers', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'DELETE_SCHEDULER_LIST',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}
