import React from "react";
import { connect } from "react-redux";
import {
  priormoneyline,
  companyaccountList,
  accountgroupselection,
  priormoneyselaccountList,
} from "../../actions/PriorMoneylineAction.js";
import autoBind from "react-autobind";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"

class PriorDayMoneyline extends React.Component {
  state = {
    moneyLineData: [],
    filename: "Moneyline - " + Date().toLocaleString() + ".csv",
    selectedaccounts: [],
    isLoadbuttonclicked: false,
    loading: false
  };

  constructor(props) {
    super(props)
    autoBind(this)

    
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }

    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
    };
  }

  onOpenModal() {
    this.formReset();
    this.setState({ showAddModal: true });
  }

  async componentDidMount() {

    this.setState({ loading: true })

    this.setState({ selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    });

  }

  sizePerPageListChange(sizePerPage) {
    alert(`sizePerPage: ${sizePerPage}`);
  }

  onPageChange(page, sizePerPage) {
    alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
  }

  async getloaddata() {

    this.setState({ loading: true })

    this.setState({ selectedaccounts: await this.props.loadData }, async () => {

      let array = [];

      this.state.isLoadbuttonclicked = true;

      let moneylineesponse = await this.props.priormoneyselaccountList(
        this.state.selectedaccounts
      );

      moneylineesponse.data.body &&
        moneylineesponse.data.body.map((data, index) => {
          array.push(data);
        });

      this.setState({ moneyLineData: array });
      this.setState({ loading: false })
    });

  }

  async getloadfulldata() {

    await this.props.getstatus()

    this.setState({ loading: true, selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    });
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <>
        <span
          className="tablehead-buttonlinks col-12 float-left"
          style={{ alignItems: "right" }}
        >
          <div className="actionbuttons">
            <button
              className="btn btn-dark btn-load"
              onClick={this.getloaddata}
            >
              Load
            </button>
            <button
              className="btn btn-dark btn-refresh"
              onClick={this.getloadfulldata}
            >
              Refresh
            </button>
            <button className="btn btn-dark" onClick={onClick}>
              Export
            </button>
          </div>
        </span>
      </>
    );
  };

  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
      expandBy: "column",
      sizePerPage: 15,
      sizePerPageList: [{
        text: '15', value: 15
      }],
    };

    function colorstyle(fieldValue, row, rowIdx) {
      return { color: fieldValue < 0 ? "red" : "white" };
    }

    return (
      this.state.loading ? (<div className="d-inline-block dashboard"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
        <div className="col-12 dashboard-main-area">
          <div className="card bg-black">
            <div className="card-body p-0">
              <ul
                className="nav nav-tabs float-left"
                id="custom-content-below-tab"
                role="tablist"
                style={{ width: "100%" }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-content-below-trade-tab"
                    data-toggle="pill"
                    href="#custom-content-below-trade"
                    role="tab"
                    aria-controls="custom-content-below-trade"
                    aria-selected="true"
                  >
                    Moneyline
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="custom-content-below-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-content-below-trade"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-trade-tab"
                >
                  <div
                    className="card-body moneygridalignment table-responsive p-0"
                    style={{ MaxHeight: 400 }}
                  >
                    {!this.state.isLoadbuttonclicked ? (
                      <BootstrapTable
                        data={this.state.moneyLineData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"88px"}
                          Style={{ "minWidth": "88px" }}
                          className="moneygriddatebind"
                          dataField="gmicobdate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"44px"}
                          Style={{ "minWidth": "44px" }}
                          dataField="firm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"49px"}
                          Style={{ "minWidth": "49px" }}
                          dataField="office"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"58px"}
                          Style={{ "minWidth": "58px" }}
                          dataField="account"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          className="moneygridshrtnamebind"
                          dataField="shortname"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"68px"}
                          Style={{ "minWidth": "68px" }}
                          dataField="accounttype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Acct Type",
                          }}
                        >
                          Acct Type
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"72px"}
                          Style={{ "minWidth": "72px" }}
                          dataField="accttypecurrsymbol"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Currency",
                          }}
                        >
                          Currency
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          className="moneygrideblance"
                          dataField="accountbalance"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Ending Balance",
                          }}
                        >
                          Ending Balance
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridbblance"
                          dataField="previousbalance"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Beginning Balance",
                          }}
                        >
                          Beginning Balance{" "}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          dataField="opentradeequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Open Trade Equity",
                          }}
                        >
                          Open Trade Equity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"100px"}
                          Style={{ "minWidth": "100px" }}
                          tdStyle={colorstyle}
                          dataField="totalequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Total Equity",
                          }}
                        >
                          Total Equity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          className="moneygridtcm"
                          dataField="totoutstandingmargincalls"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Total Margin Call",
                          }}
                        >
                          Total Margin Call
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"100px"}
                          Style={{ "minWidth": "100px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="marginexcessdeficit"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Excess/Deficit",
                          }}
                        >
                          Excess/Deficit
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridwdf"
                          dataField="withdrawablefunds"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Withdrawable Fund",
                          }}
                        >
                          Withdrawable Fund
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"125px"}
                          Style={{ "minWidth": "125px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="spanadjustedequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "SpanAdjustedEquity",
                          }}
                        >
                          SpanAdjustedEquity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          dataField="optionpremium"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Option Premium",
                          }}
                        >
                          Option Premium
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"160px"}
                          Style={{ "minWidth": "180px" }}
                          tdStyle={colorstyle}
                          className="moneygridr2bc"
                          dataField="convrate2basecurrency"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Rate to Acct Base Currency",
                          }}
                        >
                          Rate to Acct Base Currency
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          dataField="buyingpower"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Buying Power",
                          }}
                        >
                          Buying Power
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="prevote"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Previous OTE",
                          }}
                        >
                          Previous OTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridcequity"
                          dataField="cashequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Cash Equity",
                          }}
                        >
                          Cash Equity
                        </TableHeaderColumn>
                      </BootstrapTable>
                    ) : (
                      <BootstrapTable
                        data={this.state.moneyLineData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"88px"}
                          Style={{ "minWidth": "88px" }}
                          className="moneygriddatebind"
                          dataField="gmicobdate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"44px"}
                          Style={{ "minWidth": "44px" }}
                          dataField="firm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"49px"}
                          Style={{ "minWidth": "49px" }}
                          dataField="office"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"58px"}
                          Style={{ "minWidth": "58px" }}
                          dataField="account"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          className="moneygridshrtnamebind"
                          dataField="shortname"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"68px"}
                          Style={{ "minWidth": "68px" }}
                          dataField="accounttype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Acct Type",
                          }}
                        >
                          Acct Type
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"72px"}
                          Style={{ "minWidth": "72px" }}
                          dataField="accttypecurrsymbol"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Currency",
                          }}
                        >
                          Currency
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          className="moneygrideblance"
                          dataField="accountbalance"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Ending Balance",
                          }}
                        >
                          Ending Balance
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridbblance"
                          dataField="previousbalance"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Beginning Balance",
                          }}
                        >
                          Beginning Balance{" "}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          dataField="opentradeequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Open Trade Equity",
                          }}
                        >
                          Open Trade Equity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"100px"}
                          Style={{ "minWidth": "100px" }}
                          tdStyle={colorstyle}
                          dataField="totalequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Total Equity",
                          }}
                        >
                          Total Equity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          className="moneygridtcm"
                          dataField="totoutstandingmargincalls"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Total Margin Call",
                          }}
                        >
                          Total Margin Call
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"100px"}
                          Style={{ "minWidth": "100px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="marginexcessdeficit"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Excess/Deficit",
                          }}
                        >
                          Excess/Deficit
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridwdf"
                          dataField="withdrawablefunds"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Withdrawable Fund",
                          }}
                        >
                          Withdrawable Fund
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"125px"}
                          Style={{ "minWidth": "125px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="spanadjustedequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "SpanAdjustedEquity",
                          }}
                        >
                          SpanAdjustedEquity
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"110px"}
                          Style={{ "minWidth": "110px" }}
                          tdStyle={colorstyle}
                          dataField="optionpremium"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Option Premium",
                          }}
                        >
                          Option Premium
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"160px"}
                          Style={{ "minWidth": "180px" }}
                          tdStyle={colorstyle}
                          className="moneygridr2bc"
                          dataField="convrate2basecurrency"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Rate to Acct Base Currency",
                          }}
                        >
                          Rate to Acct Base Currency
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          dataField="buyingpower"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Buying Power",
                          }}
                        >
                          Buying Power
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridmsd"
                          dataField="prevote"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Previous OTE",
                          }}
                        >
                          Previous OTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"120px"}
                          Style={{ "minWidth": "120px" }}
                          tdStyle={colorstyle}
                          className="moneygridcequity"
                          dataField="cashequity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Cash Equity",
                          }}
                        >
                          Cash Equity
                        </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriordayMoney: state.csr.priormoneyline,
    companyaccount: state.csr.companyaccountList,
    accountgroup: state.csr.accountgroupselection,
    Selectedpriormoney: state.csr.priormoneyselaccountList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priormoneyline: () => {
      return dispatch(priormoneyline());
    },
    companyaccountList: () => {
      return dispatch(companyaccountList());
    },
    accountgroupselection: () => {
      return dispatch(accountgroupselection());
    },
    priormoneyselaccountList: (formData) => {
      return dispatch(priormoneyselaccountList(formData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PriorDayMoneyline);
