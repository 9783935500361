import axios from 'axios'
import config from './config'


export const tierList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'tier-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'TIER_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const tierAdd = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-tier', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'TIER_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const tierEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-tier', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'TIER_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const tierById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-tier/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'TIER_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const tierDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-tier', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'TIER_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}