import React from "react"
import { companiesList } from '../../../../actions/csrCompaniesAction.js'
import autoBind from "react-autobind"
import { connect } from "react-redux"
import _ from "lodash"
import { companyEdit, ActiveStatusUpdateforUser } from "../../../../actions/csrCompaniesAction.js"
import jwtDecode from "jwt-decode";


class companiesDetails extends React.Component {
  state = {
    OnboardedDate: null,
    ApprovedDate: null,
    UpdatedDate: null,
    form: {
      active: null,
      id: this.props.formDetails.id,
      createdby: this.props.createdby,
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    form["id"] = this.props.formDetails.id
    form["active"] = e.target.value
    this.setState(_.extend(this.state.form, form))
    this.ActiveStatusUpdate()
  }

  async ActiveStatusUpdate() {
    let response = await this.props.ActiveStatusUpdateforUser(this.state.form)
    if (response && response.status === 200) {
      this.setState({ successMessage: "Updated Successfully" })
      this.setState({ successAlert: true })
      window.location.reload(false)
    }
  }

  async onupdateButttonClick() {
    let response = await this.props.companyEdit(this.state.form)
    if (response && response.status === 200) {
      this.setState({ successMessage: "Updated Successfully" })
      this.setState({ successAlert: true })
      window.location.reload(false)
    }
  }

  async componentDidMount() {

    if (this.props.formDetails.ApprovedDate !== null) {
      let aprdte = this.props.formDetails.ApprovedDate.replace('T', ' ')
      this.state.ApprovedDate = aprdte.replace('.000Z', '')
    }
    if (this.props.formDetails.UpdatedDate !== null) {
      let updte = this.props.formDetails.UpdatedDate.replace('T', ' ')
      this.state.UpdatedDate = updte.replace('.000Z', '')
    }
    if (this.props.formDetails.OnboardedDate !== null) {
      let onboarddte = this.props.formDetails.OnboardedDate.replace('T', ' ')
      this.state.OnboardedDate = onboarddte.replace('.000Z', '')
    }

    this.state.form.active = this.props.formDetails.Active
    let form = {}
    form["id"] = this.props.formDetails.id
    form["active"] = this.state.form.active
    this.setState(_.extend(this.state.form, form))
  }

  render() {

    return (


      <div className="col-md-12 pl-0 pr-0">
        <div className="col-md-6 pl-3 float-left">
          <div className="row">
            <div
              className="card bg-black col-md-12 block-right"
              style={{ MaxHeight: 440 }}
            >
              <div className="card-header">
                <h5 className="m-0">Companies Details</h5>
              </div>
              <div className="card-body" style={{ maxHeight: 396 }}>
                <input
                  type="hidden"
                  id="Id"
                  name="Id"
                  value={this.props.formDetails.id}
                />
                <div className="row">
                  <div className="col-md-3 form-title">Company:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.companyname}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Company ID:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.companyID}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Entity Type:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.entitytype}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-title">Country:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.country}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">User Region:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.region}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-title">Tier:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.companytier}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Domain:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.safedomain1 !== ' ' ? this.props.formDetails.safedomain1 : ''}
                    {this.props.formDetails.safedomain2 !== ' ' ? ',' + this.props.formDetails.safedomain2 : ''}
                    {this.props.formDetails.safedomain3 !== ' ' ? ',' + this.props.formDetails.safedomain3 : ''}
                    {this.props.formDetails.safedomain4 !== ' ' ? ',' + this.props.formDetails.safedomain4 : ''}
                    {this.props.formDetails.safedomain5 !== ' ' ? ',' + this.props.formDetails.safedomain5 : ''}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">SWIFT Code (BIC):</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.swiftcode}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 form-title">Notes:</div>
                  <div className="col-md-9 form-value">
                    {this.props.formDetails.note}
                  </div>
                </div>

                <div className="status-box col-md-12">
                  <div className="row">
                    <div className="col-md-3 form-title">Active</div>
                    {(this.props.formDetails.Created_User_Mail_ID === localStorage.getItem('useremailid')) ?
                      <div className="col-md-9 form-value">
                        {this.props.formDetails.Active}
                      </div>
                      :
                      <div className="col-md-9 form-value">
                        <select name="active" value={this.state.form.active} onChange={this.handleInputChange}>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>}
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Onboarded By:</div>
                    <div
                      className="col-md-9 form-value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.formDetails.Created_User_Name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Onboarded Date:</div>
                    <div className="col-md-9 form-value">
                      {this.state.OnboardedDate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Approved By:</div>
                    <div
                      className="col-md-9 form-value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.formDetails.Certified_User_Name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Approved Date:</div>
                    <div className="col-md-9 form-value">
                      {this.state.ApprovedDate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Updated By:</div>
                    <div
                      className="col-md-9 form-value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.formDetails.Updated_User_Name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Updated Date:</div>
                    <div className="col-md-9 form-value">
                      {this.state.UpdatedDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Companies: state.csr.companiesList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    companyEdit: (formData) => {
      return dispatch(companyEdit(formData))
    },
    ActiveStatusUpdateforUser: (formData) => {
      return dispatch(ActiveStatusUpdateforUser(formData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(companiesDetails)