import axios from 'axios'
import config from './config'

export const entityList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'entity-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ENTITY_LIST',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const entityAdd = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-entity', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const entityEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-entity', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const entityById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-entity/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const entityDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-entity', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ASSET_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}