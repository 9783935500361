import axios from 'axios'
import config from './config'

export const historicalReportsList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'historical-reports', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'HISTORICAL_REPORTS_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const historicalreportlistById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'historical-reports/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_HISTORICAL_REPORT_BY_ID',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}