import React from "react"
import { reportslist, GetReportsDetails,AddToS3 } from "../../actions/ReportsAction"
import { connect } from "react-redux"
import SceduleUser from "../schedulers/schedule_User"
import autoBind from "react-autobind"
import { reportlistById } from "../../actions/ReportsAction"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { ExportToCsv } from "export-to-csv"
import jsPDF from "jspdf"
import _ from "lodash"
import "jspdf-autotable"
import S3Objects from "../s3Bucket/ListofObjects"
import { Spinner } from 'react-bootstrap'
import AlertPopup from '../AlertPopup/popupDesign'
import jwtDecode from "jwt-decode";



class Reports extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    fileformat: null,
    data: [],
    showAddModal: false,
    fileName: null,
    form: {
      selectedReportViewName: null,
      UserID: null,
      ReportId: null,
      UserType: null,
      REPORTSYSTEM: null,
      REPORTBATCHID: null,
      REPORTBATCHTYPE: null,
      REPORTLOADTYPE: null,
    },

    isReportselected: false,
    isschedulebuttonClicked: false,
    reportDetails: null,
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  async exportdata() {

    this.setState({ loading: true })
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      title: 'My Awesome CSV',
      filename: this.state.fileName.trim()
    }

    const csvExporter = new ExportToCsv(options)

    let response = await this.props.GetReportsDetails(this.state.form)

    this.state.data = []

    if (response && response.status === 200) {
      response.data.body &&
        response.data.body.map((reports, index) => {
          this.state.data.push(reports)
        })
    }
    if (this.state.data.length !== 0) {
      csvExporter.generateCsv(this.state.data)
      this.addtos3()
    } else {
      this.setState({ showAddModal: true })
    }
    this.setState({ loading: false })
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  async printDocument() {
    var doc = new jsPDF("p", "pt")

    doc.autoTable({ html: "#my-table" })

    doc.autoTable({
      head: [["STATEMENT"]],
      body: [
        (await this.props.Listofreports) &&
        this.props.Listofreports.map((reports, index) => {
          return reports.REPORTID
        }),
      ],
    })

    doc.save("pdfgenerated.pdf")
  }

  rowvalues() {
    return (
      this.props.Listofreports &&
      this.props.Listofreports.map((reports, index) => {
        return <td>{reports.REPORTID}</td>
      })
    )
  }

  getstatus(status) {
    this.setState({ isschedulebuttonClicked: false })
    this.setState({ isReportselected: false })
  }

  schedulebuttonclicked() {
    if (this.state.isReportselected) {
      this.setState({ isschedulebuttonClicked: true })
    }
  }
  addtos3() {
    if (this.state.isReportselected >=1) {
        const data = this.state.data;
        const reportDetails = this.state.reportDetails;
        const form = this.state.form;
        const filepath = localStorage.getItem('companyname').toLowerCase().replace(/\s/g, '')+"/"+localStorage.getItem('useridforuser');
        this.props.AddToS3({ reportDetails, form ,filepath,data});
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let response = await this.props.reportslist()
    this.setState({ loading: false })
  }

  async cancelAlert() {
    this.setState({ showAddModal: false })
  }

  onGetEditWarningStatus() {
    this.setState({ showAddModal: false })
  }

  cancelclicked() {
    this.setState({ showAddModal: false })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isReportselected: true })
          let response = await this.props.reportlistById(row.ID)
          if (response && response.status === 200) {
            this.setState({ reportDetails: response.data.body, fileName: response.data.body.REPORTNAME })
            let form = {}
            form["selectedReportViewName"] = response.data.body.REPORTSRC
            form["UserID"] = localStorage.getItem("useridforuser")
            form["ReportId"] = response.data.body.REPORTID
            form["UserType"] = localStorage.getItem("userType")
            form["REPORTSYSTEM"] = response.data.body.REPORTSYSTEM
            form["REPORTBATCHID"] = response.data.body.REPORTBATCHID
            form["REPORTBATCHTYPE"] = response.data.body.REPORTBATCHTYPE
            form["REPORTLOADTYPE"] = response.data.body.REPORTLOADTYPE
            this.setState({ fileformat: response.data.body.REPORTFORMAT })
            this.setState(_.extend(this.state.form, form))
          }
        }
      },
    }
    return !this.state.isMyconsoleClicked ? (
      this.state.isschedulebuttonClicked ? (
        <SceduleUser
          compviewstatus={this.getstatus}
          selectedreport={this.state.reportDetails}
        />
      ) : (
        // this.state.showAddModal ? <WarningPopup getStatusofpopup={this.onGetEditWarningStatus} cancelStatus={this.cancelclicked} Message={'Can\'t Download file! Selected Report has No data'} /> :

        this.state.showAddModal ? <AlertPopup getStatus={this.onGetEditWarningStatus} cancelStatus={this.cancelclicked} Message={'Can\'t Download file! Selected Report has No data'} /> :

          <div className="content-wrapper">

            <section className="content-header pb-0">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 dashboard-main-area">
                    <div className="card bg-black">
                      <div className="card-header card-onboarding">
                        <div className="col-md-12 action-group report-actions">
                          {
                            (this.state.fileformat = "CSV" ? (
                              <button
                                className="btn btn-default"
                                onClick={this.exportdata}
                              >
                                Run
                              </button>
                            ) : (
                              <button
                                className="btn btn-default"
                                onClick={this.printDocument}
                              >
                                Run
                              </button>
                            ))
                          }
                          {localStorage.getItem("userType") === "INTERNAL" ? (
                            <button
                              className="btn btn-default"
                              onClick={this.schedulebuttonclicked}
                            >
                              Schedule
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                          <div
                            className="table-responsive p-0 report-table reports"
                            style={{ maxHeight: 422 }}
                          >
                            <BootstrapTable
                              data={this.props.Listofreports}
                              selectRow={selectRowProp}
                              striped
                              hover
                            >
                              <TableHeaderColumn isKey dataField="ID" hidden>
                                ID
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"105px"}
                                dataField="REPORTID"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Report ID",
                                }}
                              >
                                Report ID
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"300px"}
                                dataField="REPORTNAME"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Report Name",
                                }}
                              >
                                Report Name
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"80px"}
                                dataField="REPORTSYSTEM"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "System",
                                }}
                              >
                                System
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"90px"}
                                dataField="REPORTBATCHID"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Batch ID",
                                }}
                              >
                                Batch ID
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"90px"}
                                dataField="REPORTBATCHTYPE"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Batch Type",
                                }}
                              >
                                Batch Type
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"90px"}
                                dataField="REPORTLOADTYPE"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Load Type",
                                }}
                              >
                                Load Type
                          </TableHeaderColumn>
                              <TableHeaderColumn
                                width={"70px"}
                                dataField="REPORTFORMAT"
                                filter={{
                                  type: "TextFilter",
                                  placeholder: "Format",
                                }}
                              >
                                Format
                          </TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      )
    ) : (
      <S3Objects />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Listofreports: state.csr.reportslist,
    reportListbyID: state.csr.getReportbyId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reportslist: () => {
      return dispatch(reportslist())
    },
    reportlistById: (id) => {
      return dispatch(reportlistById(id))
    },
    GetReportsDetails: (formData) => {
      return dispatch(GetReportsDetails(formData))
    },
    AddToS3: (formData) => {
      return dispatch(AddToS3(formData));
   },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports)
