import axios from 'axios'
import config from './config'

export const userList = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'user-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USERS_LIST',
                    value: response.data.body
                })
                return response
            }).catch(error => {
                console.log(error)
            })
    }
}

export const userTypeList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'usertype-list', axiosconfig).then(response => {
            dispatch({
                type: 'USERS_TYPES_LIST',
                value: response.data.body
            })
        }).catch(error => {
            console.log(error)
        })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const userAdd = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-user', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USERS_ADD_SUCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const userDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-user', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USER_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const userEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-user', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USER_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const userActiveUpdate = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'active-userupdate', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USER_ACTIVE_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const userById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-user/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USER_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const userAccById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'usergroup-list/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'USER_ACC_GROUP_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}
//

export const ActiveStatusUpdateforUser = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'active-userupdate', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACTIVE_STATUS_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const CreatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'CreatedUserfromUserTable/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_CREATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const UpdatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'UpdatedUserfromUserTable/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_UPDATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const CertifiedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'CertifiedUserfromUserTable/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_CRETIFIED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const ActGrpListforUser = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'ActGrpDetailsforUser', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACCOUNT_GROUP_LIST_FOR_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}
