import autoBind from 'react-autobind'
import React, { Component } from 'react'

class Footer extends Component {

  state = {

  }

  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    return (
      <footer className="main-footer bg-black">
        <div className="float-right d-none d-sm-block">

        </div>
        <strong>Copyright & copy 2020 Cx Futures.</strong> All rights reserved.
      </footer>
    )
  }
}

export default Footer
