import React from 'react'
import { statementList, statementlistById } from '../../actions/StatementAction'
import { GetReportsDetails } from '../../actions/ReportsAction'
import { connect } from "react-redux"
import autoBind from "react-autobind"
import ScheduleUser from '../schedulers/schedule_User'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { ExportToCsv } from 'export-to-csv'
import _ from "lodash"
import WarningPopup from '../AlertPopup/alertpopupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";



const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
}

class Statements extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    isRunButtonClicked: false,
    fileformat: null,
    data: [],
    data1: [],
    showAddModal: false,
    showpdfPreview: false,
    form: {
      selectedReportName: null,
      selectedReportViewName: null,
      UserID: null,
      ReportId: null,
      UserType: null,
      REPORTSYSTEM: null,
      REPORTBATCHID: null,
      REPORTBATCHTYPE: null,
      REPORTLOADTYPE: null
    },
    isStatementsselected: false,
    isschedulebuttonClickedfromstatements: false,
    selectedstatementreportname: null,
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  async exportdata() {

    const csvExporter = new ExportToCsv(options)

    let response = await this.props.GetReportsDetails(this.state.form)

    this.state.data = []

    if (response && response.status === 200) {
      response.data.body && response.data.body.map((reports, index) => {
        this.state.data.push(reports)
      })
    }

    if (this.state.data.length !== 0) {
      csvExporter.generateCsv(this.state.data)
    }
    else {
      this.setState({ showAddModal: true })
    }
  }

  async runbuttonClicked() {

    this.state.data = []
    let response = await this.props.GetReportsDetails(this.state.form)
    fetch(response.data.body)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = this.state.form.selectedReportName + '.pdf'
          a.click()
        })
      })
  }

  async cancelAlert() {
    this.setState({ showAddModal: false })
  }

  getstatus(status) {
    this.setState({ isschedulebuttonClickedfromstatements: false })
    this.setState({ isStatementsselected: false })
  }

  schedulebuttonclicked() {
    if (this.state.isStatementsselected) {
      this.setState({ isschedulebuttonClickedfromstatements: true })
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.statementList()
    this.setState({ loading: false })
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  onGetEditWarningStatus() {
    this.setState({ showAddModal: false })
  }

  cancelclicked() {
    this.setState({ showAddModal: false })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isStatementsselected: true })
          let response = await this.props.statementlistById(row.ID)
          if (response && response.status === 200) {
            this.setState({ selectedstatementreportname: response.data.body, fileformat: response.data.body.REPORTFORMAT })
            let form = {}
            form["selectedReportName"] = response.data.body.REPORTNAME
            form["selectedReportViewName"] = response.data.body.REPORTSRC
            form["UserID"] = localStorage.getItem('useridforuser')
            form["ReportId"] = response.data.body.REPORTID
            form["UserType"] = localStorage.getItem('userType')
            form["REPORTSYSTEM"] = response.data.body.REPORTSYSTEM
            form["REPORTBATCHID"] = response.data.body.REPORTBATCHID
            form["REPORTBATCHTYPE"] = response.data.body.REPORTBATCHTYPE
            form["REPORTLOADTYPE"] = response.data.body.REPORTLOADTYPE
            form["CompanyName"] =localStorage.getItem('companyname')
            this.setState(_.extend(this.state.form, form))
          }
        }
      }
    }


    return (
      this.state.showAddModal ? <WarningPopup getStatusofpopup={this.onGetEditWarningStatus} Message={'Can\'t Download file! Selected Report has No data'} /> :
        this.state.isschedulebuttonClickedfromstatements ? <ScheduleUser compviewstatusofStatements={this.getstatus} fromStatements={true} selectedstatement={this.state.selectedstatementreportname} /> :
          <div className="content-wrapper">

            <section className="content-header pb-0">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item active">Statements</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">


                  <div className="col-12 dashboard-main-area">
                    <div className="card bg-black">
                      <div className="card-header card-onboarding">
                        <div className="col-md-12 action-group report-actions">
                          {this.state.fileformat === "CSV" ?
                            (<button className="btn btn-default" onClick={this.exportdata}>Run</button>)
                            :
                            (<button className="btn btn-default" onClick={this.runbuttonClicked}>Run</button>)}
                          {localStorage.getItem('userType') === "INTERNAL" ?
                            <button className="btn btn-default" onClick={this.schedulebuttonclicked}>Schedule</button>
                            : ""
                          }
                        </div>
                      </div>
                      <div className="card-body p-0">
                        {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                          <div className="table-responsive p-0 report-table statements-report" style={{ height: 410 }}>
                            <BootstrapTable data={this.props.Listofstatements} selectRow={selectRowProp} striped hover >
                              <TableHeaderColumn isKey dataField='ID' hidden >ID</TableHeaderColumn>
                              <TableHeaderColumn width={"105px"} dataField='REPORTID' filter={{ type: 'TextFilter', placeholder: 'Statement ID' }}>Statement ID</TableHeaderColumn>
                              <TableHeaderColumn width={"300px"} dataField='REPORTNAME' filter={{ type: 'TextFilter', placeholder: 'Statement Name' }}>Statement Name</TableHeaderColumn>
                              <TableHeaderColumn width={"80px"} dataField='REPORTSYSTEM' filter={{ type: 'TextFilter', placeholder: 'System' }}>System</TableHeaderColumn>
                              <TableHeaderColumn width={"90px"} dataField='REPORTBATCHID' filter={{ type: 'TextFilter', placeholder: 'Batch ID' }}>Batch ID</TableHeaderColumn>
                              <TableHeaderColumn width={"90px"} dataField='REPORTBATCHTYPE' filter={{ type: 'TextFilter', placeholder: 'Batch Type' }}>Batch Type</TableHeaderColumn>
                              <TableHeaderColumn width={"90px"} dataField='REPORTLOADTYPE' filter={{ type: 'TextFilter', placeholder: 'Load Type' }}>Load Type</TableHeaderColumn>
                              <TableHeaderColumn width={"70px"} dataField='REPORTFORMAT' filter={{ type: 'TextFilter', placeholder: 'Format' }}>Format</TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Listofstatements: state.csr.statementList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statementList: () => {
      return dispatch(statementList())
    },
    statementlistById: (id) => {
      return dispatch(statementlistById(id))
    },
    GetReportsDetails: (formData) => {
      return dispatch(GetReportsDetails(formData))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Statements)

