import _ from "lodash"
import React from 'react'
import autoBind from "react-autobind"
import { companiesList } from '../../../../actions/csrCompaniesAction.js'
import { regionList } from '../../../../actions/csrRegionAction.js'
import { tierList } from '../../../../actions/csrTierAction.js'
import { entityList } from '../../../../actions/csrEntityAction.js'
import { companyAdd } from '../../../../actions/csrCompaniesAction.js'
import { connect } from "react-redux"
import jwtDecode from "jwt-decode";


class addCompanyDetails extends React.Component {
  state = {
    sumofDomainTabs: 0,
    val: 0,
    values: [],
    fafacir: null,
    SelectCompany: null,
    successAlert: false,
    successMessage: null,
    incrementValue: 1,
    incrementValue1: 0,
    companyerror: "",
    entityerror: "",
    countryerror: "",
    regionerror: "",
    tiererror: "",
    domainerror: "",
    swiftcodeerror: "",
    noteserror: "",
    form: {
      companyname: null,
      companyID: null,
      entitytype: null,
      country: null,
      region: null,
      companytier: null,
      domain: "Test",
      swiftcode: " ",
      note: ' ',
      safedomain1: " ",
      safedomain2: " ",
      safedomain3: " ",
      safedomain4: " ",
      safedomain5: " ",
      active: 'NO',
      futureusen1: 0,
      futureusen2: 0,
      futureusen3: 0,
      futureusen4: 0,
      futureusen5: 0,
      futureusec1: 'blank',
      futureusec2: 'blank',
      futureusec3: 'blank',
      futureusec4: 'blank',
      futureusec5: 'blank'
    }
  }


  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  createUI(val) {
    return this.state.values.map((el, i) =>
      <div key={i}>
        <input type="text" value={el || ''} onChange={this.handleChange.bind(this, i)} name={"safedomain" + val} />
        <i className="fas fa-times-circle" onClick={this.removeClick.bind(this, i)}></i>
      </div>
    )
  }

  addClick() {
    if (this.state.val > 4) return 0

    this.setState({ sumofDomainTabs: this.state.sumofDomainTabs + 1 })
    this.setState({ fafacir: "fas fa-plus-circle test" })
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
    this.state.incrementValue1 = this.state.incrementValue++
    this.state.val = this.state.val + 1
  }

  handleChange(i, event) {
    if (this.state.form.safedomain1 === " " || i === 0) {
      let form = {}
      let values = [...this.state.values]
      form["safedomain1"] = event.target.value
      values[i] = event.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ values })
    } else if (this.state.form.safedomain2 === " " || i === 1) {
      let form = {}
      let values = [...this.state.values]
      form["safedomain2"] = event.target.value
      values[i] = event.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ values })
    } else if (this.state.form.safedomain3 === " " || i === 2) {
      let form = {}
      let values = [...this.state.values]
      form["safedomain3"] = event.target.value
      values[i] = event.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ values })
    } else if (this.state.form.safedomain4 === " " || i === 3) {
      let form = {}
      let values = [...this.state.values]
      form["safedomain4"] = event.target.value
      values[i] = event.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ values })
    } else if (this.state.form.safedomain5 === " " || i === 4) {
      let form = {}
      let values = [...this.state.values]
      form["safedomain5"] = event.target.value
      values[i] = event.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ values })
    }
  }

  removeClick(i) {
    this.setState({ sumofDomainTabs: this.state.sumofDomainTabs - 1 })
    if (this.state.sumofDomainTabs === 0) {
      this.setState({ fafacir: "fas fa-plus-circle" })
    }
    let form = {}
    form["safedomain1"] = this.state.form.safedomain2
    form["safedomain2"] = this.state.form.safedomain3
    form["safedomain3"] = this.state.form.safedomain4
    form["safedomain4"] = this.state.form.safedomain5
    form["safedomain5"] = " "
    this.setState(_.extend(this.state.form, form))
    let values = [...this.state.values]
    values.splice(i, 1)
    this.setState({ values })
    this.setState({ val: this.state.val - 1 })
    this.state.incrementValue1 = this.state.incrementValue--
  }

  handleInputChange(e) {
    if (e.target.name === 'note') {
      if (e.target.value.length <= 100) {
        this.setState({ noteserror: '' })
        let form = {}
        form[e.target.name] = e.target.value
        this.setState(_.extend(this.state.form, form))
      }
      else {
        this.setState({ noteserror: 'Cannot Accept More then 100 Character' })
      }
    }
    else {
      let form = {}
      form[e.target.name] = e.target.value
      this.setState(_.extend(this.state.form, form))
    }
  }

  onCancelButttonClick() {
    this.props.showcompstatus(true)
  }

  validation() {
    let isDuplicateCompany = false
    if (!this.state.form.companyname) {
      this.setState({ companyerror: "Company Cannot be blank" })
      return false
    } else {
      this.props.companyList && this.props.companyList.map((data, index) => {
        if (data === this.state.form.companyname) {
          isDuplicateCompany = true
        }
      })
      if (isDuplicateCompany) {
        this.setState({ companyerror: "Company Name Already available" })
        return false
      }
      else {
        this.setState({ companyerror: "" })
      }
    }
    if (!this.state.form.entitytype) {
      this.setState({ entityerror: "Please choose any of the Entity Type" })
      return false
    } else {
      this.setState({ entityerror: "" })
    }
    if (!this.state.form.country) {
      this.setState({ countryerror: "Country Cannot be blank" })
      return false
    } else {
      this.setState({ countryerror: "" })
    }
    if (!this.state.form.region) {
      this.setState({ regionerror: "Please choose any of the Region" })
      return false
    } else {
      this.setState({ regionerror: "" })
    }
    if (!this.state.form.companytier) {
      this.setState({ tiererror: "Please choose any of the Tier" })
      return false
    } else {
      this.setState({ tiererror: "" })
    }
    if (this.state.form.safedomain1 === " " && this.state.form.safedomain2 === " " && this.state.form.safedomain3 === " " && this.state.form.safedomain4 === " " && this.state.form.safedomain5 === " ") {
      this.setState({ domainerror: "Must Enter atleast one domain name" })
      return false
    } else {
      this.setState({ domainerror: "" })
    }
    return true
  }


  async onSaveButttonClick() {
    if (this.state.form.safedomain1 === "") {
      let form = {}
      form["safedomain1"] = " "
      this.setState(_.extend(this.state.form, form))
    }
    const isValid = this.validation()
    if (isValid) {
      let response = await this.props.companyAdd(this.state.form)
      if (response && response.status === 200) {
        this.setState({ successMessage: "Added Successfully" })
        this.props.onSubmit(true)
      }
    }
  }

  onCancelButtonClick() {

  }
  async componentDidMount() {
    this.setState({ fafacir: "fas fa-plus-circle" })
    await this.props.companiesList()
    await this.props.regionList()
    await this.props.tierList()
    await this.props.entityList()
  }

  cancelAlert() {
    this.setState({ successAlert: false })
    this.props.onSubmit(true)
  }

  render() {

    let UserRegionList
    if (this.props.regions) {
      UserRegionList = this.props.regions.map((region) =>
        <option key={region.id} > {region.region}
        </option>
      )
    }

    let TireList
    if (this.props.tier) {
      TireList = this.props.tier.map((tier) =>
        <option key={tier.id} > {tier.tier}
        </option>
      )
    }

    let EntityList
    if (this.props.entity) {
      EntityList = this.props.entity.map((entity) =>
        <option key={entity.id} >{entity.entityType}</option>)
    }

    return (
      <div className="hold-transition sidebar-mini layout-navbar-fixed">
        <div className="wrapper">
          <div className="col-md-6 pl-3 float-left" >
            <div className="card bg-black col-md-12 block-right add-user-form" style={{ maxHeight: 380 }}>
              <div className="card-header">
                <h4>Company Add</h4>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-3 form-title">Company:</div>
                    <div className="col-md-9 form-value">
                      <input type="text" onChange={this.handleInputChange} value={this.state.form.companyname} name="companyname" placeholder="Enter company name" />
                      <div style={{ color: "red" }}>{this.state.companyerror}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Entity Type:</div>
                    <div className="col-md-9 form-value">
                      <select name="entitytype" value={this.state.form.entitytype} onChange={this.handleInputChange}>
                        <option value='0' >Select</option>
                        {EntityList}
                      </select>
                      <div style={{ color: "red" }}>{this.state.entityerror}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">User Country:</div>
                    <div className="col-md-9 form-value">
                      <input type="text" onChange={this.handleInputChange} value={this.state.form.country} name="country" placeholder="Enter your country" />
                      <div style={{ color: "red" }}>{this.state.countryerror}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">User Region:</div>
                    <div className="col-md-9 form-value">
                      <select name="region" value={this.state.form.region} onChange={this.handleInputChange}>
                        <option value='0' >Select</option>
                        {UserRegionList}
                      </select>
                      <div style={{ color: "red" }}>{this.state.regionerror}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Tier:</div>
                    <div className="col-md-9 form-value">
                      <select name="companytier" value={this.state.form.companytier} onChange={this.handleInputChange}>
                        <option value='0' >Select</option>
                        {TireList}
                      </select>
                      <div style={{ color: "red" }}>{this.state.tiererror}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Domain:</div>
                    <div className="col-md-9 form-value companyDomain">
                      <form onSubmit={this.handleSubmit}>
                        {this.createUI(this.state.val)}
                        <i class={this.state.fafacir} onClick={this.addClick.bind(this)}></i>
                        <div style={{ color: "red" }}>{this.state.domainerror}</div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">SWIFT Code (BIC):</div>
                    <div className="col-md-9 form-value">
                      <input type="text" onChange={this.handleInputChange} value={this.state.form.swiftcode} name="swiftcode" placeholder="Enter swift code" />
                      <div style={{ color: "red" }}>{this.state.swiftcodeerror}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 form-title">Notes:</div>
                    <div className="col-md-9 form-value">
                      <textarea name="note" onChange={this.handleInputChange} value={this.state.form.note} placeholder="Notes" style={{ marginBottom: 15 }}></textarea>
                      <div style={{ color: "red" }}>{this.state.noteserror}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12 action-group">
              <button className="btn btn-dark" onClick={this.onCancelButttonClick}>Cancel</button>
              <button className="btn btn-default" onClick={this.onSaveButttonClick}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}




const mapStateToProps = (state) => {
  return {
    regions: state.csr.regionList,
    Companies: state.csr.companiesList,
    tier: state.csr.tierList,
    entity: state.csr.entityList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    regionList: () => {
      return dispatch(regionList())
    },
    tierList: () => {
      return dispatch(tierList())
    },
    entityList: () => {
      return dispatch(entityList())
    },
    companyAdd: (formData) => {
      return dispatch(companyAdd(formData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(addCompanyDetails)