import autoBind from 'react-autobind'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Sidebar extends Component {

    state = {

    }

    constructor(props) {
        super(props)
        autoBind(this)
    }

    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a href="#" className="brand-link bgwhite">
                    <img src="../dist/img/logo-white.png" alt="AdminLTE Logo" className="brand-image" />
                </a>

                <div className="sidebar">
                    <nav className="mt-2">

                        {(window.location.pathname.includes("dashboard")) ?
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item ">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-copy"></i>
                                        <p>Home</p>
                                    </a>
                                </li>
                            </ul> : ''}

                        {(window.location.pathname.includes("csr")) ?
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item has-treeview menu-open">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-copy"></i>
                                        <p>General Configuration
                                        <i className="fas fa-angle-left right"></i>
                                            <span className="badge badge-info right"></span>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                        <li className="nav-item">
                                            <Link to="/csr-asset-class" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Asset Class</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/csr-entity-type" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Entity Type</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/csr-region" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Region</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/csr-product" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Product</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/csr-relationship" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Relationship</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/csr-tier" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Tier</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-copy"></i>
                                        <p>
                                            Client Onboarding
                                        <i className="fas fa-angle-left right"></i>
                                            <span className="badge badge-info right"></span>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview" style={{ display: 'none' }}>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Company Onboarding</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Account Onboarding</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Group Onboarding</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>User Onboarding</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul> : ''
                        }
                    </nav>
                </div>
            </aside>

        )
    }
}

export default Sidebar
