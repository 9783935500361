import axios from 'axios'
import config from './config'

export const regionList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'region-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REGION_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const regionAdd = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-region', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REGION_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const regionEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-region', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REGION_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const regionById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-region/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REGION_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const regionDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-region', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REGION_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}