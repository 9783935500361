import axios from 'axios';
import config from './config';

export const GetColorDetailsByID = (formData) => {
    return async function (dispatch) {
        if (formData && formData.UserID) {
            return await axios.post(config.apibaseUrl + '/getColorDetailByID', formData, axiosconfig)
                .then(response => {
                    dispatch({
                        type: 'GET_COLOR_BY_ID',
                        value: response.data.message
                    });
                    return response;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};

export const ToAddorUpdateNewColor = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + '/addNewColorForUser', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ADD_OR_UPDATE_COLOR',
                    value: response.data.message
                });
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

