import React from 'react'
import { connect } from "react-redux"
import { GetObjectsList, GetDownloadURl } from '../../actions/s3BucketAction'
import autoBind from "react-autobind"
import _ from "lodash"
import { Redirect } from "react-router"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import WarningPopup from '../AlertPopup/alertpopupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";



class ListofS3objects extends React.Component {
    state = {
        loading: false,
        nodatafounderror: false,
        listofReports: [],
        filterTxt: null,
        url: null,
        form: {
            fileName: null,
            companyName: localStorage.getItem('companyname').toLowerCase().replace(/ /g, ''),
            FolderName: localStorage.getItem('useridforuser')
        }
    }

    constructor(props) {
        super(props)
        autoBind(this)

        let expirationTime
        let token = localStorage.getItem('accessToken')
        if (token !== null) {
          const { exp } = jwtDecode(token)
          expirationTime = (exp * 1000)
          if (Date.now() >= expirationTime) {
            localStorage.clear();
            window.location.reload('/')
          }
        }
    }

    async componentDidMount() {

        this.setState({ loading: true })

        let array = []

        let response = await this.props.GetObjectsList(this.state.form)


        this.setState({ filterTxt: this.state.form.companyName + '/' + this.state.form.FolderName + '/' })

        response.data.body && response.data.body.map((data, index) => {
            data.LastModified = data.LastModified.replace('T', ' ')
            data.LastModified = data.LastModified.replace('.000Z', '')
            data.Key = data.Key.replace(this.state.form.companyName + '/' + this.state.form.FolderName + '/', '')
            if (data.Key !== '') array.push(data)
        })
        this.setState({ listofReports: array, loading: false })
    }

    async getDownloadUrl(row) {
        let form = {}
        form["fileName"] = this.state.form.companyName + '/' + this.state.form.FolderName + '/' + row.Key
        this.setState(_.extend(this.state.form, form))
        let response = await this.props.GetDownloadURl(this.state.form)
        this.download(response.data.body)
    }

    download(url) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: url,
            }
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file)
            // you could also do:
            // window.location.href = response.file
        }, 100)
    }

    cancelAlert() {
        return <Redirect to={'/dashboard'} />
    }

    buttonFormatter(cell, row) {
        return <i className="fa fa-arrow-down" onClick={() => { this.getDownloadUrl(row) }}></i>
    }

    onGetEditWarningStatus() {
        this.setState({ noDataError: false })
    }

    cancelclicked() {
        this.setState({ noDataError: false })
    }

    render() {

        var options = {
            onRowClick: async function (row, isSelect, rowIndex, e) {
                let form = {}
                form["fileName"] = e.target.dataset.value
                this.setState(_.extend(this.state.form, form))
                let response = await this.props.GetDownloadURl(this.state.form)
                this.download(response.data.body)
            }
        }

        return (
            this.state.nodatafounderror ? <WarningPopup getStatusofpopup={this.onGetEditWarningStatus} cancelStatus={this.cancelclicked} Message={'Specified User has no data in S3 Bucket'} /> :
                <div className="content-wrapper">
                    <section className="content-header pb-0">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item active">My Console</li>
                                    </ol>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item active">Folder Path : {this.state.filterTxt}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    {this.state.loading ? (<div className="d-inline-block dashboard"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                        <section className="content-header pb-0">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-9">
                                        <div
                                            className="card-body table-responsive s3objects p-0"
                                            style={{ MaxHeight: 360 }}
                                        >
                                            <BootstrapTable data={this.state.listofReports}>
                                                <TableHeaderColumn isKey width={"600px"} dataField='Key' filter={{ type: 'TextFilter', placeholder: 'Reports' }}>List of Reports</TableHeaderColumn>
                                                <TableHeaderColumn width={"200px"} dataField='LastModified' filter={{ type: 'TextFilter', placeholder: 'Date' }}>Business Date</TableHeaderColumn>
                                                <TableHeaderColumn width={"200px"} dataFormat={this.buttonFormatter} options={options}>Download</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetObjectsList: (formData) => {
            return dispatch(GetObjectsList(formData))
        },
        GetDownloadURl: (formData) => {
            return dispatch(GetDownloadURl(formData))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListofS3objects)