import _ from "lodash"
import autoBind from "react-autobind"
import { connect } from "react-redux"
import React, { Component } from "react"
import { assetList, assetDelete, assetAdd, assetById, assetEdit } from "../../../actions/csrAssetAction.js"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from 'react-router-dom'
import moment from "moment"
import AlertPopup from '../../AlertPopup/popupDesign'
import WarningPopup from '../../AlertPopup/alertpopupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";


class Assets extends Component {
  state = {
    loading: false,
    SentMessage: null,
    showalertforDuplicateInput: false,
    isDuplicate: false,
    noofcheckboxselected: 0,
    editclickedwhilenocheked: false,
    editclickedwhilemorethenone: false,
    editbuttonclicked: false,
    rowIds: [],
    warningAlert: false,
    warningMessage: null,
    successAlert: false,
    successMessage: null,
    errorAlert: false,
    errorMessage: null,
    showAddModal: false,
    edit: false,
    form: {
      product: null,
      assetClass: null,
      id: null,
    },
  }

  constructor(props) {
    super(props)
    autoBind(this)
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  async onOpenEditModal() {
    this.setState({ editbuttonclicked: true })
    if (this.state.noofcheckboxselected === 1) {
      if (this.state.rowIds.length) {
        let response = await this.props.assetById(this.state.rowIds[0])
        if (response && response.status !== undefined && response.status === 200) {
          this.setState({ edit: true })
          this.setState({ showAddModal: true })
          let form = {}
          form["product"] = response.data.body.product
          form["assetClass"] = response.data.body.assetClass
          form["id"] = response.data.body.id
          this.setState(_.extend(this.state.form, form))
        }
      }
    }
    else if (this.state.noofcheckboxselected === 0) {
      this.setState({ editclickedwhilenocheked: true })


    }
    else {
      this.setState({ editclickedwhilemorethenone: true })
    }
  }

  onOpenModal() {
    this.setState({ editbuttonclicked: false })
    this.formReset()
    this.setState({ showAddModal: true })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.assetList()
    this.setState({ loading: false })
  }

  markSelected(e) {
    if (e.target.checked) {
      this.setState({ noofcheckboxselected: this.state.noofcheckboxselected + 1 })
      this.state.rowIds.push(e.target.value)
    } else {
      this.setState({ noofcheckboxselected: this.state.noofcheckboxselected - 1 })
      let ids = this.state.rowIds.filter(function (id) {
        return id !== e.target.value
      })
      this.setState({ rowIds: ids })
    }
  }

  getAssetList() {
    return (
      this.props.assets &&
      this.props.assets.map((asset, index) => {
        return (
          <tr key={'item_' + index}>
            <td style={{ width: 110 }}>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  onClick={this.markSelected}
                  id={"customCheckbox" + (asset.id + 1)}
                  value={asset.id}
                />
                <label
                  htmlFor={"customCheckbox" + (asset.id + 1)}
                  className="custom-control-label"
                ></label>
              </div>
            </td>
            <td style={{ width: 340 }}>{asset.product}</td>
            <td style={{ width: 340 }}>{asset.assetClass}</td>
            <td style={{ width: 340 }}>{moment(asset.onboardedDate).format("DD MMM YYYY HH:mm:s")}</td>
            <td style={{ textTransform: "capitalize", width: 340 }}>{asset.userfirstname + " " + asset.userlastname}</td>
          </tr>
        )
      })
    )
  }

  removeData() {
    this.setState({ warningMessage: 'Are you sure want to Delete this Asset Record' })
    this.state.rowIds.length
      ? this.setState({ warningAlert: true })
      : this.setState({ warningAlert: false })
  }

  removeCheckbox() {
    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false)
  }

  async deleteRecord() {
    this.setState({ SentMessage: 'Asset Deleted Successfully' })
    this.setState({ warningAlert: false })
    let response = await this.props.assetDelete(this.state.rowIds)
    this.state.rowIds = []
    if (response && response.status !== undefined && response.status === 200) {
      this.removeCheckbox()
      this.setState({ successMessage: "Deleted Successfully", noofcheckboxselected: 0 })
      this.setState({ successAlert: true })
      await this.props.assetList()
      this.setState({ rowIds: [] })
    }
  }

  cancelAlert() {
    this.setState({ warningAlert: false })
    this.setState({ successAlert: false })
    this.setState({ showAddModal: false })
    this.setState({ editclickedwhilenocheked: false })
    this.setState({ editclickedwhilemorethenone: false })
  }

  handleInputChange(e) {
    if (e.target.value.length <= 30) {
      let form = {}
      form[e.target.name] = e.target.value
      this.setState(_.extend(this.state.form, form))
    }
  }

  formReset() {
    let form = {}
    form["product"] = null
    form["assetClass"] = null
    form["id"] = null
    this.setState(_.extend(this.state.form, form))
  }


  cancelAlert1() {
    this.setState({ showalertforDuplicateInput: false })
    this.setState({ isDuplicate: false })
    this.state.form.assetClass = ""
    this.state.form.product = ""
  }

  async submitAsset() {

    this.setState({ showAddModal: false })

    this.setState({ isDuplicate: false })

    this.props.assets &&
      this.props.assets.map((asset, index) => {
        if (asset.assetClass === this.state.form.assetClass || asset.product === this.state.form.product) {
          this.state.isDuplicate = true
        }
      })


    if (
      this.state.form.product === null ||
      this.state.form.assetClass === null
    ) {
      this.formReset()
      return false
    }

    if (this.state.edit) {
      this.setState({ SentMessage: 'Asset Edited Successfully' })
      this.setState({ noofcheckboxselected: 0 })
      let response = await this.props.assetEdit(this.state.form)
      this.removeCheckbox()
      this.state.rowIds = []
      if (response && response.status !== undefined && response.status === 200) {
        this.formReset()
        this.setState({ successMessage: "Updated Successfully" })
        this.setState({ successAlert: true })
        this.setState({ edit: false })
        await this.props.assetList()
        this.setState({ rowIds: [] })
      }
    } else {
      this.setState({ SentMessage: 'Asset Added Successfully' })
      if (!this.state.isDuplicate) {
        this.state.isDuplicate = false
        let response = await this.props.assetAdd(this.state.form)
        if (response && response.status !== undefined && response.status === 200) {
          this.formReset()
          this.setState({ successMessage: "Added Successfully" })
          this.setState({ successAlert: true })
          await this.props.assetList()
          this.setState({ rowIds: [] })
        }
      }
      else {
        this.setState({ showalertforDuplicateInput: true })
      }
    }
  }

  onGetWarningStatus() {
    this.setState({ noofcheckboxselected: 0, warningAlert: false })
    this.deleteRecord()
  }

  ongetstatus() {
    this.setState({ successAlert: false })
  }

  onGetEditWarningStatus() {
    this.setState({ noofcheckboxselected: 0, editclickedwhilenocheked: false, editclickedwhilemorethenone: false, showalertforDuplicateInput: false })
    this.state.rowIds = []
  }

  cancelclicked() {
    this.setState({ noofcheckboxselected: 0, editclickedwhilenocheked: false, editclickedwhilemorethenone: false, showalertforDuplicateInput: false, successAlert: false, warningAlert: false })
    this.state.rowIds = []
  }

  render() {

    return (
      this.state.loading ? (<Spinner animation="border" role="status"></Spinner>) :
        this.state.warningAlert ? (<WarningPopup alertType={'warningAlert'} getStatus={this.onGetWarningStatus} Message={this.state.warningMessage} cancelStatus={this.cancelclicked} />) :
          this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={this.state.SentMessage} />) :
            this.state.editclickedwhilenocheked ? (<WarningPopup getStatusofpopup={this.onGetEditWarningStatus} Message={'Please select any Asset Class'} cancelStatus={this.cancelclicked} />) :
              this.state.editclickedwhilemorethenone ? (<WarningPopup getStatusofpopup={this.onGetEditWarningStatus} Message={'Please select only one Asset Class'} cancelStatus={this.cancelclicked} />) :
                this.state.showalertforDuplicateInput ? (<WarningPopup getStatusofpopup={this.onGetEditWarningStatus} Message={'Asset Already Exist'} cancelStatus={this.cancelclicked} />) :

                  <div className="content-wrapper">
                    {this.state.showAddModal ? (
                      <SweetAlert cancelBtnBsStyle="default" confirmBtnText="Save" showCancel title={this.state.editbuttonclicked ? "Edit Asset class" : "Add Asset Class"} onConfirm={this.submitAsset} onCancel={this.cancelAlert}>
                        <form><div className="input-group mb-3"><input type="text" name="product" value={this.state.form.product} onChange={this.handleInputChange} className="form-control input-style" placeholder="Product" /></div>
                          <div className="input-group mb-3"><input type="text" name="assetClass" value={this.state.form.assetClass} onChange={this.handleInputChange} className="form-control input-style" placeholder="Asset Class" />
                          </div> </form></SweetAlert>) : ("")
                    }

                    <section className="content-header">
                      <div className="container-fluid">
                        <div className="row mb-2">
                          <div className="col-sm-4">
                            <ol className="breadcrumb float-sm-left">
                              <li className="breadcrumb-item">
                                <a href="#">CSR</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#">General Configuration</a>
                              </li>
                              <li className="breadcrumb-item active">Asset Class</li>
                            </ol>
                          </div>

                          <div className="col-sm-5">
                            <div className="card-head p-0">
                              <ul className="dash-sidebar-menu float-sm-left">
                                <li className="dash-sidebar-item active"><Link to="/dashboard/csr-asset-class" className="nav-link">Asset Class</Link></li>
                                <li className="dash-sidebar-item"><Link to="/dashboard/csr-entity-type" className="nav-link">Entity Type</Link></li>
                                <li className="dash-sidebar-item"><Link to="/dashboard/csr-region" className="nav-link">Region</Link></li>
                                <li className="dash-sidebar-item"><Link to="/dashboard/csr-product" className="nav-link">Product</Link></li>
                                <li className="dash-sidebar-item"><Link to="/dashboard/csr-relationship" className="nav-link">Relationship</Link></li>
                                <li className="dash-sidebar-item"><Link to="/dashboard/csr-tier" className="nav-link">Tier</Link></li>
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>
                    </section>

                    <section className="content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12">
                            <div className="card-header card-onboarding">
                              <div className="card-tools col-md-12 pl-0">
                                <div className="top-button-group row">
                                  <a
                                    className="btn btn-default add-user-btn"
                                    onClick={this.onOpenModal}
                                  >
                                    Add
                                  </a>
                                  <a
                                    href="#"
                                    className="btn btn-default add-user-btn"
                                    onClick={this.onOpenEditModal}
                                  >
                                    Edit
                                  </a>
                                  <button
                                    onClick={this.removeData}
                                    className="btn btn-default"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <div className="card bg-black">
                                  <div className="card-header">
                                    <h3 className="card-title">Asset Class</h3>
                                  </div>
                                  <div className="card-body table-responsive p-0 config" style={{ MaxHeight: 400 }}>
                                    <table className="table table-head-fixed text-nowrap">
                                      <thead>
                                        <tr>
                                          <th>Action</th>
                                          <th>Product</th>
                                          <th>Asset Class</th>
                                          <th>Created on</th>
                                          <th>Created by</th>
                                        </tr>
                                      </thead>
                                      <tbody>{this.getAssetList()}</tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    assets: state.csr.assetList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    assetList: () => {
      return dispatch(assetList())
    },
    assetDelete: (rowIds) => {
      return dispatch(assetDelete(rowIds))
    },
    assetAdd: (formData) => {
      return dispatch(assetAdd(formData))
    },
    assetById: (id) => {
      return dispatch(assetById(id))
    },
    assetEdit: (formData) => {
      return dispatch(assetEdit(formData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assets)
