import React from 'react'
import _ from "lodash"
import autoBind from "react-autobind"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { AddScheduler } from '../../actions/schedulerAction'
import { connect } from "react-redux"
import { accountGroupList } from '../../actions/csrAccountGroupAction'
import { companyBycompanyname } from '../../actions/csrCompaniesAction'
import S3Objects from '../s3Bucket/ListofObjects'
import AlertPopup from '../AlertPopup/popupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";




const initialState = {
  loading: false,
  isMyconsoleClicked: false,
  fileextensionError: "",
  dateformaterror: "",
  deliveryerror: "",
  accountgrperror: "",
  statementorReportfieldValue: null,
  successMessage: null,
  successAlert: null,
  accountGroupsList: [],
  selectedaccountgrouplist: [],
  removedaccountgrouplist: [],
  accountAfterAddButtonClicked: [],
  accountafterremoveButtonClicked: [],
  actGrpRightafterClkRmvBtn: [],
  bindrightSideafterClkRmvBtn: [],
  emptyarray: [],
  rightcheckboxids: [],
  leftcheckboxids: [],
  isaddbuttonClicked: false,
  isremovebuttonclicked: false,
  isrowselected: false,
  isrowselected1: false,
  displayErrorMessage: false,
  form: {
    userid: null,
    userfirstname: null,
    userlastname: null,
    useremailid: null,
    companyname: null,
    companyid: null,
    entitytype: null,
    country: null,
    region: null,
    companytier: null,
    swiftcode: null,
    rptstmid: null,
    rptstmname: null,
    dateformat: null,
    fextdte: null,
    deliveryftp: "Y",
    deliveryemail: "Y",
    schedulebytype: "ACCOUNTGROUP",
    accountgroup: " ",
    email2user: "N",
    ftp2user: "N",
    ftp2folder: null,
    futusec01: " ",
    futusec02: " ",
    futusec03: " ",
    futusec04: " ",
    futusec05: " ",
    futusec06: " ",
    futusec07: " ",
    futusec08: " ",
    futusec09: " ",
    futusec10: " ",
    items: []
  }
}

class Scheduler extends React.Component {
  state = initialState

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  handleInputChange1(e) {
    if (e.target.checked) {
      let form = {}
      form[e.target.name] = e.target.value
      this.setState(_.extend(this.state.form, form))
      this.setState({ deliveryerror: "" })
    } else {
      let form = {}
      form[e.target.name] = "N"
      this.setState(_.extend(this.state.form, form))
    }
  }

  addButtonClicked() {
    if (this.state.isrowselected) {
      this.setState({ accountgrperror: "" })
      this.state.accountafterremoveButtonClicked = []
      this.state.selectedaccountgrouplist && this.state.selectedaccountgrouplist.map((selected, index) => {
        this.state.accountAfterAddButtonClicked.push(selected)
        this.state.accountafterremoveButtonClicked.push(selected)
      })

      this.setState({ isaddbuttonClicked: true })
      this.state.leftcheckboxids && this.state.leftcheckboxids.map((ids, index) => {
        this.state.accountGroupsList = this.state.accountGroupsList.filter((accountselected) => {
          return accountselected.id !== ids
        })
      })
    }
    this.state.selectedaccountgrouplist = []
    this.setState({ isremovebuttonclicked: false })
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  formReset() {
    let form = {}
    form["userid"] = null
    form["userfirstname"] = null
    form["userlastname"] = null
    form["useremailid"] = null
    form["companyname"] = null
    form["companyid"] = null
    form["entitytype"] = null
    form["country"] = null
    form["region"] = null
    form["companytier"] = null
    form["swiftcode"] = null
    form["rptstmid"] = null
    form["rptstmname"] = null
    form["dateformat"] = null
    form["fextdte"] = null
    form["ftp2folder"] = null
    form["email2user"] = "N"
    form["ftp2user"] = "N"
    this.setState(_.extend(this.state.form, form))
    this.state.accountGroupsList = []
    this.state.selectedaccountgrouplist = []
    this.state.removedaccountgrouplist = []
    this.state.accountAfterAddButtonClicked = []
    this.state.accountafterremoveButtonClicked = []
    this.state.actGrpRightafterClkRmvBtn = []
    this.state.bindrightSideafterClkRmvBtn = []
    this.state.emptyarray = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
  }

  async removebuttonclicked() {
    let flag = false
    if (this.state.isrowselected1) {
      let form = _.cloneDeep(this.state)
      let containsids = this.state.leftcheckboxids
      let removedid = this.state.rightcheckboxids
      let dif = _.differenceWith(containsids, removedid, _.isEqual)
      form['leftcheckboxids'] = dif
      await this.setState(_.extend(this.state, form))

      this.setState({ isremovebuttonclicked: true })

      this.setState({ isremovebuttonclicked: true, isaddbuttonClicked: true })
      this.state.removedaccountgrouplist && this.state.removedaccountgrouplist.map((accountselected, index) => {
        this.state.accountGroupsList.push(accountselected)
        this.state.accountAfterAddButtonClicked = this.state.accountAfterAddButtonClicked.filter((actgrpid) => {
          return accountselected.id !== actgrpid.id
        })
      })

      this.setState({ isrowselected1: false })
      this.setState({ isrowselected: false })
    }
    this.state.removedaccountgrouplist = []
    this.state.rightcheckboxids = []
    this.state.leftcheckboxids = []
  }


  handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
    if (this.state.form.fextdte !== null) {
      this.setState({ fileextensionError: "" })
    }
    if (this.state.form.dateformat !== null) {
      this.setState({ dateformaterror: "" })
    }
  }

  validation() {
    if (this.state.form.fextdte === null) {
      this.setState({ fileextensionError: "Select any File Extension" })
      return false
    } else {
      this.setState({ fileextensionError: "" })
    }
    if (this.state.form.dateformat === null) {
      this.setState({ dateformaterror: "Select any Date format" })
      return false
    } else {
      this.setState({ dateformaterror: "" })
    }
    if (this.state.form.email2user === "N" && this.state.ftp2user === "N") {
      this.setState({ deliveryerror: "Select atleast One Delivery Method" })
      return false
    } else {
      this.setState({ deliveryerror: "" })
    }
  }

  async SaveButtonClicked() {

    this.validation()

    this.state.form.items = []
    this.state.accountAfterAddButtonClicked && this.state.accountAfterAddButtonClicked.map((account, index) => {
      this.state.form.items.push(account.actgroupid)
    })

    if (this.state.form.items.length === 0) {
      this.setState({ accountgrperror: "Select Account any Group" })
      return false
    }

    else {
      this.setState({ accountgrperror: "" })
      let response = await this.props.AddScheduler(this.state.form)
      if (response && response.status === 200) {
        this.setState({ successMessage: "Added Successfully" })
        this.setState({ successAlert: true })
      }
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    this.formReset()
    this.setState({ statementorReportfieldValue: this.props.selectedreportDetail.REPORTNAME.trim() + "-" + this.props.selectedUserDetail.userid + "-" + new Date().toLocaleString() })
    let getlist = await this.props.companyBycompanyname(this.props.selectedUserDetail.companyname)
    if (getlist && getlist.status == 200) {
      let form = {}
      form["accountgroup"] = this.props.selectedUserDetail.accountgroup
      form["userid"] = this.props.selectedUserDetail.userid
      form["userfirstname"] = this.props.selectedUserDetail.userfirstname
      form["userlastname"] = this.props.selectedUserDetail.userlastname
      form["useremailid"] = this.props.selectedUserDetail.useremailid
      form["companyname"] = this.props.selectedUserDetail.companyname
      try {
        form["companyid"] = getlist.data.body.companyid
        form["entitytype"] = getlist.data.body.entitytype
        form["country"] = getlist.data.body.country
        form["region"] = getlist.data.body.region
        form["companytier"] = getlist.data.body.companytier
        form["swiftcode"] = getlist.data.body.swiftcode
      } catch (e) {
        this.setState({ displayErrorMessage: true })
      }
      form["rptstmid"] = this.props.selectedreportDetail.REPORTID
      form["rptstmname"] = this.props.selectedreportDetail.REPORTNAME
      form["ftp2folder"] = "" + this.props.selectedUserDetail.companyname + "/" + this.props.selectedUserDetail.userid
      this.setState(_.extend(this.state.form, form))
      this.tablerender()
    }
    this.setState({ loading: false })
  }

  cancelButtonClicked() {
    this.props.compviewstatus(true)
  }

  cancelAlert() {
    this.formReset()
    this.setState({ successAlert: false })
    this.setState(initialState)
    if (this.props.fromStatements) {
      this.props.statementCompView(true)
    } else {
      this.props.reloadView(true)
    }
  }

  async tablerender() {
    let array = []
    let response = await this.props.accountGroupList()
    this.props.accountgroups && this.props.accountgroups.map((accountgrp, index) => {
      array.push(accountgrp)
    })
    this.setState({ accountGroupsList: array })
  }

  onGetEditWarningStatus() {
    this.setState({ displayErrorMessage: false, successAlert: false })
    if (this.props.fromStatements) {
      this.props.statementCompView(true)
    } else {
      this.props.reloadView(true)
    }
  }

  cancelclicked() {
    this.setState({ displayErrorMessage: false, successAlert: false })
    if (this.props.fromStatements) {
      this.props.statementCompView(true)
    } else {
      this.props.reloadView(true)
    }
  }

  render() {
    const selectRowProp = {
      mode: "checkbox",
      selected: this.state.leftcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected: true })

          //Add selected account to rightsidechekcboxList
          this.state.leftcheckboxids.push(row.id)

          //Get checked Id Details
          let selected = this.state.accountGroupsList.find((accountselected) => {
            return accountselected.id === row.id
          })
          this.state.selectedaccountgrouplist.push(selected)
        }
        else {
          //Remove Unchecked ID from List
          let ids = this.state.leftcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.leftcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.leftcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.selectedaccountgrouplist = this.state.selectedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })
        }
      }
    }

    const selectRowPropfromleft = {
      mode: "checkbox",
      selected: this.state.rightcheckboxids,
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isrowselected1: true })

          //Add selected account from rightsidechekcboxList
          this.state.rightcheckboxids.push(row.id)

          //Add removed Id details       removedaccountgrouplist
          let selected = this.state.accountAfterAddButtonClicked.find((accountselected) => {
            return accountselected.id == row.id
          })
          this.state.removedaccountgrouplist.push(selected)

          this.state.accountafterremoveButtonClicked = this.state.accountafterremoveButtonClicked.filter((accountselected) => {
            return accountselected.id !== row.id
          })

        }
        else {
          //Remove id from rightsidechekcboxList 
          let ids = this.state.rightcheckboxids.filter(function (id) {
            return id !== row.id
          })
          this.state.rightcheckboxids = []
          ids && ids.map((id, index) => {
            this.state.rightcheckboxids.push(id)
          })

          //To Remove Unchecked Id Details
          this.state.removedaccountgrouplist = this.state.removedaccountgrouplist.filter((accountselected) => {
            return accountselected.id !== row.id
          })

        }
      }
    }
    return (
      this.state.loading ? (<Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner>) :
        this.state.displayErrorMessage ? <AlertPopup getStatus={this.onGetEditWarningStatus} Message={'Selected Company Details Not Available'} /> :

          this.state.successAlert ? (<AlertPopup getStatus={this.onGetEditWarningStatus} Message={'Scheduled Successfully'} />) :

            !this.state.isMyconsoleClicked ?

              <div className="hold-transition sidebar-mini layout-navbar-fixed">


                <div className="content-wrapper">
                  <section className="content-header pb-0">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-left">
                            {this.props.fromStatements ?
                              <li className="breadcrumb-item"><a href="#">Statements</a></li> :
                              <li className="breadcrumb-item"><a href="#">Reports</a></li>
                            }
                            <li className="breadcrumb-item active">Scheduler</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="content">
                    <div className="container-fluid">
                      <div className="row">


                        <div className="col-12 dashboard-main-area">
                          <div className="card bg-black">
                            <div className="card-header card-onboarding">
                              <div className="col-md-12 action-group report-actions">
                                <button className="btn btn-default" onClick={this.cancelButtonClicked}>Cancel</button>
                                <button className="btn btn-default" onClick={this.SaveButtonClicked}>Save</button>
                              </div>
                            </div>
                            <div className="card-body p-0">
                              <div className="col-md-12 scheduler-block ml-2" style={{ width: 'calc(100% - 10px)' }}>
                                <div className="row">
                                  <div className="col-12">
                                    <h2>Scheduler</h2>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 offset-2">
                                    <p>Statement/Report Name</p>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" id="SRname" readOnly value={this.state.statementorReportfieldValue} placeholder="Position Report - Final - UserID + Date" style={{ width: 450, paddingLeft: 15 }} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 offset-2">
                                    <p>File Extension</p>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="k-form-field" >
                                      <div className="custom-control custom-radio col-md-3" style={{ float: 'left' }}>
                                        <input className="custom-control-input" name="fextdte" onChange={this.handleInputChange} type="radio" id="radiobutton1" value="B" />
                                        <label for="radiobutton1" className="custom-control-label">Business Date</label>
                                      </div>
                                      <div className="custom-control custom-radio col-md-3" style={{ float: 'left' }}>
                                        <input className="custom-control-input" name="fextdte" onChange={this.handleInputChange} type="radio" id="radiobutton2" value="S" />
                                        <label for="radiobutton2" className="custom-control-label">System Date</label>
                                      </div>
                                    </div>
                                    <div style={{ color: "red" }}>{this.state.fileextensionError}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 offset-2">
                                    <p>Date Format</p>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="k-form-field" >
                                      <div className="custom-control custom-radio col-md-3" style={{ float: 'left' }}>
                                        <input className="custom-control-input" onChange={this.handleInputChange} name="dateformat" type="radio" id="radiobutton3" value="CCYYMMDD" />
                                        <label for="radiobutton3" className="custom-control-label">CCYYMMDD</label>
                                      </div>
                                      <div className="custom-control custom-radio col-md-3" style={{ float: 'left' }}>
                                        <input className="custom-control-input" onChange={this.handleInputChange} name="dateformat" type="radio" id="radiobutton4" value="MMDDYYCC" />
                                        <label for="radiobutton4" className="custom-control-label">MMDDYYCC</label>
                                      </div>
                                      <div className="custom-control custom-radio col-md-3" style={{ float: 'left' }}>
                                        <input className="custom-control-input" onChange={this.handleInputChange} name="dateformat" type="radio" id="radiobutton5" value="DDMMCCYY" />
                                        <label for="radiobutton5" className="custom-control-label">DDMMCCYY</label>
                                      </div>
                                    </div>
                                    <div style={{ color: "red" }}>{this.state.dateformaterror}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 offset-2">
                                    <p>Delivery</p>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="custom-control custom-checkbox col-md-3" style={{ float: 'left' }}>
                                      <input className="custom-control-input" type="checkbox" onChange={this.handleInputChange1} name="email2user" id="customCheckbox1" value="Y" />
                                      <label for="customCheckbox1" className="custom-control-label">Email</label>
                                    </div>
                                    <div className="custom-control custom-checkbox col-md-3" style={{ float: 'left' }}>
                                      <input className="custom-control-input" type="checkbox" onChange={this.handleInputChange1} name="ftp2user" id="customCheckbox2" value="Y" />
                                      <label for="customCheckbox2" className="custom-control-label">Secure FTP Folder</label>
                                    </div>
                                    <div style={{ color: "red" }}>{this.state.deliveryerror}</div>
                                  </div>
                                  <div className="col-md-2 ">
                                    <p>Entitlement for User:</p>
                                  </div>
                                  <div className="col-md-2">
                                    {this.props.selectedUserDetail.userfirstname + " " + this.props.selectedUserDetail.userlastname}
                                  </div>
                                  <div className="col-md-1">
                                    <p>Company:</p>
                                  </div>
                                  <div className="col-md-2">
                                    {this.props.selectedUserDetail.companyname}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="col-md-5 pr-3 float-left ">
                                  <div className="row">
                                    <div className="col-md-12 table-header">
                                      <h6>List of Account Groups</h6>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="card-body table-responsive p-0 report-table" style={{ maxHeight: 196 }}>
                                      {!this.state.isaddbuttonClicked ?
                                        (
                                          <BootstrapTable data={this.state.accountGroupsList} selectRow={selectRowProp} striped hover>
                                            <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                          </BootstrapTable>
                                        )
                                        :
                                        (
                                          <BootstrapTable data={this.state.accountGroupsList} selectRow={selectRowProp} striped hover>
                                            <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                          </BootstrapTable>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 pl-2 pr-2 float-left add-group-form">
                                  <div className="row">
                                    <div className="card bg-black col-md-12 block-right add-user-form" style={{ maxHeight: 250 }}>
                                      <div className="col-md-12 table-header" style={{ height: 36, borderBottom: 'solid 1px rgba(255,255,255,0.25)' }}>
                                        <h6 style={{ textAlign: 'center' }}>Add or Remove</h6>
                                      </div>
                                      <div className="card-body">
                                        <form>
                                          <div className="row">
                                            <div className="col-md-12 forward-buttons">
                                              <span
                                                className="arrow-btn"
                                                onClick={this.addButtonClicked}
                                              >
                                                <i className="fas fa-angle-right"></i>
                                              </span>
                                            </div>
                                            <div className="col-md-12 forward-buttons">
                                              <span
                                                className="arrow-btn"
                                                onClick={this.removebuttonclicked}
                                              >
                                                <i className="fas fa-angle-left"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-5 pl-3 float-left add-user-form pr-0">
                                  <div className="row">
                                    <div className="col-md-12 table-header">
                                      <h6>Selected Account Groups</h6>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="card-body table-responsive p-0 report-table" style={{ maxHeight: 196 }}>
                                      {this.state.isaddbuttonClicked ?
                                        this.state.isremovebuttonclicked ?
                                          <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropfromleft} striped hover>
                                            <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                          </BootstrapTable>
                                          :
                                          <BootstrapTable data={this.state.accountAfterAddButtonClicked} selectRow={selectRowPropfromleft} striped hover>
                                            <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                            <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                          </BootstrapTable>
                                        :
                                        <BootstrapTable data={this.state.emptyarray} striped hover>
                                          <TableHeaderColumn isKey dataField='id' hidden >ID</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='actgroupid' filter={{ type: 'TextFilter', placeholder: 'Account Group Name' }}>Account Group Name</TableHeaderColumn>
                                          <TableHeaderColumn width={"200px"} dataField='companyname' filter={{ type: 'TextFilter', placeholder: 'Company Name' }}>Company Name</TableHeaderColumn>
                                        </BootstrapTable>
                                      }
                                      <div className="col-md-6" style={{ color: "red" }}>{this.state.accountgrperror}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              :
              <S3Objects />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    accountgroups: state.csr.accountgrouplist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    AddScheduler: (formData) => {
      return dispatch(AddScheduler(formData))
    },
    accountGroupList: () => {
      return dispatch(accountGroupList())
    },
    companyBycompanyname: (company) => {
      return dispatch(companyBycompanyname(company))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Scheduler)