import React from "react";
import autoBind from "react-autobind";
import _ from "lodash";
import { UpdateActiveStatusforAG, getselectedaccbygroup, availableaccountList, getAllAccList } from '../../../../actions/csrAccountGroupAction.js';
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";


class accountGroupDetails extends React.Component {
  state = {
    ids: [],
    selectedaccounts: [],
    ApprovedDate: null,
    UpdatedDate: null,
    OnboardedDate: null,
    form: {
      active: null,
      id: this.props.formDetails.id,
      actgroupid: this.props.formDetails.actgroupid,
      companyname: this.props.formDetails.companyname
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
    
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState(_.extend(this.state.form, form));
    this.onupdateButttonClick();
  }

  async onupdateButttonClick() {
    let response = await this.props.UpdateActiveStatusforAG(this.state.form);
    if (response && response.status === 200) {
      this.setState({ successMessage: "Updated Successfully", successAlert: true });
      window.location.reload(false);
    }
  }

  async componentDidMount() {
    if (this.props.formDetails.ApprovedDate !== null) {
      let aprdte = this.props.formDetails.ApprovedDate.replace('T', ' ')
      this.state.ApprovedDate = aprdte.replace('.000Z', '')
    }
    if (this.props.formDetails.UpdatedDate !== null) {
      let updte = this.props.formDetails.UpdatedDate.replace('T', ' ')
      this.state.UpdatedDate = updte.replace('.000Z', '')
    }
    if (this.props.formDetails.OnboardedDate !== null) {
      let onboarddte = this.props.formDetails.OnboardedDate.replace('T', ' ')
      this.state.OnboardedDate = onboarddte.replace('.000Z', '')
    }

    this.state.ids.length = 0;
    let response = await this.props.getselectedaccbygroup(this.state.form);
    if (response && response.status === 200) {
      response.data.body &&
        response.data.body.map((accounts, index) => {
        }
        );
    }

    await this.props.getselectedaccbygroup(this.state.form);
    if (response && response.status === 200) {
      response.data.body &&
        response.data.body.map((accounts, index) => {
          this.state.ids.push(accounts.fkactgroupid);
          this.state.selectedaccounts.push(accounts);
        })
    }

    let form = {};
    form["id"] = this.props.formDetails.id;
    form["active"] = this.state.form.active;
    this.props.senddata(this.state.ids);
    this.props.senddata1(this.state.selectedaccounts);

  }

  async markSelected(e) {
  }


  selectedaccbygroup() {
    return (
      this.props.getselectedaccounts &&
      this.props.getselectedaccounts.map((accounts, index) => {
        return (
          <tr key={index}>
            <td>{accounts.firm}</td>
            <td>{accounts.office}</td>
            <td>{accounts.account}</td>
            <td>{accounts.shortname}</td>
          </tr>
        );
      })
    );
  }

  render() {
    return (
      <div className="hold-transition sidebar-mini layout-navbar-fixed">
        <div className="wrapper">
          <div className="col-md-6 float-left">
            <div className="row">
              <div className="col-md-12 table-header">
                <h6>Accounts</h6>
              </div>
            </div>
            <div className="row">
              <div className="card-body table-responsive p-0 AccountDetails" style={{ maxHeight: 150 }}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                    <tr>
                      <th>Firm</th>
                      <th>Office</th>
                      <th>Account no</th>
                      <th>Account short name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.selectedaccbygroup()}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="card bg-black col-md-12 block-right" style={{ MaxHeight: 120, marginTop: 15 }}>
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 form-title">Active</div>
                      {(this.props.formDetails.Created_User_Mail_ID === localStorage.getItem('useremailid')) ?
                        <div className="col-md-9 form-value">
                          {this.props.formDetails.active}
                        </div>
                        :
                        <div className="col-md-9 form-value">
                          <select name="active" value={this.props.formDetails.active} onChange={this.handleInputChange}>
                            <option>YES</option>
                            <option>NO</option>
                          </select>
                        </div>}
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Onboarded By:</div>
                      <div className="col-md-9 form-value">{this.props.formDetails.Created_User_Name}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Onboarded Date:</div>
                      <div className="col-md-9 form-value">{this.state.OnboardedDate}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Approved By:</div>
                      <div className="col-md-9 form-value">{this.props.formDetails?.Certified_User_Name !== 'undefined undefined' ? this.props.formDetails.Certified_User_Name : ''}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Approved Date:</div>
                      <div className="col-md-9 form-value">{this.state.ApprovedDate}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Updated By:</div>
                      <div className="col-md-9 form-value">{this.props.formDetails?.Updated_User_Name !== 'undefined undefined' ? this.props.formDetails.Updated_User_Name : ''}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 form-title">Updated Date:</div>
                      <div className="col-md-9 form-value">{this.state.UpdatedDate}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    getselectedaccounts: state.csr.getselectedaccountgroup,
    accountslist: state.csr.availableaccountList,
    getallaccountlist: state.csr.getAllAccList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateActiveStatusforAG: (formData) => {
      return dispatch(UpdateActiveStatusforAG(formData));
    },
    getselectedaccbygroup: (formdata) => {
      return dispatch(getselectedaccbygroup(formdata));
    },
    availableaccountList: () => {
      return dispatch(availableaccountList());
    },
    getAllAccList: () => {
      return dispatch(getAllAccList());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(accountGroupDetails);