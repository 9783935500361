import React from 'react'
import { userEdit, userActiveUpdate } from '../../../../actions/csrUserAction.js'
import autoBind from "react-autobind"
import { connect } from "react-redux"
import _ from "lodash"
import jwtDecode from "jwt-decode";


class userDetails extends React.Component {
  state = {
    onboardeddate: null,
    certifieddate: null,
    updatedate: null,
    form: {
      active: null,
      id: this.props.formDetails.id,
    }
  }

  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  handleInputChange(e) {
    let form = {}
    form[e.target.name] = e.target.value
    this.setState(_.extend(this.state.form, form))
    this.onActiveStatsUpdate()
  }

  async onActiveStatsUpdate() {
    let response = await this.props.userActiveUpdate(this.state.form)
    if (response && response.status === 200) {
      this.setState({ successMessage: "Updated Successfully" })
      this.setState({ successAlert: true })
      window.location.reload(false)
    }
  }

  async onupdateButttonClick() {
    let response = await this.props.userEdit(this.state.form)
    if (response && response.status === 200) {
      this.setState({ successMessage: "Updated Successfully" })
      this.setState({ successAlert: true })
      window.location.reload(false)
    }
  }

  async componentDidMount() {

    if (this.props.formDetails.onboardeddate !== null) {
      let onboardDate = this.props.formDetails.onboardeddate.replace('T', ' ')
      this.setState({ onboardeddate: onboardDate.replace('.000Z', '') })
    }
    if (this.props.formDetails.certifieddate !== null) {
      let approveDate = this.props.formDetails.certifieddate.replace('T', ' ')
      this.setState({ certifieddate: approveDate.replace('.000Z', '') })
    }
    if (this.props.formDetails.updatedate !== null) {
      let updatedate = this.props.formDetails.updatedate.replace('T', ' ')
      this.setState({ updatedate: updatedate.replace('.000Z', '') })
    }

    let form = {}
    form["id"] = this.props.formDetails.id
    form["active"] = this.state.form.active
  }


  render() {
    return (
      <div className="col-md-5 pl-3 float-left">
        <div className="row">
          <div className="card bg-black col-md-12 block-right" style={{ MaxHeight: 440 }}>
            <div className="card-header">
              <h5 className="m-0">User Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 form-title">First Name:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.userfirstname}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Last Name:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.userlastname}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">User Type:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.usertp}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Email:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.useremailid}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Telephone:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.userphonenumer}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">User Country:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.usercountry}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">User Region:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.userregion}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Company Name:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.companyname}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Account Group:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.accountgroup}</div>
              </div>
              <div className="row">
                <div className="col-md-3 form-title">Notes:</div>
                <div className="col-md-9 form-value">{this.props.formDetails.note}</div>

                <div className="status-box col-md-12">
                  <div className="row">
                    <div className="col-md-3 form-title">Active</div>
                    {(this.props.formDetails.Created_User_Mail_ID === localStorage.getItem('useremailid')) ?
                      <div className="col-md-9 form-value">
                        {this.props.formDetails.active}
                      </div>
                      :
                      <div className="col-md-9 form-value">
                        <select name="active" value={this.props.formDetails.active} onChange={this.handleInputChange}>
                          <option>YES</option>
                          <option>NO</option>
                        </select>
                      </div>}
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Onboarded By:</div>
                    <div className="col-md-9 form-value">{this.props.formDetails.Created_User_Name}</div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Onboarded Date:</div>
                    <div className="col-md-9 form-value">{this.state.onboardeddate}</div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Approved By:</div>
                    <div className="col-md-9 form-value">{this.props.formDetails?.Certified_User_Name !== 'undefined undefined' ? this.props.formDetails.Certified_User_Name : ''} 
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Approved Date:</div>
                    <div className="col-md-9 form-value">{this.state.certifieddate}</div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Updated By:</div>
                    <div className="col-md-9 form-value">{this.props.formDetails?.Updated_User_Name !== 'undefined undefined' ? this.props.formDetails.Updated_User_Name : ''} 
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 form-title">Updated Date:</div>
                    <div className="col-md-9 form-value">{this.state.updatedate}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Companies: state.csr.companiesList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userEdit: (formData) => {
      return dispatch(userEdit(formData))
    },
    userActiveUpdate: (formData) => {
      return dispatch(userActiveUpdate(formData))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(userDetails)