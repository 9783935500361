import axios from 'axios'
import config from './config'

export const relationshipList = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'relationship-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'RELATIONSHIP_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const relationshipAdd = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-relationship', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'RELATIONSHIP_ADD_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const relationshipEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-relationship', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'RELATIONSHIP_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const relationshipById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-relationship/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'RELATIONSHIP_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const relationshipDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-relationship', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'RELATIONSHIP_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}