import React from 'react'
import { schedulerList, AccountGroupListfromScheduler, DeleteScheduler } from "../../actions/schedulerAction"
import { connect } from "react-redux"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import _ from "lodash"
import SchedulerDetails from './SchedulerDetails'
import WarningPopup from '../AlertPopup/alertpopupDesign'
import AlertPopup from '../AlertPopup/popupDesign'
import autoBind from "react-autobind"
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";




class ListofSchedulers extends React.Component {

    state = {
        loading: false,
        warningAlert: false,
        SentMessage: null,
        warningMessage: null,
        SchedulerList: [],
        isCheckboxClicked: false,
        ActgrpList: [],
        form: {
            USERID: null,
            USERFIRSTNAME: null,
            USERLASTNAME: null,
            USEREMAILID: null,
            COMPANYNAME: null,
            COMPANYID: null,
            RPTSTMID: null,
            RPTSTMNAME: null,
            DATEFORMAT: null,
            EMAIL2USER: null,
            FTP2USER: null,
            FTPT2FOLDER: null
        }
    }

    constructor(props) {
        super(props)
        autoBind(this)

        let expirationTime
        let token = localStorage.getItem('accessToken')
        if (token !== null) {
          const { exp } = jwtDecode(token)
          expirationTime = (exp * 1000)
          if (Date.now() >= expirationTime) {
            localStorage.clear();
            window.location.reload('/')
          }
        }
    }


    removeData() {
        this.setState({ warningMessage: 'Are you sure want to Delete this Scheduler List' })
        this.state.isCheckboxClicked ? this.setState({ warningAlert: true })
            : this.setState({ warningAlert: false })
    }


    async componentDidMount() {
        let array = []

        this.setState({ loading: true })

        let response = await this.props.schedulerList()

        response.data.body && response.data.body.map((data, index) => {
            array.push(data)
        })

        this.setState({ SchedulerList: array, loading: false})
    }

    onGetWarningStatus() {
        this.setState({ warningAlert: false })
        this.deleteRecord()
    }

    ongetstatus() {
        this.setState({ successAlert: false })
    }

    async deleteRecord() {
        this.setState({ SentMessage: 'Schedules Deleted Successfully' })
        this.setState({ warningAlert: false })
        let response = await this.props.DeleteScheduler(this.state.form)
        if (response && response.status !== undefined && response.status === 200) {
            this.setState({ successAlert: true })
            let array = []

            let response = await this.props.schedulerList()

            response.data.body && response.data.body.map((data, index) => {
                array.push(data)
            })

            this.setState({ SchedulerList: array })
        }
    }

    render() {
        const selectRowProp = {
            mode: "radio",
            onSelect: async (row, isSelect, rowIndex, e) => {

                let form = {}
                form["COMPANYID"] = row.COMPANYID
                form["RPTSTMID"] = row.RPTSTMID
                form["DATEFORMAT"] = row.DATEFORMAT
                form["RPTSTMNAME"] = row.RPTSTMNAME
                form["FTP2USER"] = row.FTP2USER
                form["EMAIL2USER"] = row.EMAIL2USER
                form["COMPANYNAME"] = row.COMPANYNAME
                form["USEREMAILID"] = row.USEREMAILID
                form["USERLASTNAME"] = row.USERLASTNAME
                form["USERFIRSTNAME"] = row.USERFIRSTNAME
                form["USERID"] = row.USERID
                form["FTPT2FOLDER"] = row.FTPT2FOLDER
                this.setState(_.extend(this.state.form, form))

                let response = await this.props.AccountGroupListfromScheduler(this.state.form)
                this.state.ActgrpList = []
                response.data.body && response.data.body.map((data, index) => {
                    this.state.ActgrpList.push(data)
                })
                this.setState({
                    isCheckboxClicked: true,
                })
            },
        }

        return (
            this.state.warningAlert ? (<WarningPopup alertType={'warningAlert'} getStatus={this.onGetWarningStatus} Message={this.state.warningMessage} cancelStatus={this.cancelclicked} />) :
                this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={this.state.SentMessage} />) :

                    <div className="hold-transition sidebar-mini layout-navbar-fixed">
                        <div className="wrapper">
                            <div className="content-wrapper">
                                <section className="content-header">
                                    <div className="container-fluid">
                                        <div className="row mb-2">
                                            <div className="col-sm-4">
                                                <ol className="breadcrumb float-sm-left">
                                                    <li className="breadcrumb-item">
                                                        <a href="#">CSR</a>
                                                    </li>
                                                    <li className="breadcrumb-item active">
                                                        List of Schedulers
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="content">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card bg-black csr-onboarding">
                                                    <div className="card-header card-onboarding">
                                                        <div className="card-tools col-md-12 pl-0">
                                                            <div className="top-button-group row">
                                                                <button
                                                                    onClick={this.removeData}
                                                                    className="btn btn-default"
                                                                >
                                                                    Delete
                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 pl-0 pr-0">
                                                        <div className="col-md-9 float-left">
                                                            <div className="row">
                                                                <div className="col-md-12 table-header">
                                                                    <h6>List of Schedulers</h6>
                                                                </div>
                                                            </div>
                                                            {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                                                                <div className="row rowCompanyGrid">
                                                                    <div className="card-body table-responsive schedulerList p-0">
                                                                        <BootstrapTable
                                                                            data={this.state.SchedulerList}
                                                                            selectRow={selectRowProp}
                                                                            striped
                                                                            hover
                                                                            ref={this.onBootstrapTableRef}
                                                                        >
                                                                            <TableHeaderColumn
                                                                                hidden
                                                                                isKey
                                                                                dataField="id"
                                                                            >
                                                                                ID
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"90px"}
                                                                                dataField="USERID"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "User ID",
                                                                                }}
                                                                            >
                                                                                User ID
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"125px"}
                                                                                dataField="USERFIRSTNAME"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "First Name",
                                                                                }}
                                                                            >
                                                                                User First Name
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"135px"}
                                                                                dataField="USERLASTNAME"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "Last Name",
                                                                                }}
                                                                            >
                                                                                User Last Name
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"225px"}
                                                                                dataField="COMPANYNAME"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "Compamy Name",
                                                                                }}
                                                                            >
                                                                                Company Name
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"60px"}
                                                                                dataField="RPTSTMID"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "Report ID",
                                                                                }}
                                                                            >
                                                                                Report ID
                                        </TableHeaderColumn>
                                                                            <TableHeaderColumn
                                                                                width={"225px"}
                                                                                dataField="RPTSTMNAME"
                                                                                Style={{ whiteSpace: 'nowrap' }}
                                                                                filter={{
                                                                                    type: "TextFilter",
                                                                                    placeholder: "Report name",
                                                                                }}
                                                                            >
                                                                                Report name
                                        </TableHeaderColumn>
                                                                        </BootstrapTable>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {this.state.isCheckboxClicked ?
                                                            <SchedulerDetails formDetails={this.state.form}
                                                                tableData={this.state.ActgrpList} />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        getSchedulerList: state.csr.getSchedulerList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        schedulerList: () => {
            return dispatch(schedulerList())
        },
        AccountGroupListfromScheduler: (formdata) => {
            return dispatch(AccountGroupListfromScheduler(formdata))
        },
        DeleteScheduler: (formdata) => {
            return dispatch(DeleteScheduler(formdata))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListofSchedulers)