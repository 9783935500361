import React from "react";
import { connect } from "react-redux";
import {
  intradaytradeList,
  intradayPositionList,
  companyaccountList,
  accountgroupselection,
  intradaytradeaccountList,
  intradayPositionaccountList,
} from "../../actions/IntraTradePositionAction.js";
import autoBind from "react-autobind";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"


class IntradayTradeandPositions extends React.Component {
  state = {
    IntradayData: [],
    IntraDaypositionData: [],
    filename: "Intraday Trades - " + Date().toLocaleString() + ".csv",
    selectedaccounts: [],
    isLoadbuttonclicked: false,
  };

  constructor(props) {
    super(props);
    autoBind(this)


    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }

    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
    };
  }

  onOpenModal() {
    this.formReset();
    this.setState({ showAddModal: true });
  }

  async componentDidMount() {
    this.setState({ loading: true, selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    });
  }

  sizePerPageListChange(sizePerPage) {
    alert(`sizePerPage: ${sizePerPage}`);
  }

  onPageChange(page, sizePerPage) {
    alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
  }

  async getloaddata() {

    this.setState({ loading: true })

    this.setState({ selectedaccounts: this.props.loadData, isLoadbuttonclicked: true }, async () => {

      let array = []

      let array1 = []

      let traderesponse = await this.props.intradaytradeaccountList(
        this.state.selectedaccounts
      );

      traderesponse.data.body && traderesponse.data.body.map((data, index) => {
        array.push(data)
      })

      this.setState({ IntradayData: array })

      let positionresponse = await this.props.intradayPositionaccountList(
        this.state.selectedaccounts
      );

      positionresponse.data.body && positionresponse.data.body.map((data, index) => {
        array1.push(data)
      })

      this.setState({ IntraDaypositionData: array1 })

      this.setState({ loading: false })

    });
  }

  async getloadfulldata() {

    await this.props.getstatus()

    this.setState({ loading: true, selectedaccounts: this.props.loadData }, () => {
      this.getloaddata();
    });
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <>
        <span
          className="tablehead-buttonlinks col-12 float-left"
          style={{ alignItems: "right" }}
        >
          <div className="actionbuttons">
            <button
              className="btn btn-dark btn-load"
              onClick={this.getloaddata}
            >
              Load
            </button>
            <button
              className="btn btn-dark btn-refresh"
              onClick={this.getloadfulldata}
            >
              Refresh
            </button>
            <button className="btn btn-dark" onClick={onClick}>
              Export
            </button>
          </div>
        </span>
      </>
    );
  };

  IntradayTradesClick() {
    this.setState({
      filename: "Intraday Trades - " + Date().toLocaleString() + ".csv",
    });
  }

  IntradayPositionsClick() {
    this.setState({
      filename: "Intraday Positions - " + Date().toLocaleString() + ".csv",
    });
  }

  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
      expandBy: "column",
      sizePerPage: 15,
      sizePerPageList: [{
        text: '15', value: 15
      }]
    };

    function colorstyle(fieldValue, row, rowIdx) {
      return { color: fieldValue < 0 ? "red" : "white" };
    }

    return (
      this.state.loading ? (<div className="d-inline-block dashboard"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
        <div className="col-12 dashboard-main-area">
          <div className="card bg-black">
            <div className="card-body p-0">
              <ul
                className="nav nav-tabs float-left"
                id="custom-content-below-tab"
                role="tablist"
                style={{ width: "100%" }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-content-below-trade-tab"
                    data-toggle="pill"
                    href="#custom-content-below-trade"
                    role="tab"
                    aria-controls="custom-content-below-trade"
                    aria-selected="true"
                    onClick={this.IntradayTradesClick}
                  >
                    Trades
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-content-below-position-tab"
                    data-toggle="pill"
                    href="#custom-content-below-position"
                    role="tab"
                    aria-controls="custom-content-below-position"
                    aria-selected="false"
                    onClick={this.IntradayPositionsClick}
                  >
                    Positions
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="custom-content-below-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-content-below-trade"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-trade-tab"
                >
                  <div
                    className="card-body tradegridalignment table-responsive p-0"
                    style={{ MaxHeight: 404 }}
                  >
                    {!this.state.isLoadbuttonclicked ? (
                      <BootstrapTable
                        data={this.state.IntradayData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="gdaydate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                          className="griddatebind"
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                          className="gridfirmbind"
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="shortname"
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                          tdStyle={colorstyle}
                        >
                          QTY
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          dataField="subcusip"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="strikeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="tradeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="status1"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Status",
                          }}
                        >
                          Status
                        </TableHeaderColumn>
                      </BootstrapTable>
                    ) : (
                      <BootstrapTable
                        data={this.state.IntradayData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="gdaydate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                          className="griddatebind"
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                          className="gridfirmbind"
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="shortname"
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                          tdStyle={colorstyle}
                        >
                          QTY
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          dataField="subcusip"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="strikeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="tradeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="status1"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Status",
                          }}
                        >
                          Status
                        </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-content-below-position"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-position-tab"
                >
                  <div
                    className="card-body tradegridalignment table-responsive p-0"
                    style={{ MaxHeight: 400 }}
                  >
                    {!this.state.isLoadbuttonclicked ? (
                      <BootstrapTable
                        data={this.state.IntraDaypositionData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="gdaydate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                          className="griddatebind"
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                          className="gridfirmbind"
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="shortname"
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                          tdStyle={colorstyle}
                        >
                          QTY
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          dataField="subcusip"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="strikeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="tradeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="status1"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Status",
                          }}
                        >
                          Status
                        </TableHeaderColumn>
                      </BootstrapTable>
                    ) : (
                      <BootstrapTable
                        data={this.state.IntraDaypositionData}
                        options={options}
                        exportCSV
                        csvFileName={this.state.filename}
                        pagination
                      >
                        <TableHeaderColumn isKey dataField="id" hidden>
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="gdaydate"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Business Date",
                          }}
                          className="griddatebind"
                        >
                          Business Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"45px"}
                          Style={{ "minWidth": "45px" }}
                          dataField="firmid"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Firm",
                          }}
                          className="gridfirmbind"
                        >
                          Firm
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="officeno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Office",
                          }}
                        >
                          Office
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="accountno"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Account",
                          }}
                        >
                          Account
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="shortname"
                          width={"160px"}
                          Style={{ "minWidth": "160px" }}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Short name",
                          }}
                        >
                          Short name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"70px"}
                          Style={{ "minWidth": "70px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Contract",
                          }}
                        >
                          Contract
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"40px"}
                          Style={{ "minWidth": "40px" }}
                          dataField="buysell"
                          filter={{
                            type: "TextFilter",
                            placeholder: "B/S",
                          }}
                        >
                          B/S
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"85px"}
                          Style={{ "minWidth": "85px" }}
                          dataField="quantity"
                          filter={{
                            type: "TextFilter",
                            placeholder: "QTY",
                          }}
                          tdStyle={colorstyle}
                        >
                          QTY
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"75px"}
                          Style={{ "minWidth": "75px" }}
                          dataField="exchangecode"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Exchange",
                          }}
                        >
                          Exchange
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractmm"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Month",
                          }}
                        >
                          Month
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"60px"}
                          Style={{ "minWidth": "60px" }}
                          dataField="contractccyy"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Year",
                          }}
                        >
                          Year
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          width={"65px"}
                          Style={{ "minWidth": "65px" }}
                          dataField="subcusip"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Prompt",
                          }}
                        >
                          Prompt
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"50px"}
                          Style={{ "minWidth": "50px" }}
                          dataField="subtype"
                          filter={{
                            type: "TextFilter",
                            placeholder: "P/C",
                          }}
                        >
                          P/C
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="strikeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Strike Price",
                          }}
                        >
                          Strike Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="tradeprice"
                          tdStyle={colorstyle}
                          filter={{
                            type: "TextFilter",
                            placeholder: "Trade Price",
                          }}
                        >
                          Trade Price
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width={"90px"}
                          Style={{ "minWidth": "90px" }}
                          dataField="status1"
                          filter={{
                            type: "TextFilter",
                            placeholder: "Status",
                          }}
                        >
                          Status
                        </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intradaytrade: state.csr.intradaytradelist,
    intradayposition: state.csr.intradaypositionlist,
    companyaccount: state.csr.companyaccountList,
    accountgroup: state.csr.accountgroupselection,
    selectedintradaytrade: state.csr.intradaytradeaccountList,
    selectedintradayposition: state.csr.intradayPositionaccountList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    intradaytradeList: () => {
      return dispatch(intradaytradeList());
    },
    intradayPositionList: () => {
      return dispatch(intradayPositionList());
    },
    companyaccountList: () => {
      return dispatch(companyaccountList());
    },
    accountgroupselection: () => {
      return dispatch(accountgroupselection());
    },
    intradaytradeaccountList: (formData) => {
      return dispatch(intradaytradeaccountList(formData));
    },
    intradayPositionaccountList: (formData) => {
      return dispatch(intradayPositionaccountList(formData));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntradayTradeandPositions);
