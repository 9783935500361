import autoBind from "react-autobind"
import {
  companiesList,
  companyDelete,
  companyById,
  CopmpanyCreatedUserMailId,
  CopmpanyCertifiedUserMailId,
  CopmpanyUpdatedUserMailId,
} from "../../../../actions/csrCompaniesAction.js"
import { connect } from "react-redux"
import AddCompanyDetails from "./addCompanyDetails.jsx"
import EditCompaniesDetails from "./editCompaniesDetails.jsx"
import CompanyDetails from "./companiesDetails.jsx"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import _ from "lodash"
import { Link } from 'react-router-dom'
import WarningPopup from '../../../AlertPopup/alertpopupDesign'
import AlertPopup from '../../../AlertPopup/popupDesign'
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";
var React = require("react")

class companies extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)

    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }

    this.state = {
      companyAvailability: [],
      loading: false,
      CompanygridData: null,
      noofcheckboxselected: 0,
      editbuttonclicked: false,
      isCheckBoxClicked: false,
      ListofCompanies: [],
      showcomponent: false,
      showeditComponent: false,
      showcompanyDetails: false,
      warningAlert: false,
      warningMessage: null,
      successAlert: false,
      successMessage: null,
      rowIds: [],
      form: {
        Created_User_Name: null,
        Updated_User_Name: null,
        Certified_User_Name: null,
        Created_User_Mail_ID: null,
        id: null,
        companyname: null,
        companyID: null,
        entitytype: this.props.entity,
        country: null,
        region: null,
        companytier: null,
        domain: null,
        swiftcode: null,
        note: null,
        safedomain1: null,
        safedomain2: null,
        safedomain3: null,
        safedomain4: null,
        safedomain5: null,
        OnboardedBy: null,
        OnboardedDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        UpdatedBy: null,
        UpdatedDate: null,
        Active: null,
      },
    }
    this.onBootstrapTableRef = this.onBootstrapTableRef.bind(this)
  }

  clearSelectedRow() {
    this.bootstrapTableRef.cleanSelected()
  }

  onBootstrapTableRef(instance) {
    this.bootstrapTableRef = instance
  }

  async componentDidMount() {
    let array = []
    let response = await this.props.companiesList()
    response.data.body && response.data.body.map((data, index) => {
      array.push(data)
      this.state.companyAvailability.push(data.companyname)
    })
    this.setState({ CompanygridData: array })
  }

  async getstatus(_status) {
    this.setState({ showcomponent: false })
    this.setState({ showeditComponent: false })
    this.state.companyAvailability = []
    let array = []
    let response = await this.props.companiesList()
    response.data.body && response.data.body.map((data, index) => {
      array.push(data)
      this.state.companyAvailability.push(data.companyname)
    })
    this.setState({ CompanygridData: array })
  }

  removeData() {
    this.state.rowIds.length
      ? this.setState({ warningAlert: true })
      : this.setState({ warningAlert: false })
  }

  getcheckedStatus() {
    this.clearSelectedRow()
  }

  async getCompaniesforEdit(status) {
    this.setState({ successMessage: 'Company Details Edited Successfully' })
    this.setState({ showcomponent: false })
    this.setState({ isCheckBoxClicked: false })
    this.setState({ showeditComponent: false })
    this.setState({ successAlert: true })
    await this.props.companiesList()
  }

  async getCompanies(status) {
    let array = []
    this.setState({ successMessage: 'Company Details Added Successfully' })
    this.setState({ showcomponent: false })
    this.setState({ isCheckBoxClicked: false })
    this.setState({ showeditComponent: false })
    this.setState({ successAlert: true })
    let response = await this.props.companiesList()
    this.state.companyAvailability = []
    response.data.body && response.data.body.map((data, index) => {
      array.push(data)
      this.state.companyAvailability.push(data.companyname)
    })
    this.setState({ CompanygridData: array, companyAvailability: array })
  }

  addButtonClick() {
    this.setState({ showcomponent: true })
    this.setState({ showeditComponent: false })
    this.setState({ showcompanyDetails: false })
  }

  editButtonClick() {
    this.setState({ showcomponent: false })
    this.setState({ showcompanyDetails: false })
    if (this.state.noofcheckboxselected === 0) {
      this.setState({ editbuttonclicked: true })
    }
    else {
      this.setState({ showeditComponent: true })
    }
  }

  async deleteRecord() {
    this.setState({ warningAlert: false })
    let response = await this.props.companyDelete(this.state.rowIds)
    if (response && response.status === 200) {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false))
      this.setState({
        successMessage: "Company Details Deleted Successfully",
        successAlert: true,
        noofcheckboxselected: 0,
      })
      let companydata = await this.props.companiesList()
      let array = []
      companydata.data.body && companydata.data.body.map((data, index) => {
        array.push(data)
      })
      this.setState({ CompanygridData: array, companyAvailability: array })
      this.state.rowIds = []
    }
  }

  cancelAlert() {
    this.setState({ warningAlert: false })
    this.setState({ successAlert: false })
  }

  onGetWarningStatus() {
    this.setState({ warningAlert: false, isCheckBoxClicked: false })
    this.deleteRecord()
  }


  cancelclicked() {
    this.setState({ warningAlert: false, isCheckBoxClicked: false, showeditComponent: false, noofcheckboxselected: 0 })
    this.state.rowIds = []
  }

  ongetstatus() {
    this.setState({ successAlert: false, editbuttonclicked: false })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect) => {
        if (isSelect === true) {
          this.setState({
            isCheckBoxClicked: false,
            showcompanyDetails: false,
          })
          this.state.form.Created_User_Mail_ID = []
          this.state.form.Created_User_Name = []
          this.state.form.Updated_User_Name = []
          this.state.form.Certified_User_Name = []
          this.state.form.OnboardedDate = []
          this.state.form.UpdatedDate = []
          this.state.form.ApprovedDate = []

          this.setState({
            noofcheckboxselected: this.state.noofcheckboxselected + 1,
          })

          this.state.rowIds = []
          this.state.rowIds.push(row.id)
          let response = await this.props.companyById(row.id)

          let getCreatedUserMailID = await this.props.CopmpanyCreatedUserMailId(
            row.id
          )
          let getUpdatedUserName = await this.props.CopmpanyUpdatedUserMailId(
            row.id
          )
          let getCertifiedUserName = await this.props.CopmpanyCertifiedUserMailId(
            row.id
          )
          let form = []
          if (getCreatedUserMailID !== undefined && getCreatedUserMailID.data.body !== null) {
            form["Created_User_Mail_ID"] = getCreatedUserMailID.data.body.useremailid
            form["Created_User_Name"] = getCreatedUserMailID.data.body.userfirstname + " " + getCreatedUserMailID.data.body.userlastname
          }
          if (getUpdatedUserName !== undefined && getUpdatedUserName.data.body !== null) {
            form["Updated_User_Name"] = getUpdatedUserName.data.body.userfirstname + " " + getUpdatedUserName.data.body.userlastname
          }
          if (getCertifiedUserName !== undefined && getCertifiedUserName.data.body !== null) {
            form["Certified_User_Name"] = getCertifiedUserName.data.body.userfirstname + " " + getCertifiedUserName.data.body.userlastname
          }

          this.setState(_.extend(this.state.form, form))

          if (response && response.status === 200) {

            this.state.form.Active = response.data.body.active
            this.state.form.companyID = response.data.body.companyid
            this.state.form.companyname = response.data.body.companyname
            this.state.form.entitytype = response.data.body.entitytype
            this.state.form.country = response.data.body.country
            this.state.form.region = response.data.body.region
            this.state.form.companytier = response.data.body.companytier
            this.state.form.safedomain1 = response.data.body.safedomain1
            this.state.form.safedomain2 = response.data.body.safedomain2
            this.state.form.safedomain3 = response.data.body.safedomain3
            this.state.form.safedomain4 = response.data.body.safedomain4
            this.state.form.safedomain5 = response.data.body.safedomain5
            this.state.form.swiftcode = response.data.body.swiftcode
            this.state.form.note = response.data.body.note
            this.state.form.OnboardedDate = response.data.body.onboardeddate
            this.state.form.UpdatedDate = response.data.body.updatedate
            this.state.form.ApprovedDate = response.data.body.certifieddate
            this.state.form.id = response.data.body.id

            this.setState({
              isCheckBoxClicked: true,
              showcompanyDetails: true,
              showcomponent: false,
              showeditComponent: false,
              loading: false
            })
          }
        } else {
          this.setState({
            noofcheckboxselected: this.state.noofcheckboxselected - 1,
          })
          this.state.isCheckBoxClicked = false
          let ids = this.state.rowIds.filter(function (id) {
            return id !== row.id
          })
          this.setState({ rowIds: ids })
        }
      },
    }
    return (
      this.state.warningAlert ? (<WarningPopup alertType={'warningAlert'} getStatus={this.onGetWarningStatus} Message={'Are You sure want to delete this company details'} cancelStatus={this.cancelclicked} />) :
        this.state.successAlert ? (<AlertPopup getStatus={this.ongetstatus} Message={this.state.successMessage} />) :
          <div className="hold-transition sidebar-mini layout-navbar-fixed">
            {this.state.editbuttonclicked ? (<AlertPopup getStatus={this.ongetstatus} Message="Please select any Company" />) : ("")}
            <div className="wrapper">
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                        <ol className="breadcrumb float-sm-left">
                          <li className="breadcrumb-item">
                            <a href="#">CSR</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Client Onboarding</a>
                          </li>
                          <li className="breadcrumb-item active">Companies</li>
                        </ol>
                      </div>

                      <div className="col-sm-5">
                        <div className="card-head p-0">
                          <ul className="dash-sidebar-menu float-sm-left">
                            <li className="dash-sidebar-item active">
                              <Link to="/dashboard/csr-CsrCompanies" className="nav-link">Companies</Link>
                            </li>
                            <li className="dash-sidebar-item">
                              <Link to="/dashboard/csr-AccountGroup" className="nav-link">Account Groups</Link>
                            </li>
                            <li className="dash-sidebar-item">
                              <Link to="/dashboard/csr-Users" className="nav-link">Users</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="card bg-black csr-onboarding">
                          <div className="card-header card-onboarding">
                            <div className="card-tools col-md-12 pl-0">
                              <div className="top-button-group row">
                                <a
                                  className="btn btn-default add-user-btn"
                                  onClick={this.addButtonClick}
                                >
                                  Add
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-default add-user-btn"
                                  onClick={this.editButtonClick}
                                >
                                  Edit
                                </a>
                                <button
                                  onClick={this.removeData}
                                  className="btn btn-default"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 pl-0 pr-0">
                            <div className="col-md-6 pr-3 float-left">
                              <div className="row">
                                <div className="col-md-12 table-header">
                                  <h6>List of Companies</h6>
                                </div>
                              </div>
                              <div className="row rowCompanyGrid">
                                <div className="card-body table-responsive comp company p-0">
                                  <BootstrapTable
                                    data={this.state.CompanygridData}
                                    selectRow={selectRowProp}
                                    striped
                                    hover
                                    ref={this.onBootstrapTableRef}
                                  >
                                    <TableHeaderColumn
                                      hidden
                                      isKey
                                      dataField="id"
                                    >
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      width={"360px"}
                                      dataField="companyname"
                                      filter={{
                                        type: "TextFilter",
                                        placeholder: "Company",
                                      }}
                                    >
                                      Company
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      width={"350px"}
                                      dataField="companyid"
                                      filter={{
                                        type: "TextFilter",
                                        placeholder: "Company ID",
                                      }}
                                    >
                                      Company ID
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            {this.state.showeditComponent ?
                              (
                                <EditCompaniesDetails formDetails={this.state.form} onSubmit={this.getCompaniesforEdit} showcompstatus={this.getstatus} />
                              )
                              :
                              this.state.showcomponent ?
                                (
                                  <AddCompanyDetails onSubmit={this.getCompanies} showcompstatus={this.getstatus} companyList={this.state.companyAvailability} />
                                )
                                :
                                this.state.isCheckBoxClicked ?
                                  (
                                    this.state.loading ? (<div className="d-inline-block clientonboarding"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                                      <CompanyDetails formDetails={this.state.form} updatestate={this.componentDidMount} />
                                  )
                                  : ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Companies: state.csr.companiesList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companiesList: () => {
      return dispatch(companiesList())
    },
    companyDelete: (rowIds) => {
      return dispatch(companyDelete(rowIds))
    },
    companyById: (id) => {
      return dispatch(companyById(id))
    },
    CopmpanyCreatedUserMailId: (id) => {
      return dispatch(CopmpanyCreatedUserMailId(id))
    },
    CopmpanyUpdatedUserMailId: (id) => {
      return dispatch(CopmpanyUpdatedUserMailId(id))
    },
    CopmpanyCertifiedUserMailId: (id) => {
      return dispatch(CopmpanyCertifiedUserMailId(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(companies)
