import axios from 'axios'
import config from './config'


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const reportslist = () => {
    return (dispatch) => {
        axios.get(config.apibaseUrl + 'reports-list', axiosconfig)
            .then(response => {
                dispatch({
                    type: 'REPORTS_LIST',
                    value: response.data.body
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const reportlistById = (id) => {

    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'reports-listbyId/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_REPORT_BY_ID_SUCCESS',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const GetReportsDetails = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'download-Csv/', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_REPORT_DETAILS_SUCCESS',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const AddToS3 = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'uploadFiletoS3Bucket', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ADD_TO_S3',
                    value: response.data.body
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}