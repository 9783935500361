import axios from 'axios'
import config from './config'

export const companiesList = () => {
    return async (dispatch) => {
        return await axios.get(config.apibaseUrl + 'company-list', axiosconfig).then(response => {
            dispatch({
                type: 'COMPANIES_LIST',
                value: response.data.body
            })
            return response
        })
            .catch(error => {
                console.log(error)
            })
    }
}


let axiosconfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}


export const companyAdd = (formData) => {
    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'add-company', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_ADD_SUCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const companyDelete = (rowIds) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'remove-company', { rowIds }, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_DELETE_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const companyEdit = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'edit-company', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const companyById = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-company/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_GET_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const ActiveStatusUpdateforUser = (formData) => {

    return async function (dispatch) {
        return await axios.post(config.apibaseUrl + 'active-companyupdate', formData, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'ACTIVE_STATUS_EDIT_SUCCESS',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const companyBycompanyname = (company) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'get-companyby_companyName/' + company, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'COMPANY_GET_BY_COMPANY_NAME',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const CopmpanyCreatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'CreatedUserfromCompany/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_COMPANY_CREATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const CopmpanyUpdatedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'UpdatedUserfromCompany/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_COMPANY_UPDATED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export const CopmpanyCertifiedUserMailId = (id) => {
    return async function (dispatch) {
        return await axios.get(config.apibaseUrl + 'CertifiedUserfromCompany/' + id, axiosconfig)
            .then(response => {
                dispatch({
                    type: 'GET_COMPANY_CRETIFIED_USER',
                    value: response.data.message
                })
                return response
            })
            .catch(error => {
                console.log(error)
            })
    }
}