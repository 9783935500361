import { createReducer } from '../store.js';

export default createReducer(
	{
		assetList: null,
		assetDeleteStatus: false,
		assetAddStatus: false,
		assetGetStatus: false,
		assetEditStatus: false,
		entityList: null,
		entityDeleteStatus: false,
		entityAddStatus: false,
		entityGetStatus: false,
		entityEditStatus: false,
		regionList: null,
		regionDeleteStatus: false,
		regionAddStatus: false,
		regionGetStatus: false,
		regionEditStatus: false,
		productList: null,
		productDeleteStatus: false,
		productAddStatus: false,
		productGetStatus: false,
		productEditStatus: false,
		relationshipList: null,
		relationshipDeleteStatus: false,
		relationshipAddStatus: false,
		relationshipGetStatus: false,
		relationshipEditStatus: false,
		tierList: null,
		tierDeleteStatus: false,
		tierAddStatus: false,
		tierGetStatus: false,
		tierEditStatus: false,
		companiesList: null,
		companyaddstatus: false,
		usersList: null,
		useraddstatus: false,
		userDeleteStatus: false,
		userEditStatus: false,
		userGetStatus: false,
		companyDeleteStatus: false,
		companyEditStatus: false,
		companyGetStatus: false,
		accountgrouplist: null,
		availableaccountgrplist: null,
		selectedaccountgrouplist: null,
		notselectedaccountgrouplist: null,
		addaccountgroup: false,
		getaccountgroupbyid: false,
		editaccountGroup: false,
		intradaytradelist: null,
		intradaypositionlist: null,
		companyaccountList: null,
		priordaytradelist: null,
		priordaypositionlist: null,
		priormoneyline: null,
		intradaytradeaccountList: null,
		intradayPositionaccountList: null,
		priormoneyselaccountList: null,
		priordaytradeaccountList: null,
		priordayPositionaccountList: null,
		deleteaccountgrp: false,
		usertypelist: null,
		reportslist: null,
		statementList: null,
		historicalreports: null,
		historicalstatementsReport: null,
		getReportbyId: null,
		getstatementbyid: null,
		addscheduler: false,
		getcompanydetailsbycompany: null,
		gethistoricalreportbyid: null,
		getreportDetails: null,
		getAccountBycompany: null,
		getobjlist: null,
		getUserMailIdfromActGrp: null,
		getCompanyname: null,
		getCompanyUpdateduser: null,
		getCompanynameCretifiedUser: null,
		getCretifiedUserName: null,
		getCreatedUserName: null,
		getupdatedUserName: null,
		getUpdatedUserNamefromAgp: null,
		getCretifiedUserNamefromAgp: null,
		getDownloadUrl: null,
		getactgrpforInternalUser: null,
		getactgrpforExternalUser: null,
		GetModuleList: null,
		getRegionList: null,
		getmaxcobDate: null,
		getstatuslist: null,
		endTimeList: null,
		getFullTable: null,
		getaccountList: null,
		getSchedulerList: null,
		getactgrplist: null,
		getActGrpforUser: null,
		getActListbyActGrp: null,
		getAccountsList: null
	},
	{
		'ASSET_LIST': (state, action) => {
			return Object.assign({}, state, {
				assetList: action.value
			});
		},
		'ASSET_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetAddStatus: true
			});
		},
		'ASSET_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetDeleteStatus: true
			});
		},
		'ASSET_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetGetStatus: true
			});
		},
		'ASSET_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetEditStatus: true
			});
		},
		'ENTITY_LIST': (state, action) => {
			return Object.assign({}, state, {
				entityList: action.value
			});
		},
		'ENTITY_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetAddStatus: true
			});
		},
		'ENTITY_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetDeleteStatus: true
			});
		},
		'ENTITY_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetGetStatus: true
			});
		},
		'ENTITY_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				assetEditStatus: true
			});
		},
		'REGION_LIST': (state, action) => {
			return Object.assign({}, state, {
				regionList: action.value
			});
		},
		'REGION_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				regionAddStatus: true
			});
		},
		'REGION_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				regionDeleteStatus: true
			});
		},
		'REGION_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				regionGetStatus: true
			});
		},
		'REGION_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				regionEditStatus: true
			});
		},
		'PRODUCT_LIST': (state, action) => {
			return Object.assign({}, state, {
				productList: action.value
			});
		},
		'PRODUCT_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				productAddStatus: true
			});
		},
		'PRODUCT_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				productDeleteStatus: true
			});
		},
		'PRODUCT_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				productGetStatus: true
			});
		},
		'PRODUCT_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				productEditStatus: true
			});
		},
		'RELATIONSHIP_LIST': (state, action) => {
			return Object.assign({}, state, {
				relationshipList: action.value
			});
		},
		'RELATIONSHIP_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				relationshipAddStatus: true
			});
		},
		'RELATIONSHIP_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				relationshipDeleteStatus: true
			});
		},
		'RELATIONSHIP_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				relationshipGetStatus: true
			});
		},
		'RELATIONSHIP_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				relationshipEditStatus: true
			});
		},
		'TIER_LIST': (state, action) => {
			return Object.assign({}, state, {
				tierList: action.value
			});
		},
		'TIER_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				tierAddStatus: true
			});
		},
		'TIER_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				tierDeleteStatus: true
			});
		},
		'TIER_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				tierGetStatus: true
			});
		},
		'TIER_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				tierEditStatus: true
			});
		},
		'COMPANIES_LIST': (state, action) => {
			return Object.assign({}, state, {
				companiesList: action.value
			});
		},
		'COMPANY_ADD_SUCESS': (state, action) => {
			return Object.assign({}, state, {
				companyaddstatus: action.value
			});
		},
		'COMPANY_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				companyDeleteStatus: true
			});
		},
		'COMPANY_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				companyEditStatus: true
			});
		},
		'COMPANY_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				companyGetStatus: true
			});
		},
		'USERS_LIST': (state, action) => {
			return Object.assign({}, state, {
				usersList: action.value
			});
		},
		'USERS_ADD_SUCESS': (state, action) => {
			return Object.assign({}, state, {
				useraddstatus: action.value
			});
		},
		'USER_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				userDeleteStatus: true
			});
		},
		'USER_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				userEditStatus: true
			});
		},
		'USER_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				userGetStatus: true
			});
		},
		'ACCOUNT_GROUP_LIST': (state, action) => {
			return Object.assign({}, state, {
				accountgrouplist: action.value
			})
		},
		'AVAILABLEACCOUNT_GROUP_LIST': (state, action) => {
			return Object.assign({}, state, {
				availableaccountgrplist: action.value
			})
		},
		'SELECTED_ACCOUNT_GROUP_LIST': (state, action) => {
			return Object.assign({}, state, {
				selectedaccountgrouplist: action.value
			})
		},
		'NOT_SELECTED_ACCOUNT_GROUP_LIST': (state, action) => {
			return Object.assign({}, state, {
				notselectedaccountgrouplist: action.value
			})
		},
		'ADD-ACCOUNT-GROUP': (state, action) => {
			return Object.assign({}, state, {
				addaccountgroup: true
			})
		},
		'GET_ACCOUNT_GROUP_BY_ID': (state, action) => {
			return Object.assign({}, state, {
				getaccountgroupbyid: true
			})
		},
		'ACCOUNT_GROUP_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				editaccountGroup: true
			})
		},
		'GET_SELECTED_ACCOUNT_BY_GROUP': (state, action) => {
			return Object.assign({}, state, {
				getselectedaccountgroup: action.value
			})
		},
		'Intraday_TRADE_LIST': (state, action) => {
			return Object.assign({}, state, {
				intradaytradelist: action.value
			})
		},
		'Intraday_POSITION_LIST': (state, action) => {
			return Object.assign({}, state, {
				intradaypositionlist: action.value
			})
		},
		'COMPANY_ACCOUNT_LIST': (state, action) => {
			return Object.assign({}, state, {
				companyaccountList: action.value
			})
		},
		'ACCOUNT_GROUP_SELECT': (state, action) => {
			return Object.assign({}, state, {
				accountgroupselection: action.value
			})
		},
		'PRIORDAY_TRADE_LIST': (state, action) => {
			return Object.assign({}, state, {
				priordaytradelist: action.value
			})
		},
		'PRIORDAY_POSITION_LIST': (state, action) => {
			return Object.assign({}, state, {
				priordaypositionlist: action.value
			})
		},
		'PRIOR_MONEY_LINE': (state, action) => {
			return Object.assign({}, state, {
				priormoneyline: action.value
			})
		},

		'Intraday_TRADE_Account_LIST': (state, action) => {
			return Object.assign({}, state, {
				intradaytradeaccountList: action.value
			})
		},

		'Intraday_POSITION_Account_LIST': (state, action) => {
			return Object.assign({}, state, {
				intradayPositionaccountList: action.value
			})
		},

		'PRIORMONEYLINE_SELACCOUNT_LIST': (state, action) => {
			return Object.assign({}, state, {
				priormoneyselaccountList: action.value
			})
		},

		'PRIOR_TRADE_Account_LIST': (state, action) => {
			return Object.assign({}, state, {
				priordaytradeaccountList: action.value
			})
		},

		'PRIOR_POSITION_Account_LIST': (state, action) => {
			return Object.assign({}, state, {
				priordayPositionaccountList: action.value
			})
		},
		'ACTIVE_STATUS_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				activestatusupdate: true
			})
		},
		'USER_ACTIVE_EDIT_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				activeditsuccess: true
			})
		},
		'ALL_ACC_LIST': (state, action) => {
			return Object.assign({}, state, {
				getAllAccList: action.value
			})
		},
		'USER_ACC_GROUP_GET_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				useraccgrplist: action.value
			})
		},
		'ACCOUNT_GROUP_DELETE_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				deleteaccountgrp: true
			})
		},
		'USERS_TYPES_LIST': (state, action) => {
			return Object.assign({}, state, {
				usertypelist: action.value
			})
		},
		'REPORTS_LIST': (state, action) => {
			return Object.assign({}, state, {
				reportslist: action.value
			})
		},
		'STATEMENTS_LIST': (state, action) => {
			return Object.assign({}, state, {
				statementList: action.value
			})
		},
		'HISTORICAL_REPORTS_LIST': (state, action) => {
			return Object.assign({}, state, {
				historicalreports: action.value
			})
		},
		'HISTORICAL_STATEMENTS_LIST': (state, action) => {
			return Object.assign({}, state, {
				historicalstatementsReport: action.value
			})
		},
		'GET_REPORT_BY_ID_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				getReportbyId: action.value
			})
		},
		'GET_STATEMENT_BY_ID_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				getstatementbyid: action.value
			})
		},
		'SCHEDULER_ADD_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				addscheduler: action.value
			})
		},
		'COMPANY_GET_BY_COMPANY_NAME': (state, action) => {
			return Object.assign({}, state, {
				getcompanydetailsbycompany: action.value
			})
		},
		'GET_HISTORICAL_REPORT_BY_ID': (state, action) => {
			return Object.assign({}, state, {
				gethistoricalreportbyid: action.value
			})
		},
		'GET_REPORT_DETAILS_SUCCESS': (state, action) => {
			return Object.assign({}, state, {
				getreportDetails: action.value
			})
		},
		'ADD_TO_S3': (state, action) => {
			return Object.assign({}, state, {
				getreportDetails: action.value
			})
		},
		'GET_SELECTED_ACCOUNT_BY_COMPANY': (state, action) => {
			return Object.assign({}, state, {
				getAccountBycompany: action.value
			})
		},
		'OBJECT_LISTED_SUCESS': (state, action) => {
			return Object.assign({}, state, {
				getobjlist: action.value
			})
		},
		'GET_ACCOUNT_GROUP_CREATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getUserMailIdfromActGrp: action.value
			})
		},
		'GET_COMPANY_CREATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCompanyname: action.value
			})
		},
		'GET_COMPANY_UPDATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCompanyUpdateduser: action.value
			})
		},
		'GET_COMPANY_CRETIFIED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCompanynameCretifiedUser: action.value
			})
		},
		'GET_CREATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCreatedUserName: action.value
			})
		},
		'GET_UPDATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getupdatedUserName: action.value
			})
		},
		'GET_CRETIFIED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCretifiedUserName: action.value
			})
		},
		'GET_ACCOUNT_GROUP_UPDATED_USER': (state, action) => {
			return Object.assign({}, state, {
				getUpdatedUserNamefromAgp: action.value
			})
		},
		'GET_ACCOUNT_GROUP_CERTIFIED_USER': (state, action) => {
			return Object.assign({}, state, {
				getCretifiedUserNamefromAgp: action.value
			})
		},
		'DOWNLOAD_URL_SUCESS': (state, action) => {
			return Object.assign({}, state, {
				getDownloadUrl: action.value
			})
		},
		'ACCOUNT_GROUP_LIST_INTERNAL_USER': (state, action) => {
			return Object.assign({}, state, {
				getactgrpforInternalUser: action.value
			})
		},
		'ACCOUNT_GROUP_LIST_EXTERNAL_USER': (state, action) => {
			return Object.assign({}, state, {
				getactgrpforExternalUser: action.value
			})
		},
		'BATCH_STATUS_LIST': (state, action) => {
			return Object.assign({}, state, {
				getstatuslist: action.value
			})
		},
		'GET_MAX_COB_DATE': (state, action) => {
			return Object.assign({}, state, {
				getmaxcobDate: action.value
			})
		},
		'GET_REGION_LIST': (state, action) => {
			return Object.assign({}, state, {
				getRegionList: action.value
			})
		},
		'GET_MODULE_LIST': (state, action) => {
			return Object.assign({}, state, {
				GetModuleList: action.value
			})
		},
		'GET_END_TIME_LIST': (state, action) => {
			return Object.assign({}, state, {
				endTimeList: action.value
			})
		},
		'GET_FULL_TABLE_DATA': (state, action) => {
			return Object.assign({}, state, {
				getFullTable: action.value
			})
		},
		'ACCOUNT_LISTS': (state, action) => {
			return Object.assign({}, state, {
				getaccountList: action.value
			})
		},
		'SCHEDULER_LIST': (state, action) => {
			return Object.assign({}, state, {
				getSchedulerList: action.value
			})
		},
		'ACTGRP_SCHECULER_LIST': (state, action) => {
			return Object.assign({}, state, {
				getactgrplist: action.value
			})
		},
		'DELETE_SCHEDULER_LIST': (state, action) => {
			return Object.assign({}, state, {
				deleteStatudofScheduler: action.value
			})
		},
		'ACCOUNT_GROUP_LIST_FOR_USER': (state, action) => {
			return Object.assign({}, state, {
				getActGrpforUser: action.value
			})
		},
		'GET_ACCOUNT_LIST_BY_ACTGRP': (state, action) => {
			return Object.assign({}, state, {
				getActListbyActGrp: action.value
			})
		},
		'ACCOUNTS_LIST': (state, action) => {
			return Object.assign({}, state, {
				getAccountsList: action.value
			})
		},
		'GET_COLOR_BY_ID': (state, action) => {
			return Object.assign({}, state, {
				getcolorbyID: action.value
			})
		}
	}
);
