import React from "react"
import { historicalReportsList } from "../../actions/historicalAction"
import { connect } from "react-redux"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import ScheculerAcc from "../schedulers/scheduler_acc"
import autoBind from "react-autobind"
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode"


class HistoricalReports extends React.Component {
  state = {
    loading: false,
    isMyconsoleClicked: false,
    isRunbuttonClicked: false,
    isreportselected: false,
    selectedhistoricalreport: null,
  }
  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.historicalReportsList()
    this.setState({ loading: false })
  }

  constructor(props) {
    super(props)
    autoBind(this)

    
    let expirationTime
    let token = localStorage.getItem('accessToken')
    if (token !== null) {
      const { exp } = jwtDecode(token)
      expirationTime = (exp * 1000)
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.reload('/')
      }
    }
  }

  RunbuttonClicked() {
    if (this.state.isreportselected) {
      this.setState({ isRunbuttonClicked: true })
    }
  }

  getviewStatus(e) {
    this.setState({ isRunbuttonClicked: false })
    this.setState({ isreportselected: false })
  }

  MyconsoleButtonClicked() {
    this.setState({ isMyconsoleClicked: true })
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      onSelect: async (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({ isreportselected: true })
          this.state.selectedhistoricalreport = row
        }
      },
    }

    return this.state.isRunbuttonClicked ? (
      <ScheculerAcc
        viewstatus={this.getviewStatus}
        historcalReport={this.props.historicalreportList}
        historicalReportName={this.state.selectedhistoricalreport}
      />
    ) : (
      <div className="content-wrapper">
        <section className="content-header pb-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item active">
                    Historical Reports
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 dashboard-main-area">

                <div className="card-header card-onboarding">
                  <div className="col-md-12 action-group report-actions">
                    <button
                      className="btn btn-default"
                      onClick={this.RunbuttonClicked}
                    >
                      Run
                      </button>
                  </div>
                </div>

                <div className="card bg-black">
                  <div className="card-body p-0">
                    {this.state.loading ? (<div className="d-inline-block schedulers"><Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner></div>) :
                      <div
                        className="table-responsive p-0 report-table historicalReport"
                        style={{ height: 422 }}
                      >
                        <BootstrapTable
                          data={this.props.historicalreportList}
                          selectRow={selectRowProp}
                          striped
                          hover
                        >
                          <TableHeaderColumn isKey dataField="ID" hidden>
                            ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"105px"}
                            dataField="REPORTID"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Report ID",
                            }}
                          >
                            Report ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"300px"}
                            dataField="REPORTNAME"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Report Name",
                            }}
                          >
                            Report Name
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"80px"}
                            dataField="REPORTSYSTEM"
                            filter={{ type: "TextFilter", placeholder: "System" }}
                          >
                            System
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTBATCHID"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Batch ID",
                            }}
                          >
                            Batch ID
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTBATCHTYPE"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Batch Type",
                            }}
                          >
                            Batch Type
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"90px"}
                            dataField="REPORTLOADTYPE"
                            filter={{
                              type: "TextFilter",
                              placeholder: "Load Type",
                            }}
                          >
                            Load Type
                        </TableHeaderColumn>
                          <TableHeaderColumn
                            width={"70px"}
                            dataField="REPORTFORMAT"
                            filter={{ type: "TextFilter", placeholder: "Format" }}
                          >
                            Format
                        </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    historicalreportList: state.csr.historicalreports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    historicalReportsList: () => {
      return dispatch(historicalReportsList())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoricalReports)
