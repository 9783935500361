import React from 'react'
import { AccountGroupListfromScheduler } from './../../actions/schedulerAction'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Spinner } from 'react-bootstrap'
import jwtDecode from "jwt-decode";
import autoBind from "react-autobind"



class SchedulerDetails extends React.Component {

    state = {
        loading: false,
        selectedItemData: []
    }

    constructor(props) {
        super(props)
        autoBind(this)
    
        let expirationTime
        let token = localStorage.getItem('accessToken')
        if (token !== null) {
          const { exp } = jwtDecode(token)
          expirationTime = (exp * 1000)
          if (Date.now() >= expirationTime) {
            localStorage.clear();
            window.location.reload('/')
          }
        }
      }

    selectedaccbygroup() {
        return (
            this.props.tableData &&
            this.props.tableData.map((data, index) => {
                return (
                    <tr key={'item_' + index}>
                        <td style={{ width: 340 }}>{data.ACTGROUPID}</td>
                    </tr>
                )
            })
        )
    }

    async componentDidMount() {
        this.setState({ loading: false })
        let array = []
        await this.props.tableData &&
            this.props.tableData.map((data, index) => {
                array.push(data)
            })

        this.setState({ selectedItemData: array })
        this.setState({ loading: false })
    }




    render() {
        return (
            this.state.loading ? (<Spinner animation="border" style={{ textAlign: "center" }} role="status"><span className="sr-only">Loading...</span></Spinner>) :
                <div className="hold-transition sidebar-mini layout-navbar-fixed">
                    <div className="wrapper">
                        <div className="col-md-3 float-left">
                            <div className="row">
                                <div className="col-md-12 table-header">
                                    <h6>List of Account Groups</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card-body table-responsive p-0" style={{ maxHeight: 150 }}>
                                    <table className="table table-head-fixed text-nowrap">
                                        <BootstrapTable
                                            data={this.props.tableData}
                                            // selectRow={selectRowProp}
                                            striped
                                            hover
                                            ref={this.onBootstrapTableRef}
                                        >
                                            <TableHeaderColumn
                                                hidden
                                                isKey
                                                dataField="id"
                                            >
                                                ID
                                        </TableHeaderColumn>
                                            <TableHeaderColumn
                                                width={"130px"}
                                                dataField="ACTGROUPID"
                                                filter={{
                                                    type: "TextFilter",
                                                    placeholder: "Account Group",
                                                }}
                                            >
                                                Account Group
                                        </TableHeaderColumn>
                                        </BootstrapTable>

                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="card bg-black col-md-12 block-right" style={{ MaxHeight: 120, marginTop: 15 }}>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-5 form-title">COMPANY NAME:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.COMPANYNAME}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">DATE FORMAT:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.DATEFORMAT}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">FTP 2 USER:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.FTP2USER}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">EMAIL 2 USER:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.EMAIL2USER}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">USER EMAIL ID:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.USEREMAILID}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">COMPANY ID:</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.COMPANYID}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 form-title">FTPT2 FOLDER</div>
                                                <div className="col-md-7 form-value">{this.props.formDetails.FTPT2FOLDER}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getactgrplist: state.csr.getactgrplist,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        AccountGroupListfromScheduler: (formdata) => {
            return dispatch(AccountGroupListfromScheduler(formdata))
        }
    }
}

export default SchedulerDetails;